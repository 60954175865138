/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  SearchSC,
  HeaderOverlay,
  SearchInputContainer,
  IconContainer,
  SearchInput,
  Popup,
  SearchResultRow,
  RowContent,
  ResultTitle,
  Type,
  NoResult,
} from './Search.style';

import Icon from 'components/common/icon/Icon';
import Loading from 'components/common/loading/Loading';
import SearchEntityImage from 'components/common/search-entity-image/SearchEntityImage';

import {
  getEntityLink,
  getEntityImage,
  getEntityName,
  getEntityType,
} from './Search.service';

export default function Search({
  showPopup,
  searchResults,
  loading,
  pageLoading,
  searchValue,
  onSearchChange,
  onPopupClose,
  onLinkClick,
  onScroll,
  onSearchFieldFocus,
}) {
  const { t } = useTranslation();

  return (
    <SearchSC data-testid="search" open={showPopup}>
      {showPopup ? <HeaderOverlay onClick={onPopupClose} /> : null}

      <SearchInputContainer open={showPopup}>
        <IconContainer>
          <Icon name="search" isDark size={16} />
        </IconContainer>
        <SearchInput
          type="search"
          placeholder={t('search')}
          value={searchValue}
          onChange={onSearchChange}
          onFocus={onSearchFieldFocus}
        />
      </SearchInputContainer>
      <Popup open={showPopup} onScroll={onScroll}>
        {loading ? (
          <Loading />
        ) : searchResults.length ? (
          searchResults.map((result) => (
            <SearchResultRow
              key={result.id}
              className="animate__animated animate__fadeIn"
              to={getEntityLink(result)}
              onClick={onLinkClick}
            >
              <RowContent>
                <SearchEntityImage
                  src={getEntityImage(result)}
                  alt={getEntityName(result)}
                  title={getEntityName(result)}
                />
                <ResultTitle>{getEntityName(result)}</ResultTitle>
                <Type>{getEntityType(result)}</Type>
              </RowContent>
            </SearchResultRow>
          ))
        ) : (
          <NoResult>{t('no_result_found')}</NoResult>
        )}
        {pageLoading ? <Loading /> : null}
      </Popup>
    </SearchSC>
  );
}

Search.propTypes = {
  showPopup: PropTypes.bool.isRequired,
  searchResults: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onSearchFieldFocus: PropTypes.func.isRequired,
};
