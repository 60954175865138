/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-27 20:43:41
 * @modify date 2021-02-27 20:43:41
 */

import React from 'react';

import OAuthButtons from './OAuthButtons';

export default function OAuthButtonsContainer() {
  const handleGoogleClick = () => {};
  const handleLinkedInClick = () => {};

  return (
    <OAuthButtons
      onGoggleClick={handleGoogleClick}
      onLinkedInClick={handleLinkedInClick}
    />
  );
}
