/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { TicketsSC, HighlightCardContainer } from './Tickets.style';

import Highlights from 'components/layout/highlights/Highlights.container';
import ProjectsContainer from 'components/layout/projects/Projects.container';
import GanttChartContainer from 'components/layout/gantt-chart/GanttChart.container';
import UpdatesContainer from 'components/layout/updates/Updates.container';
import TabRouter from 'components/layout/tab-router/TabRouter';

export default function Tickets({ tabs }) {
  return (
    <TicketsSC data-testid="tickets-page">
      <HighlightCardContainer className="animate__animated animate__fadeIn">
        <Highlights entity="" id="" restProps={{ isAnimated: true }} />
      </HighlightCardContainer>

      <TabRouter
        tabs={tabs}
        renders={[
          <ProjectsContainer key={1} />,
          <>
            <GanttChartContainer entity="" id="" />
            <UpdatesContainer entity="" id="" />
          </>,
        ]}
      />
    </TicketsSC>
  );
}

Tickets.propTypes = {
  /** list of tabs */
  tabs: PropTypes.array.isRequired,
};
