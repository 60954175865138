/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 14:40:39
 * @modify date 2021-01-06 14:40:39
 */

import React from 'react';
import PropTypes from 'prop-types';

import { FormErrorSC, ListContainer, ListItem } from './FormError.style';

export default function FormError({ errorMessages }) {
  return (
    <FormErrorSC data-testid="form-error">
      {errorMessages.length === 1 ? (
        errorMessages[0]
      ) : (
        <ListContainer>
          {errorMessages.map((msg) => (
            <ListItem key={msg}>{msg}</ListItem>
          ))}
        </ListContainer>
      )}
    </FormErrorSC>
  );
}
FormError.propTypes = {
  errorMessages: PropTypes.array,
};

FormError.defaultProps = {
  errorMessages: '',
};
