/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:57:04
 * @modify date 2021-01-04 16:40:03
 */

import styled from 'styled-components';

export const GuestNavigatorSC = styled.div`
  display: flex;
`;
