/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 13:49:17
 * @modify date 2021-02-17 13:49:17
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import MembersAccordion from './MembersAccordion';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getMembersService } from './MembersAccordion.service';

export default function MemberAccordionContainer({ entity, id }) {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [internalLoading, setInternalLoading] = useState(false);
  const [hasMore, setHasMore] = useState(0);
  const [page, setPage] = useState(0);

  const isOnPageBottom = useSelector(
    (state) => state.commonStates.isOnPageBottom,
  );

  const getMembers = () => {
    getMembersService(entity, id, page).then((members) => {
      setMembers(members?.data?.data ?? []);
      setHasMore(members?.data?.hasMore ?? false);
      setLoading(false);
      setInternalLoading(false);
    });
  };

  const getMoreMembers = () => {
    if (loading || internalLoading || !isOnPageBottom || !hasMore) {
      return;
    }
    setInternalLoading(true);
    getMembersService(entity, id, page).then((members) => {
      setMembers((old) => [...old, ...members?.data?.data]);
      setHasMore(members?.data?.hasMore ?? false);
      setInternalLoading(false);
      setPage((old) => old + 1);
    });
  };

  useEffect(getMembers, []);
  useEffect(getMoreMembers, [isOnPageBottom]);

  const handleToggleAccordion = (accordionName) => {
    if (activeAccordion === accordionName) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionName);
    }
  };

  if (loading) {
    return <Loading gap={100} />;
  }

  if (!members.length) {
    return <NoData />;
  }

  return (
    <MembersAccordion
      members={members}
      activeAccordion={activeAccordion}
      loading={hasMore}
      onAccordionToggle={handleToggleAccordion}
    />
  );
}

MemberAccordionContainer.propTypes = {
  entity: PropTypes.string,
  id: PropTypes.string,
};

MemberAccordionContainer.defaultProps = {
  entity: '',
  id: '',
};
