/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Card from 'components/common/card/Card';
import CardHeader from 'components/common/card/card-header/CardHeader';
import CardBody from 'components/common/card/card-body/CardBody';
import Summary from 'components/common/summary/Summary';

export default function SummaryCard({ summary, attachments }) {
  const { t } = useTranslation();

  return (
    <Card className="animate__animated animate__fadeIn">
      <CardHeader bold>{t('summary')}</CardHeader>
      <CardBody padded>
        <Summary summaryText={summary} attachments={attachments} />
      </CardBody>
    </Card>
  );
}

SummaryCard.propTypes = {
  summary: PropTypes.string.isRequired,
  attachments: PropTypes.array.isRequired,
};
