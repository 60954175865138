/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  TicketsSC,
  TicketStatus,
  IconContainer,
  TextData,
  Number,
  Label,
} from './Tickets.style';

import Icon from 'components/common/icon/Icon';

const statusesIconsMap = {
  'In Progress': 'rotate_right',
  Pending: 'priority_high',
  Completed: 'done',
};

export default function Tickets({ data }) {
  return (
    <TicketsSC>
      {data.map((status) => (
        <TicketStatus key={status.id}>
          <IconContainer>
            <Icon
              name={statusesIconsMap[status.tag]}
              alt={status.tag}
              size={15}
            />
          </IconContainer>
          <TextData>
            <Number>{status.count}</Number>
            <Label>{status.tag}</Label>
          </TextData>
        </TicketStatus>
      ))}
    </TicketsSC>
  );
}
Tickets.propTypes = {
  /** data is an array with ticket statuses */
  data: PropTypes.array.isRequired,
};
