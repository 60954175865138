/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:11
 * @modify date 2020-12-27 13:25:11
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  DashboardSC,
  KanbanContainer,
  SwimlaneHeader,
  HeaderLabel,
} from './Dashboard.style';

import PageHeader from 'components/common/page-header/PageHeader';
import KanbanBoard from 'components/common/kanban-board/KanbanBoard';
import Swimlane from 'components/common/swimlane/Swimlane';
import SwimlaneTicketCard from 'components/common/swimlane/swimlane-ticket-card/SwimlaneTicketCard';
import StatusIcon from 'components/common/status-icon/StatusIcon';
import Icon from 'components/common/icon/Icon';
import Tags from 'components/common/tags/Tags';

import { STATUS_ICON_TAG_MAP } from 'utils/ticket-status-util';

import DASHBOARD_ICON from 'assets/images/icons/dashboard.svg';

export default function Dashboard({
  showHeader,
  statuses,
  hasMore,
  dashboard,
  tickets,
  setTickets,
  onAdd,
  onScroll,
}) {
  const { t } = useTranslation();

  return (
    <DashboardSC data-testid="dashboard-page">
      {showHeader ? (
        <PageHeader
          entityName={t('dashboard')}
          icon={DASHBOARD_ICON}
          heading={dashboard?.title ?? ''}
          footer={
            <Tags
              orientation="horizontal"
              gap={4}
              maxCount={8}
              tags={dashboard.filters.map((filter) => ({
                id: filter.id,
                name: filter.name ?? filter.title ?? '',
              }))}
            />
          }
        />
      ) : null}

      <KanbanContainer>
        <KanbanBoard>
          {statuses.map((status, index) => (
            <Swimlane
              key={status.id}
              id={status.id}
              loading={hasMore[index]}
              header={
                <SwimlaneHeader>
                  <StatusIcon>
                    <Icon name={STATUS_ICON_TAG_MAP[status.tag]} size={17} />
                  </StatusIcon>
                  <HeaderLabel>{status.tag}</HeaderLabel>
                </SwimlaneHeader>
              }
              list={tickets[index]}
              setList={setTickets[index]}
              onAdd={onAdd}
              onScroll={(event) => onScroll(event, index)}
            >
              {tickets[index].map((ticket) => (
                <SwimlaneTicketCard
                  key={ticket.id}
                  project={ticket.project}
                  ticket={ticket}
                />
              ))}
            </Swimlane>
          ))}
        </KanbanBoard>
      </KanbanContainer>
    </DashboardSC>
  );
}

Dashboard.propTypes = {
  showHeader: PropTypes.bool.isRequired,
  statuses: PropTypes.array.isRequired,
  hasMore: PropTypes.array.isRequired,
  dashboard: PropTypes.object,
  tickets: PropTypes.array,
  setTickets: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
};
