/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TasksSC,
  TaskListContainer,
  TaskBar,
  TagContainer,
} from './Tasks.style';

import Loading from 'components/common/loading/Loading';
import EntityName from 'components/common/entity-name/EntityName';
import TimeRange from 'components/common/time-range/TimeRange';
import Avatar from 'components/common/avatar/Avatar';
import Tag from 'components/common/tag/Tag';
import ImageIcon from 'components/common/image-icon/ImageIcon';

import { getTimeRange, getExtremeRelativeTime } from 'utils/date-formats-util';
import { getIconByTicket } from 'utils/ticket-status-util';

export default function Tasks({ tasks, hasMore, onScroll }) {
  const { t } = useTranslation();

  return (
    <TasksSC>
      <TaskListContainer onScroll={onScroll}>
        {tasks.map((task) => (
          <TaskBar key={task.id}>
            <EntityName
              icon={<ImageIcon src={task.img} alt={task.title} size="small" />}
              entityName={t('task')}
              link={`/ticket-${task.key}`}
              title={task.title}
              size="small"
            />
            <TimeRange
              icon={getIconByTicket(task)}
              isPriority={task.isPriority}
              range={getTimeRange(task.createdAt, task.endDate ?? new Date())}
              duration={getExtremeRelativeTime(
                task.createdAt,
                task.endDate ?? new Date(),
              )}
            />
            <Avatar
              username={task.assignee.name}
              img={task.assignee.img}
              link={`/user/${task.assignee.id}`}
              alt={task.assignee.name}
            />
            <TagContainer>
              {task.components.length ? (
                <Tag>{task.components[0].tag}</Tag>
              ) : null}
            </TagContainer>
          </TaskBar>
        ))}
        {hasMore ? <Loading gap={20} /> : null}
      </TaskListContainer>
    </TasksSC>
  );
}

Tasks.propTypes = {
  tasks: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onScroll: PropTypes.func.isRequired,
};
