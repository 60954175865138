/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 01:52:04
 * @modify date 2020-12-24 01:52:04
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TeamSC,
  PageHeaderContainer,
  HighlightCardContainer,
} from './Team.style';

import PageHeader from 'components/common/page-header/PageHeader';

import Highlights from 'components/layout/highlights/Highlights.container';
import MembersAccordionContainer from 'components/layout/members-accordion/MembersAccordion.container';
import GanttChartContainer from 'components/layout/gantt-chart/GanttChart.container';
import UpdatesContainer from 'components/layout/updates/Updates.container';
import TabRouter from 'components/layout/tab-router/TabRouter';

export default function Team({ data, tabs }) {
  const { t } = useTranslation();

  return (
    <TeamSC data-testid="team-page">
      <PageHeaderContainer className="animate__animated animate__fadeIn">
        <PageHeader
          entityName={t('team')}
          icon={data.img}
          heading={data.name}
        />
      </PageHeaderContainer>

      <HighlightCardContainer className="animate__animated animate__fadeIn">
        <Highlights
          entity="team"
          id={data.id}
          restProps={{ isAnimated: true }}
        />
      </HighlightCardContainer>

      <TabRouter
        tabs={tabs}
        renders={[
          <>
            <MembersAccordionContainer entity={data.entity} id={data.id} />
          </>,
          <>
            <GanttChartContainer entity={data.entity} id={data.id} />
            <UpdatesContainer entity={data.entity} id={data.id} />
          </>,
        ]}
      />
    </TeamSC>
  );
}

Team.propTypes = {
  /** data contains the user data */
  data: PropTypes.object,
  /** list of tabs */
  tabs: PropTypes.array.isRequired,
};
