/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:13:07
 * @modify date 2020-12-31 23:59:27
 */

import styled from 'styled-components';

export const KanbanBoardSC = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 1fr);
  gap: 16px;
  height: 100%;
  justify-content: start;
`;
