/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-22 17:45:52
 */

import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const HeaderButtonSC = styled.div`
  display: flex;
  border-left: 1px solid ${(props) => props.theme.dividerColor};
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const HeaderButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 100%;
  cursor: pointer;
  transition: all 250ms ease;
`;

export const Badge = styled.div`
  position: absolute;
  top: 18px;
  left: 37px;
  width: 6.4px;
  height: 6.4px;
  border-radius: 10px;
  background: ${(props) => props.theme.header.badge.backgroundColor};
`;
