/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:20
 * @modify date 2020-12-27 13:25:20
 */

import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import DashboardList from './DashboardList';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getDashboardsService, saveDashboard } from './DashboardList.service';

export default function DashboardListContainer() {
  const [tags, setTags] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterIds, setFilterIds] = useState([]);

  const getDashboards = () => {
    getDashboardsService().then((dashboards) => {
      setDashboards(dashboards?.data?.data ?? []);
      setLoading(false);
    });
  };

  useEffect(getDashboards, []);

  const handleAddTag = (newTag) => {
    newTag.id = uuid();
    setTags((old) => [...old, newTag]);
  };

  const handleRemoveTag = (tagId) => {
    setTags((old) => old.filter((tag) => tag.id !== tagId));
  };

  const handleFilterIdsChange = (newFilterIds) => {
    setFilterIds([...newFilterIds]);
  };

  const handleSave = () => {
    if (filterIds.length) {
      saveDashboard(filterIds).then((response) => {
        window.location = `/dashboard/${response.data.id}`;
      });
    }
  };

  if (loading) {
    return <Loading gap={100} />;
  }

  if (!dashboards.length) {
    return <NoData />;
  }

  return (
    <DashboardList
      dashboards={dashboards}
      tags={tags}
      filterIds={filterIds}
      onFilterIdsChange={handleFilterIdsChange}
      onRemoveTag={handleRemoveTag}
      onAddTag={handleAddTag}
      onSave={handleSave}
    />
  );
}
