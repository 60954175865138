/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-11 18:36:30
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const HighlightsSC = styled.div`
  display: flex;
`;

export const CarouselContainer = styled.div`
  flex: 1;
  display: flex;
  padding: ${variables.cardPadding}px;
  height: 180px;
`;
