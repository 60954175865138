import React from 'react';
import { Redirect } from 'react-router-dom';

export const fakeChatReplies = {
  'my task': 'Using App Drawer you can select My Task.',
  kanban:
    'On Dashboard page, you can create a new dashboard or select existing one to see the Kanban board.',
  gantt:
    'To view gantt chart for all the projects, goto "Tasks" page and select "Updates" tab. To view gantt chart for a particular project click on a project on "Tasks" page > select a project and switch to "Updates" or click on project name on projects gantt chart.',
  teams: 'To see all the teams, click on "People" link on the app drawer.',
  feature: `Features are listed under projects, so to view the features you'll need to go to "Tasks" page and select your project from the projects list and switch to "Features" tab.`,
  milestones: `Milestones are listed under projects, so to view the milestones you'll need to go to "Tasks" page and select your project from the projects list.`,
  profile: `By clicking on your username at the top right of the screen, you see three options, "Profile", you can view and change your profile, "Account Settings" and Logout`,
  settings: `By clicking on your username at the top right of the screen, you see three options, "Profile", you can view and change your profile, "Account Settings" and Logout`,
  paras: `Paras is currently working on integration of new ticket creation in frontend application. He has four task on priority.`,
  priority: (
    <div>
      <p>List first 5 priority tasks in the chatbot</p>
      <ul className="chat-list">
        <li>Adding the auto populate ML model</li>
        <li>Enabling the REST access to the chatbot API</li>
        <li>Following up on the UX design for the list priority</li>
        <li>Scheduling Demo time with prospective users </li>
        <li>Integrating the chatbot search with Elasticsearch</li>
      </ul>
      <Redirect to="/my-tickets" />
    </div>
  ),
  'new ticket': `Sure, let me create a new ticket & Redirecting to create new ticket`,
};

export const dummyNewTicketActionResults = {
  ux: {
    contributor: 'b20232e5-4427-4bc2-bcd2-c23db4dcfaff',
    components: [],
  },
  api: {
    contributor: 'dbebe230-6ba6-4ce9-967f-f45afce7c2c6',
    components: ['57819cc7-01e2-4b00-a383-9bf443ee0689'],
  },
  elastic: {
    contributor: '5f72328f-b4c7-4ec7-a365-a8909f5e1a28',
    components: [
      '997a627b-ca13-4417-9a60-f6333d29b9f0',
      '57819cc7-01e2-4b00-a383-9bf443ee0689',
      'be4538e6-423b-4a6e-81b2-ac29642eead1',
    ],
  },
  doc: {
    contributor: 'e075da46-d6ee-46bc-9020-93b17dabb133',
    components: ['56da7b8b-4255-4915-84e8-be098fd7d463'],
  },
  sales: {
    contributor: '32e06dff-7659-47ee-886e-40d4555000af',
    components: [],
  },
};
