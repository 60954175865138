/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CardFooterSC } from './CardFooter.style';

/**
 * CardFooter renders footer section of the card with dynamic child.
 */
export default function CardFooter({ children }) {
  return <CardFooterSC data-testid="card-footer">{children}</CardFooterSC>;
}

CardFooter.propTypes = {
  /** content of the footer on the left side */
  children: PropTypes.element.isRequired,
};
