/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 01:48:42
 * @modify date 2021-02-17 12:17:29
 */

import { axiosInstance } from 'utils/api-util';

export function getTeamService(teamId) {
  return axiosInstance({
    url: `entity/contributor`,
    params: {
      id: teamId,
    },
  });
}
