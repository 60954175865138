/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { PeopleSC, HighlightCardContainer } from './People.style';

import Highlights from 'components/layout/highlights/Highlights.container';
import GanttChartContainer from 'components/layout/gantt-chart/GanttChart.container';
import UpdatesContainer from 'components/layout/updates/Updates.container';
import TabRouter from 'components/layout/tab-router/TabRouter';
// import TeamsContainer from 'components/layout/teams/Teams.container';
import MembersAccordionContainer from 'components/layout/members-accordion/MembersAccordion.container';

export default function People({ tabs }) {
  return (
    <PeopleSC data-testid="people-page">
      <HighlightCardContainer className="animate__animated animate__fadeIn">
        <Highlights restProps={{ isAnimated: true }} />
      </HighlightCardContainer>

      <TabRouter
        tabs={tabs}
        renders={[
          <MembersAccordionContainer key={1} />,
          // <TeamsContainer key={1} />,
          <>
            <GanttChartContainer />
            <UpdatesContainer />
          </>,
        ]}
      />
    </PeopleSC>
  );
}

People.propTypes = {
  /** list of tabs */
  tabs: PropTypes.array.isRequired,
};
