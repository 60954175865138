/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-15 22:52:24
 * @modify date 2021-02-16 20:46:00
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const FeatureSC = styled.div`
  flex: 1;
  display: grid;
  gap: ${variables.cardPadding}px;
`;

export const PageHeaderContainer = styled.div`
  width: 100%;
`;

export const HighlightCardContainer = styled.div`
  width: 100%;
`;
