/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-09 16:18:07
 * @modify date 2021-04-23 17:50:30
 */

import styled from 'styled-components';

export const AppRouterSC = styled.div`
  .Toastify__toast {
    border-radius: 5px;
    min-width: 560px;
    max-width: 560px;
    padding: 16px 20px;
    padding-left: 24px;

    .Toastify__progress-bar--default {
      background: ${(props) =>
        props.theme.toast.default.progressBar.backgroundColor};
    }
  }

  .c-button {
    background-color: ${(props) => props.theme.primaryColor} !important;
  }
  .c-basic-button {
    border-color: ${(props) => props.theme.primaryColor} !important;
  }
`;
