/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-11 14:13:36
 * @modify date 2021-01-29 20:18:35
 */

import styled from 'styled-components';

export const EditorContainerSC = styled.div`
  height: calc(100% - 12px);
  width: calc(100% - 24px);
  margin: 6px auto;
`;
