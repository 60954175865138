/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:45:13
 */

import styled from 'styled-components';

export const HomeSC = styled.div`
  display: flex;
  width: 100%;
`;
