/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-09 16:59:52
 * @modify date 2021-01-09 16:59:52
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  ToastMessageSC,
  IconSC,
  TextSC,
  TitleSC,
  MessageSC,
} from './ToastMessage.style';

import Icon from 'components/common/icon/Icon';

const iconsMap = {
  success: 'done',
  error: 'close',
  warning: 'warning',
  info: 'info',
};

export default function ToastMessage({ title, message, status, isDarkText }) {
  return (
    <ToastMessageSC data-testid="toast-message" className="k-toast-message">
      <IconSC>
        <Icon name={iconsMap[status]} iconTheme="outlined" size={13} />
      </IconSC>
      <TextSC>
        <TitleSC className="title" isDarkText={isDarkText}>
          {title}
        </TitleSC>
        <MessageSC className="message" isDarkText={isDarkText}>
          {message}
        </MessageSC>
      </TextSC>
    </ToastMessageSC>
  );
}
ToastMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.any.isRequired,
  status: PropTypes.string,
  isDarkText: PropTypes.bool,
};

ToastMessage.defaultProps = {
  title: '',
  status: 'success',
  isDarkText: false,
};
