/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-12 23:56:57
 * @modify date 2021-03-12 23:56:57
 */

import { axiosInstance } from 'utils/api-util';

export function getStatuses() {
  return axiosInstance({
    url: 'entity/list',
    params: {
      attribute: 'status',
    },
  });
}

export function updateTicketStatus(id, statusId) {
  const formData = new FormData();

  formData.append('id', id);
  formData.append('status', statusId);

  return axiosInstance({
    url: 'entity/ticket',
    method: 'PUT',
    data: formData,
  });
}
