/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-13 17:53:55
 * @modify date 2021-03-13 17:53:55
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon as IconSM } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  EditProfileSC,
  UpdatePhotoContainer,
  RemoveLink,
  LinkSC,
  ActionButtonContainer,
} from './EditProfile.style';

import Modal from 'components/common/modal/Modal';
import Avatar from 'components/common/avatar/Avatar';
import Loading from 'components/common/loading/Loading';
import PasswordStrength from 'components/common/password-strength/PasswordStrength';

export default function EditProfile({
  loading,
  photoLoading,
  formData,
  showPasswordFields,
  passwordVisibility,
  errors,
  passwordChangeLoading,
  userDataLoading,
  onUserDataSubmit,
  onPasswordVisibilityToggle,
  onPhotoRemove,
  onFormDataChange,
  onModalClose,
  onShowPasswordFields,
  onChangePasswordSubmit,
  onCancelPasswordUpdate,
  onForgotPasswordClick,
  onFileChange,
}) {
  const { t } = useTranslation();
  const fileRef = useRef(null);

  return (
    <Modal open headerText={t('profile')} onClose={onModalClose}>
      {loading ? (
        <Loading gap={200} />
      ) : (
        <EditProfileSC>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  label={t('first_name')}
                  placeholder={t('first_name')}
                  id="firstName"
                  error={errors.firstName}
                  value={formData.firstName}
                  onChange={onFormDataChange}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label={t('last_name')}
                  placeholder={t('last_name')}
                  id="lastName"
                  error={errors.lastName}
                  value={formData.lastName}
                  onChange={onFormDataChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Form.Input
                label={t('position_job_title')}
                placeholder={t('your_job_title')}
                id="designation"
                value={formData.designation}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label={t('email')}
                placeholder={t('email')}
                value={formData.email}
                readOnly
              />
            </Form.Field>
            {showPasswordFields ? (
              <>
                <Form.Field>
                  <Form.Input
                    icon={
                      <IconSM
                        link
                        name={passwordVisibility.old ? 'eye slash' : 'eye'}
                        onClick={() => onPasswordVisibilityToggle('old')}
                      />
                    }
                    type={passwordVisibility.old ? 'text' : 'password'}
                    label={t('old_password')}
                    placeholder={t('type_your_old_password_here')}
                    id="oldPassword"
                    value={formData.oldPassword}
                    error={errors.oldPassword}
                    onChange={onFormDataChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    icon={
                      <IconSM
                        link
                        name={passwordVisibility.new ? 'eye slash' : 'eye'}
                        onClick={() => onPasswordVisibilityToggle('new')}
                      />
                    }
                    type={passwordVisibility.new ? 'text' : 'password'}
                    label={t('new_password')}
                    placeholder={t('type_your_new_password_here')}
                    id="newPassword"
                    value={formData.newPassword}
                    error={errors.newPassword}
                    onChange={onFormDataChange}
                  />
                </Form.Field>
                {formData.newPassword ? (
                  <PasswordStrength password={formData.newPassword} />
                ) : null}
                <Form.Field>
                  <Form.Input
                    icon={
                      <IconSM
                        link
                        name={passwordVisibility.confirm ? 'eye slash' : 'eye'}
                        onClick={() => onPasswordVisibilityToggle('confirm')}
                      />
                    }
                    type={passwordVisibility.confirm ? 'text' : 'password'}
                    label={t('confirm_password')}
                    placeholder={t('type_your_new_password_again')}
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    error={errors.confirmPassword}
                    onChange={onFormDataChange}
                  />
                </Form.Field>
                <LinkSC onClick={onForgotPasswordClick}>
                  {t('forgot_password')}
                </LinkSC>
              </>
            ) : (
              <LinkSC onClick={onShowPasswordFields}>
                <IconSM name="edit outline" />
                {t('edit_password')}
              </LinkSC>
            )}

            {showPasswordFields ? (
              <ActionButtonContainer>
                <Button
                  className="c-button"
                  primary
                  onClick={onChangePasswordSubmit}
                  loading={passwordChangeLoading}
                >
                  {t('update_password')}
                </Button>
                <Button
                  className="c-basic-button"
                  basic
                  onClick={onCancelPasswordUpdate}
                >
                  {t('cancel')}
                </Button>
              </ActionButtonContainer>
            ) : (
              <ActionButtonContainer>
                <Button
                  className="c-button"
                  primary
                  onClick={onUserDataSubmit}
                  loading={userDataLoading}
                >
                  {t('save_changes')}
                </Button>
                <Button className="c-basic-button" basic onClick={onModalClose}>
                  {t('cancel')}
                </Button>
              </ActionButtonContainer>
            )}
          </Form>

          <UpdatePhotoContainer>
            <Avatar
              username={formData.name}
              hideUsername
              img={formData.img}
              imgSize={100}
            />

            <input
              ref={fileRef}
              type="file"
              hidden
              onChange={onFileChange}
              accept="image/*"
            />

            <Button
              basic
              primary
              className="c-basic-button"
              loading={photoLoading}
              onClick={() => fileRef.current.click()}
            >
              {t('upload_a_photo')}
            </Button>

            {formData.img ? (
              <RemoveLink onClick={onPhotoRemove}>
                {t('remove_photo')}
              </RemoveLink>
            ) : null}
          </UpdatePhotoContainer>
        </EditProfileSC>
      )}
    </Modal>
  );
}

EditProfile.propTypes = {
  loading: PropTypes.bool.isRequired,
  photoLoading: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  showPasswordFields: PropTypes.bool.isRequired,
  passwordVisibility: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  passwordChangeLoading: PropTypes.bool.isRequired,
  userDataLoading: PropTypes.bool.isRequired,
  onUserDataSubmit: PropTypes.func.isRequired,
  onPasswordVisibilityToggle: PropTypes.func.isRequired,
  onPhotoRemove: PropTypes.func.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onShowPasswordFields: PropTypes.func.isRequired,
  onChangePasswordSubmit: PropTypes.func.isRequired,
  onCancelPasswordUpdate: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
};
