/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-23 18:31:54
 * @modify date 2021-02-23 18:31:54
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CloseIconSC } from './CloseIcon.style';

import Icon from 'components/common/icon/Icon';

export default function CloseIcon({ title, onClick }) {
  return (
    <CloseIconSC title={title} onClick={onClick}>
      <Icon name="close" size={15} />
    </CloseIconSC>
  );
}

CloseIcon.propTypes = {
  /** title of the close button */
  title: PropTypes.string,
  /** triggers when user clicks on close button */
  onClick: PropTypes.func.isRequired,
};

CloseIcon.defaultProps = {
  title: 'Close',
};
