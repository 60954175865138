/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 00:09:20
 * @modify date 2020-12-24 00:14:58
 */

import styled from 'styled-components';

export const NoDataSC = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: ${(props) => props.gap}px;
`;
