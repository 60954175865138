/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-27 17:02:24
 * @modify date 2021-03-18 12:10:41
 */

const STATUS_ICON_MAP = {
  pending: 'priority_high',
  inProgress: 'rotate_right',
  completed: 'done',
};

export const STATUS_ICON_TAG_MAP = {
  Pending: 'priority_high',
  'In Progress': 'rotate_right',
  Completed: 'done',
};

function calculateTicketStatus(ticket) {
  if (ticket.endDate) {
    return 'completed';
  } else {
    // Need to figure out the logic for in progress tickets
    return 'pending';
  }
}

export function getIconByTicket(ticket) {
  if (ticket.status && ticket.status.tag) {
    return STATUS_ICON_TAG_MAP[ticket.status.tag];
  }
  return STATUS_ICON_MAP[calculateTicketStatus(ticket)];
}

export function getIconByStatus(status) {
  return STATUS_ICON_TAG_MAP[status];
}
