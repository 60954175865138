/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-15 23:05:54
 * @modify date 2021-01-15 23:05:54
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderDropdown from './HeaderDropdown';

import { getDataService } from './HeaderDropdown.service';

export default function HeaderDropdownContainer({ type, headerLabel }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    getDataService(type).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(getData, []);

  return (
    <HeaderDropdown
      loading={loading}
      data={data}
      type={type}
      headerLabel={headerLabel}
    />
  );
}

HeaderDropdownContainer.propTypes = {
  type: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
};
