/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 15:10:07
 * @modify date 2020-12-27 15:10:07
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppDrawer from './AppDrawer';

import { toggleAppDrawer, updateActiveModal } from 'actions/common-actions';

import { modalList } from 'utils/modal-util';

export default function AppDrawerContainer() {
  const dispatch = useDispatch();

  const isAppDrawerOpen = useSelector(
    (state) => state.commonStates.isAppDrawerOpen,
  );
  const selectedWorkspace = useSelector(
    (state) => state.userStates.selectedWorkspace,
  );

  const handleToggleAppDrawer = () => {
    dispatch(toggleAppDrawer());
  };

  const handleCreateModalClick = () => {
    dispatch(updateActiveModal(modalList.CREATE_TICKET_MODAL));
  };

  return (
    <AppDrawer
      isAppDrawerOpen={isAppDrawerOpen}
      selectedWorkspace={selectedWorkspace}
      onToggleAppDrawer={handleToggleAppDrawer}
      onCreateModalClick={handleCreateModalClick}
    />
  );
}
