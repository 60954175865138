/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-16 15:47:58
 * @modify date 2021-01-16 15:48:00
 */

import { v4 as uuid } from 'uuid';

export const messages = [
  {
    id: uuid(),
    time: '3 days',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'Can you add the API to the knowledge graph ticket?',
      },
    ],
  },
  {
    id: uuid(),
    time: '3 weeks',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'Alex W.',
      },
      {
        id: uuid(),
        type: 'light',
        text: 'has commented on the',
      },
      {
        id: uuid(),
        type: 'normal',
        text: 'Model Verification Ticket.',
      },
    ],
  },
  {
    id: uuid(),
    time: '2 weeks',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'The Model Verification',
      },
      {
        id: uuid(),
        type: 'light',
        text: 'ticket has pushed to',
      },
      {
        id: uuid(),
        type: 'normal',
        text: 'Staging environment.',
      },
    ],
  },
];
