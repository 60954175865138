/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-03 20:29:35
 * @modify date 2021-03-03 21:36:04
 */

const IMAGE_FILE_TYPES = ['png', 'jpg', 'jpeg'];

const SIZE_MAP = ['B', 'KB', 'MB', 'GB'];

export function isImageType(fileType) {
  return IMAGE_FILE_TYPES.includes(fileType);
}

export function getFileSizeFromBytes(bytes) {
  let newSize = bytes;
  let i = 0;
  while (newSize >= 1024) {
    newSize /= 1024;
    i++;
  }
  return newSize.toFixed(2) + ' ' + SIZE_MAP[i];
}
