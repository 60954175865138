/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:46
 */

import styled from 'styled-components';

export const ArrowButtonSC = styled.div`
  display: flex;
`;
