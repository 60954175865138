/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-14 01:08:41
 * @modify date 2021-03-31 16:54:50
 */

import styled from 'styled-components';

import { zIndex } from 'variables';

export const DashboardFilterSC = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  box-shadow: ${(props) => (props.open ? props.theme.boxShadow2 : 'none')};
  transition: all 250ms ease;
`;

export const Overlay = styled.div`
  z-index: ${zIndex.dashbaordFilterOverlay};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${(props) => props.theme.blackA2};
`;

export const SearchInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 32px;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) =>
    props.open ? props.theme.white : props.theme.lightBackgroundColor};
  /* border-radius: ${(props) => (props.open ? '8px 8px 0 0' : '8px')}; */
  border-radius: 8px;
  z-index: ${zIndex.dashbaordFilter};
`;

export const Popup = styled.div`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  background: ${(props) => props.theme.white};
  border-radius: 0 0 8px 8px;
  width: 100%;
  min-height: ${(props) => (props.open ? '480' : '0')}px;
  max-height: ${(props) => (props.open ? '480' : '0')}px;
  height: 480px;
  overflow: auto;
  z-index: ${zIndex.dashbaordFilter};
  box-shadow: ${(props) => (props.open ? props.theme.boxShadow2 : 'none')};
`;

export const SearchResultRow = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};
`;

export const RowContent = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 40px 1fr auto;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  background: ${(props) => props.theme.white};
  cursor: pointer;
  transition: all 250ms;

  &:hover {
    background: ${(props) => props.theme.grayE};
  }
`;

export const ResultTitle = styled.div`
  flex: 1;
  font-size: 13px;
  line-height: 15px;
  color: ${(props) => props.theme.blackText};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Type = styled.div`
  display: flex;
  margin-left: 18px;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-transform: capitalize;
  color: ${(props) => props.theme.blackText2};
`;

export const NoResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  height: 100%;
  color: ${(props) => props.theme.blackText2};
`;

export const Tag = styled.span`
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 12px;
  background: ${(props) => props.theme.white};
  border: ${(props) => props.theme.tag.border};
  border-radius: 100px;
  margin: 4px;
  transition: all 250ms ease;
`;

export const TagLabel = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.black};
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  align-self: flex-start;
  min-height: 32px;
  height: 32px;
`;

export const Input = styled.input`
  display: flex;
  min-height: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  flex: 1;
  background: transparent;
  padding: 0 8px;
  font-size: 13.6px;
  color: ${(props) => props.theme.search.fontColor};
  min-width: 350px;
  font-family: Roboto;
  transition: all 250ms ease;
`;
