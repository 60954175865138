/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-04-17 22:42:15
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const PageHeaderSC = styled.div`
  display: flex;
  flex-direction: column;
  height: ${variables.pageHeader.height}px;
  background: ${(props) => props.theme.gradiantBackground};
  border-radius: 8px;
  width: 100%;
`;

export const IconHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ProjectIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  margin: 0 16px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow2};
  border-radius: ${(props) =>
    variables.pageHeader.iconVariantMap[props.iconVariant].borderRadius}px;
  overflow: hidden;
  transform: translateY(12px);
`;

export const ProjectIcon = styled.img`
  display: flex;
  height: 100%;
`;

export const DefaultProjectIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.primaryColor};
  font-size: 24px;
  text-transform: uppercase;
`;

export const HeaderData = styled.div`
  display: grid;
  flex: 1;
`;

export const EntityName = styled.span`
  display: flex;
  font-size: 12.8px;
  color: ${(props) => props.theme.secondaryTextColor};
  margin-top: 6px;
`;

export const Heading = styled.h1`
  font-family: Lato;
  display: flex;
  font-size: 23px;
  font-weight: 500;
  margin: 0;
  padding: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const PageHeaderWidget = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 40px;
  margin-right: 20px;
`;

export const MenuButtonContainer = styled.div`
  display: flex;
  margin-right: 5px;
  margin-top: 5px;
  align-self: flex-start;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) => props.theme.white};
  height: 24px;
  padding-left: 88px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Footer = styled.span`
  display: flex;
  font-size: 11px;
  font-family: Lato;
  font-weight: bold;
  color: ${(props) => props.theme.secondaryTextColor};
`;
