/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import AppRouter from 'components/pages/AppRouter';

import store from 'store';

import Loading from 'components/common/loading/Loading';

export default function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loading gap={300} />}>
        <AppRouter />
      </Suspense>
    </Provider>
  );
}
