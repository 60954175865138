/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 00:09:14
 * @modify date 2020-12-24 00:09:14
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { NoDataSC } from './NoData.style';

export default function NoData({ gap, msg }) {
  const { t } = useTranslation();
  return <NoDataSC gap={gap}>{msg ?? t('no_data')}</NoDataSC>;
}
NoData.propTypes = {
  gap: PropTypes.number,
  msg: PropTypes.string,
};

NoData.defaultProps = {
  gap: 16,
  msg: null,
};
