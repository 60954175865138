/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ChatSC,
  ChatHeader,
  HeaderText,
  ChatBody,
  ChatBodyInner,
  ChatTypingArea,
  ChatTypeInput,
} from './Chat.style';

import Icon from 'components/common/icon/Icon';
import Button from 'components/common/button/Button';
import ChatBlock from 'components/layout/dora/chat/chat-block/ChatBlock';
import BOT_IMAGE from 'assets/images/logo-no-bg-blue.svg';

import { ReactComponent as TypingImage } from 'assets/images/typing.svg';

import Config from 'app.config';

export default function Chat({
  loading,
  conversation,
  typedMessage,
  user,
  onTypedMessageChange,
  onTypedMessageKeyDown,
  onClose,
}) {
  const { t } = useTranslation();
  const chatBodyBottom = useRef(null);

  useEffect(() => {
    if (chatBodyBottom) {
      chatBodyBottom.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation.length]);

  return (
    <ChatSC data-testid="chat">
      <ChatHeader>
        <HeaderText>{t('dora.header_text')}</HeaderText>
        <Button
          text=""
          iconButton
          rounded
          buttonType="flat"
          leftIcon={<Icon name="remove" size={18} />}
          size="small"
          onClick={onClose}
        />
      </ChatHeader>

      <ChatBody>
        <ChatBodyInner>
          {conversation.map((message) => (
            <ChatBlock
              key={message.id}
              sent={message.sent}
              user={{
                img: message.sent ? user.img : BOT_IMAGE,
                name: message.sent ? user.first_name : Config.appName,
              }}
              message={message.message}
            />
          ))}
        </ChatBodyInner>
        {loading ? <TypingImage className="typing" /> : null}

        <div ref={chatBodyBottom} />
      </ChatBody>

      <ChatTypingArea>
        <ChatTypeInput
          autoFocus
          placeholder={t('start_typing')}
          value={typedMessage}
          onKeyDown={onTypedMessageKeyDown}
          onChange={onTypedMessageChange}
        />
      </ChatTypingArea>
    </ChatSC>
  );
}
Chat.propTypes = {
  loading: PropTypes.bool.isRequired,
  conversation: PropTypes.array.isRequired,
  typedMessage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onTypedMessageChange: PropTypes.func.isRequired,
  onTypedMessageKeyDown: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
