/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:55:48
 * @modify date 2021-01-04 15:55:48
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Register from './Register';

import { registerService, getUserEmailFromToken } from './Register.service';

import { isValidEmail } from 'utils/vadilation-util';
import { useQuery } from 'utils/common-util';

import {
  UNACCECPTABLE_PASSWORD_SCORE,
  calculatePasswordStrength,
} from 'utils/password-util';

import { links } from 'utils/navigation-util';

const DEFAULT_FORM = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

export default function RegisterContainer() {
  const location = useLocation();
  const query = useQuery(location);

  const token = query.get('token');

  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [isError, setIsError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmailReadonly, setIsEmailReadonly] = useState(false);

  const handleToken = () => {
    if (token) {
      getUserEmailFromToken(token).then((response) => {
        if (!response.isError && response.data?.workspace_id) {
          setFormData((old) => ({
            ...old,
            email: response?.data?.email ?? '',
            companyId: response.data.workspace_id,
          }));

          if (response?.data?.email) {
            setIsEmailReadonly(true);
          }
        }
      });
    }
  };

  useEffect(handleToken, [token]);

  const handleFormDataChange = (_, { id, value }) => {
    setFormData((old) => ({ ...old, [id]: value }));
  };

  const validateForm = () => {
    setFieldErrors({
      firstName: formData.firstName ? null : 'First name is required!',
      lastName: formData.lastName ? null : 'Last name is required!',
      email: formData.email
        ? isValidEmail(formData.email ?? '')
          ? null
          : 'Please enter valid email address!'
        : 'Email is required!',
      password: formData.password ? null : 'Password is required!',
    });

    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.password
    ) {
      if (!isValidEmail(formData.email)) {
        return false;
      }

      if (
        calculatePasswordStrength(formData.password).score <=
        UNACCECPTABLE_PASSWORD_SCORE
      ) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const handleRegisterSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    registerService(formData, token)
      .then(() => {
        if (token) {
          window.location = links.login.path;
        } else {
          window.location = links.check_email.path;
        }
      })
      .catch((error) => {
        setIsError(true);
        let errorMessageArray = [];

        for (let key in error.response.data) {
          errorMessageArray.push(error.response.data[key][0]);
        }

        setErrorMessages(errorMessageArray);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Register
      formData={formData}
      isEmailReadonly={isEmailReadonly}
      loading={loading}
      isError={isError}
      fieldErrors={fieldErrors}
      errorMessages={errorMessages}
      onRegisterSubmit={handleRegisterSubmit}
      onFormDataChange={handleFormDataChange}
    />
  );
}
