/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-04-18 00:32:41
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const HighlightCardSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.lightBackgroundColor};
  min-width: ${variables.highlightCardWidth - 8}px;
  max-width: ${variables.highlightCardWidth - 8}px;
  height: 100%;
  margin: 0 4px;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 22px;
  font-family: Lato;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${(props) => props.theme.highlightCard.header.backgroundColor};
  color: ${(props) => props.theme.blackText};
  font-size: 11px;
`;

export const Body = styled.div`
  flex: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  align-content: center;
`;
