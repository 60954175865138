/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-12 23:57:05
 * @modify date 2021-03-12 23:57:05
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  StatusSelectionSC,
  Status,
  IconContainer,
  Text,
} from './StatusSelection.style';

import Icon from 'components/common/icon/Icon';

import { getIconByStatus } from 'utils/ticket-status-util';

export default function StatusSelection({ statuses, ticket, onStatusChange }) {
  return (
    <StatusSelectionSC>
      {statuses.map((status) => (
        <Status key={status.id} onClick={() => onStatusChange(status.id)}>
          <IconContainer>
            <Icon name={getIconByStatus(status.tag)} size={15} />
          </IconContainer>
          <Text>{status.tag}</Text>
          {status.id === ticket.status.id ? (
            <Icon name="done" size={17} />
          ) : null}
        </Status>
      ))}
    </StatusSelectionSC>
  );
}

StatusSelection.propTypes = {
  statuses: PropTypes.array.isRequired,
  ticket: PropTypes.object.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};
