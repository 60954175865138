/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-17 21:59:09
 * @modify date 2021-01-17 21:59:09
 */

import React, { useState, useEffect } from 'react';

import Search from './Search';

import { getSearchData } from './Search.service';

import Config from 'app.config';

let delayTimer;

export default function SearchContainer() {
  const [touched, setTouched] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [results, setResults] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const performSearch = (appendResults = true) => {
    if (touched) {
      setInternalLoading(true);
      getSearchData(searchValue, page).then((response) => {
        if (appendResults) {
          setResults((old) => [...old, ...response?.data]);
        } else {
          setResults(response?.data);
        }
        setHasMore(response?.hasMore);
        setLoading(false);
        setInternalLoading(false);
      });
    }
  };

  const getData = () => {
    if (touched) {
      setInternalLoading(true);
      clearTimeout(delayTimer);

      delayTimer = setTimeout(() => {
        performSearch(false);
      }, Config.searchDelay);
    }
  };

  const handleSearchValueChange = () => {
    if (!showPopup && touched) {
      setShowPopup(true);
    }
    getData();
  };

  useEffect(handleSearchValueChange, [searchValue, touched]);

  useEffect(performSearch, [page]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleLinkClick = () => {
    handlePopupClose();
    setTouched(false);
  };

  const handleNextPage = () => {
    if (hasMore && !internalLoading) {
      setPage((old) => old + 1);
    }
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom) {
      handleNextPage();
    }
  };

  const handleSearchFocus = () => {
    setTouched(true);
    handleSearchValueChange();
  };

  return (
    <Search
      showPopup={showPopup}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      loading={loading}
      pageLoading={hasMore}
      searchResults={results}
      onPopupClose={handlePopupClose}
      onLinkClick={handleLinkClick}
      onScroll={handleScroll}
      onSearchFieldFocus={handleSearchFocus}
    />
  );
}
