/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-11 14:13:36
 * @modify date 2021-01-11 14:13:36
 */

import React from 'react';
import PropTypes from 'prop-types';

import EditorJs from 'react-editor-js';
import Paragraph from '@editorjs/paragraph';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import Header from '@editorjs/header';

import { EditorContainerSC } from './EditorContainer.style';

import { axiosInstance } from 'utils/api-util';

const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  image: {
    class: ImageTool,
    config: {
      uploader: {
        uploadByFile(file) {
          const formData = new FormData();
          formData.append('file', file);

          // your own uploading logic here
          return axiosInstance({
            url: 'upload_file',
            method: 'POST',
            data: formData,
          }).then((response) => {
            return response.data;
          });
        },
      },
    },
  },
  header: {
    class: Header,
    inlineToolbar: true,
  },
};

export default function EditorContainer({ editorProps }) {
  return (
    <EditorContainerSC
      data-testid="editor-container"
      className="k-editor-container"
    >
      <EditorJs tools={EDITOR_JS_TOOLS} {...editorProps} minHeight={0} />
    </EditorContainerSC>
  );
}

EditorContainer.propTypes = {
  editorProps: PropTypes.object,
};

EditorContainer.defaultProps = {
  editorProps: {},
};
