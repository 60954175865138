/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-16 01:37:19
 * @modify date 2021-02-16 01:37:31
 */

import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: grid;
  gap: 16px;
`;
