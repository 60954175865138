/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';

import People from './People';

const tabs = [
  // {
  //   id: 'teams',
  //   name: 'Teams',
  //   link: '/people/teams',
  // },
  {
    id: 'members',
    name: 'Members',
    link: '/people/members',
  },
  {
    id: 'updates',
    name: 'Updates',
    link: '/people/updates',
  },
];

export default function PeoplesContainer() {
  return <People tabs={tabs} />;
}
