/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-10 17:09:48
 * @modify date 2021-01-10 17:09:48
 */

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  ModalOverlay,
  ModalSC,
  ModalHeader,
  ModalHeaderText,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from './Modal.style';

import Icon from 'components/common/icon/Icon';

/**
 * Renders modal with overlay.
 * This function automatically align itself in the center of the screen with animation.
 */
export default function Modal({
  open,
  headerText,
  children,
  modalFooter,
  onClose,
}) {
  const [modalRoot, setModalRoot] = useState(null);
  const [activeModal, setActiveModal] = useState(true);

  useEffect(() => {
    setModalRoot(document.getElementById('modal-root'));
  }, []);

  const handleModalClick = (e) => {
    e.stopPropagation();
    setActiveModal(true);
  };

  if (!open || !modalRoot) return null;

  return ReactDOM.createPortal(
    <ModalOverlay onClick={() => setActiveModal(false)}>
      <ModalSC
        className="animate__animated animate__bounceInDown"
        onClick={handleModalClick}
      >
        <ModalHeader active={activeModal}>
          <ModalHeaderText>{headerText}</ModalHeaderText>
          <ModalCloseButton title="Close" onClick={onClose}>
            <Icon name="close" size={15} />
          </ModalCloseButton>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        {modalFooter ? <ModalFooter>{modalFooter}</ModalFooter> : null}
      </ModalSC>
    </ModalOverlay>,
    modalRoot,
  );
}

Modal.propTypes = {
  /** open tells if modal is in open state or close state. */
  open: PropTypes.bool,
  /** title of the modal. */
  headerText: PropTypes.string.isRequired,
  /** renders inside of the modal. */
  children: PropTypes.element.isRequired,
  /** renders footer of the modal. */
  modalFooter: PropTypes.element,
  /** triggers when there is any request to close the modal. */
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  open: false,
  modalFooter: null,
};
