/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-19 10:37:10
 * @modify date 2021-03-20 15:03:58
 */

import styled from 'styled-components';

export const UpdateFooterSC = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterButtonContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-auto-flow: column;
  gap: 20px;
  margin-left: -8px;
  height: 44px;
`;

export const LikedUsers = styled.div`
  display: grid;
  gap: 8px;
  min-width: 150px;
`;
