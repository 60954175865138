/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 22:05:26
 * @modify date 2021-04-23 17:55:13
 */

import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

export const CreateWorkspaceSC = styled.div`
  display: flex;
  flex-direction: column;

  .submit-button {
    background: ${(props) => props.theme.primaryColor};
  }
`;

export const Heading = styled.h1`
  display: flex;
  font-size: 32px;
  margin: 0 0 16px;
`;

export const SubHeading = styled.h3`
  display: flex;
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  font-weight: normal;
  max-width: 600px;
  color: ${(props) => props.theme.blackText2};
`;

export const FormSC = styled(Form)`
  margin-top: 40px;
  &&& {
    max-width: 500px;
  }
`;

export const SubmitButtonContainer = styled.div`
  margin-top: 24px;

  .c-basic-button {
    margin-left: 10px;
  }
`;
