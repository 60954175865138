/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { TagsSC } from './Tags.style';

import Tag from 'components/common/tag/Tag';

export default function Tags({ tags, maxCount, gap, orientation }) {
  return (
    <TagsSC data-testid="tag" gap={gap} orientation={orientation}>
      {tags
        .slice(0, tags.length > maxCount ? maxCount - 1 : tags.length)
        .map((tag) => (
          <Tag key={tag.id}>{tag.name ?? tag.tag}</Tag>
        ))}
      {tags.length > maxCount ? (
        <Tag
          title={tags
            .slice(maxCount - 1)
            .map((tag) => tag.name ?? tag.tag)
            .join(', ')}
        >{`+${tags.length - maxCount + 1}`}</Tag>
      ) : null}
    </TagsSC>
  );
}

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  maxCount: PropTypes.number,
  gap: PropTypes.number,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

Tags.defaultProps = {
  maxCount: 4,
  gap: 8,
  orientation: 'vertical',
};
