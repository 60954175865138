/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 09:57:28
 * @modify date 2021-01-06 12:03:41
 */

import styled from 'styled-components';

export const CheckboxSC = styled.span`
  display: flex;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    user-select: none;
    font-size: 12px;
    text-transform: capitalize;
    padding-left: 24px;

    color: ${(props) => props.theme.blackText2};

    transition: color 150ms ease;

    // check
    &::before {
      content: '';
      width: 15px;
      height: 15px;

      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 50%;

      transform: translateY(-50%);

      background: #ddd;

      transition: background 200ms ease, box-shadow 200ms ease,
        border 100ms ease-in;
    }

    &::after {
      content: '';

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) scale(0);

      width: 15px;
      height: 15px;

      // background-color: $black;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAvklEQVRIS+2U0Q3CMAwFzxuwCYwAEzECdAM2ghHoJmxgZJRIUWgTh9C/9Du9q59fKmz8yMZ8hqCa8IjoE5Gq7oCziEx5Zt0RBfgdOACTiFxTSZcgg8/AUURefxF44Cb6aQIvvChQVcvylo/cAl8VBPgFeAKnKGmFlwRWuwewj5KwuNiWxYUuXevVHYSvTSX2vlXRDa8uOZPY+SZ4VZDcUpvEnq+e1/52rpqGScgbVYO7JvBASmdcE/RIhqCa3ht9IVAZxGa2wAAAAABJRU5ErkJggg==');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 15px;

      border-radius: 2px;

      transition: transform 0.1s ease-in;
    }
  }

  // hover
  &:hover + label::before {
    background: ${(props) => props.theme.lightBackgroundColor};
    box-shadow: ${(props) => props.theme.checkbox.hoverLabel.before.boxShadow};
  }
  &:hover + label {
    color: ${(props) => props.theme.checkbox.hoverLabel.fontColor};
  }

  // check
  &:checked + label::before {
    border-color: ${(props) => props.theme.primaryColor};
    background: ${(props) => props.theme.primaryColor};
  }
  &:checked + label::after {
    transform: translateY(-50%) scale(1);
    transition: transform 200ms ease-out;
    opacity: 1;
  }

  // checkbox disabled
  /* &:disabled + label::before {
			box-shadow: none;
			background: #ddd;
		} */
`;

export const CheckboxLabel = styled.label``;
