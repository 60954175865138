/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 16:11:12
 * @modify date 2021-02-24 16:11:12
 */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import {
  StepsContainerSC,
  Header,
  Logo,
  HeaderLogo,
  HeaderText,
  Body,
  BodyHead,
  StepsSC,
} from './StepsContainer.style';

import Steps from 'components/common/steps/Steps';

import WorkspaceName from 'components/common/workspace-name/WorkspaceName';

import { trimUrl } from 'components/pages/member/workspace/Workspace.service';

import Config from 'app.config';

import { links } from 'utils/navigation-util';

import HeaderLogoImage from 'assets/images/logo.svg';

export default function StepsContainer({
  showHomeLink,
  mode,
  userData,
  activeStepIndex,
  workspaceName,
}) {
  const { t } = useTranslation();

  const location = useLocation();

  const isInvitePage =
    trimUrl(location.pathname) === links.invite_teammates.path;

  return (
    <StepsContainerSC>
      <Header>
        {workspaceName ? (
          <WorkspaceName workspaceName={workspaceName} />
        ) : (
          <Logo to={links.home.path} as={showHomeLink ? Link : 'div'}>
            <HeaderLogo src={HeaderLogoImage} alt={Config.appName} />
            <HeaderText>{Config.appName}</HeaderText>
          </Logo>
        )}
      </Header>
      <Body>
        <BodyHead>
          <Trans
            i18nKey={'new_workspace.steps_heading'}
            values={{
              first_name: userData?.firstName ?? userData?.first_name ?? 'User',
            }}
          />
        </BodyHead>
        <StepsSC>
          <Steps
            steps={[
              {
                id: 1,
                text:
                  mode === 'create' && !isInvitePage
                    ? t('create_workspace')
                    : t('edit_workspace'),
                link: isInvitePage ? links.workspace_edit.path : null,
              },
              {
                id: 2,
                text: t('new_workspace.invite_teammates'),
                link: mode === 'edit' ? links.invite_teammates.path : null,
              },
            ]}
            activeStepIndex={activeStepIndex}
          />
        </StepsSC>
      </Body>
    </StepsContainerSC>
  );
}

StepsContainer.propTypes = {
  showHomeLink: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  userData: PropTypes.object.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  workspaceName: PropTypes.string.isRequired,
};
