/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:13:31
 * @modify date 2020-12-31 00:13:34
 */

import styled from 'styled-components';

export const StatusIconSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-left: -3px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow2};

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;
