/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:35:35
 */

import styled from 'styled-components';

export const LabelDonutSC = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
`;

export const DonutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Label = styled.div`
  display: flex;
  font-size: 13px;
  font-family: Lato;
  font-weight: bold;
`;

export const SubLabel = styled.div`
  display: flex;
  font-size: 11px;
  color: ${(props) => props.theme.secondaryTextColor};
`;
