/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 15:50:54
 * @modify date 2021-04-17 14:17:04
 */

import styled from 'styled-components';

import { variables } from 'variables';

export const WorkspaceSC = styled.div`
  display: flex;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: calc(100vw - ${variables.appDrawerExpandedWidth + 'px'});
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: 100px 48px 20px;
`;

export const StepInfo = styled.div`
  display: flex;
  color: ${(props) => props.theme.blackText2};
  letter-spacing: 0.8px;
  margin-bottom: 16px;
`;
