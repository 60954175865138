/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';

import Home from './Home';

// This file is responsible for all the logic requirement in the Home component
export default function HomeContainer() {
  return <Home />;
}
