/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-12 19:49:39
 * @modify date 2021-01-12 19:59:27
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CheckEmailSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Heading = styled.h2`
  display: flex;
  text-align: center;
`;

export const Body = styled.p`
  margin-top: 20px;
  display: flex;
  text-align: center;
`;

export const LoginLink = styled(Link)`
  margin-top: 20px;
  display: flex;
`;
