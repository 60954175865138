/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { AvatarGroupSC, More } from './AvatarGroup.style';

import Avatar from 'components/common/avatar/Avatar';

export default function AvatarGroup({ users, maxCount, view }) {
  return (
    <AvatarGroupSC
      data-testid="avatar-group"
      view={view}
      maxCount={maxCount}
      userCount={users.length}
    >
      {users
        .slice(0, users.length > maxCount ? maxCount - 1 : users.length)
        .map((user) => (
          <Avatar
            key={user.id}
            title={user.name}
            img={user.img}
            imgSize={29}
            link={`/user/${user.id}`}
          />
        ))}
      {users.length > maxCount ? (
        <More
          title={users
            .slice(maxCount - 1)
            .map((user) => user.name)
            .join(', ')}
        >
          {`+${users.length - maxCount + 1}`}
        </More>
      ) : null}
    </AvatarGroupSC>
  );
}

AvatarGroup.propTypes = {
  users: PropTypes.array.isRequired,
  maxCount: PropTypes.number,
  view: PropTypes.oneOf(['grid', 'line']),
};

AvatarGroup.defaultProps = {
  maxCount: 9,
  view: 'grid',
};
