/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:34
 * @modify date 2021-04-01 10:45:17
 */

import axios from 'axios';

import { axiosInstance } from 'utils/api-util';

export function getStatuses() {
  return axiosInstance({
    url: 'entity/list',
    params: {
      attribute: 'status',
    },
  });
}

export function getDashboardService(dashboardId) {
  return axiosInstance({
    url: 'entity/dashboard',
    params: {
      id: dashboardId,
    },
  });
}

export function getAllStatusTicketsService(dashboardId, statuses) {
  return axios.all(
    statuses.map((status) => getTicketsService(dashboardId, status.id)),
  );
}

export function getTicketsService(dashboardId, statusId, page = 0) {
  return axiosInstance({
    url: 'entity/dashboard_tasks',
    params: {
      id: dashboardId,
      status_id: statusId,
      page,
    },
  });
}

export function getAllStatusTicketsServiceByFilterIds(filterIds, statuses) {
  return axios.all(
    statuses.map((status) =>
      getTicketsServiceByFilterIds(filterIds, status.id),
    ),
  );
}

export function getTicketsServiceByFilterIds(filterIds, statusId, page = 0) {
  return axiosInstance({
    url: 'entity/dashboard_tasks',
    params: {
      filter_ids: JSON.stringify(filterIds),
      status_id: statusId,
      page,
    },
  });
}

export function updateTicket(ticketId, statusId) {
  let formData = new FormData();

  formData.append('id', ticketId);
  formData.append('status', statusId);

  return axiosInstance({
    url: 'entity/ticket',
    method: 'put',
    data: formData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
}
