/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 21:39:31
 * @modify date 2021-02-06 17:39:35
 */

import { axiosInstance } from 'utils/api-util';

export function getTasksService(entity1, id1, entity2, id2, isPriority, page) {
  const params = {};

  if (isPriority) params.priority = isPriority;
  if (entity1) params.entity = entity1;
  if (id1) params.id = id1;
  if (entity2) params.entity1 = entity2;
  if (id2) params.id1 = id2;
  if (page) params.page = page;

  return axiosInstance({
    url: 'entity/tasks',
    params,
  });
}
