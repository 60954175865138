/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-22 17:45:36
 */

import styled from 'styled-components';
import { deviceBreakpoints, variables, zIndex } from 'variables';

export const HeaderSC = styled.div`
  display: flex;
  background: ${(props) => props.theme.header.backgroundColor};
  height: ${variables.headerHeight}px;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props) => props.theme.boxShadow1};
  z-index: ${zIndex.header};

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    justify-content: flex-end;
  }
`;

export const SearchContainerSC = styled.div`
  display: flex;
  max-width: 360px;
  width: 100%;
  flex: 1;
  margin-left: ${(props) =>
    props.isAppDrawerOpen
      ? variables.pageContentPadding
      : variables.pageContentPadding * 3}px;
  transition: all 250ms ease;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: ${(props) => (props.isAppDrawerOpen ? 'none' : 'flex')};
  }
`;
