/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-14 01:19:28
 * @modify date 2021-03-16 20:36:28
 */

import { axiosInstance } from 'utils/api-util';

const whiteListedEntities = ['project', 'feature', 'team', 'user'];

export function getSearchData(term = '', page = 0) {
  return axiosInstance({
    url: `entity/search`,
    params: {
      term,
      page,
      attribute: 'filter',
    },
  }).then((response) => {
    const data = response?.data?.data ?? [];
    return {
      hasMore: response?.data?.hasMore,
      data: data.filter(
        (item) => whiteListedEntities.indexOf(item.entity) !== -1,
      ),
    };
  });
}

export function getTagLabel(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
      return result.title;
    case 'team':
    case 'user':
      return result.name;
    default:
      return '-';
  }
}

export function getEntityName(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
      return result.title;
    case 'team':
    case 'user':
      return result.name;
    default:
      return '-';
  }
}

export function getEntityType(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
    case 'team':
      return result.entity;
    case 'user':
      return result.designation;
    default:
      return '';
  }
}
