/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-02-26 22:38:59
 */

import styled from 'styled-components';

import { variables } from 'variables';

export const HeaderSC = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${variables.headerHeight}px;
  min-height: ${variables.headerHeight}px;
  background: ${(props) => props.theme.primaryLightColor};
  padding: 0 16px;
`;

export const LogoContainer = styled.div`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;

  .k-icon {
    margin-left: 5px;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -${variables.buttonSize.small.height / 2}px;
  transition: all 250ms ease;
  transform: ${(props) =>
    props.isAppDrawerOpen
      ? 'none'
      : `translateX(${
          variables.buttonSize.small.height / 2 + variables.pageContentPadding
        }px)`};

  .k-button {
    background: ${(props) => props.theme.white};
    box-shadow: ${(props) => props.theme.boxShadow2};

    &:hover {
      background: ${(props) => props.theme.lightBackgroundColor};
    }

    .k-icon {
      padding-top: 0.5px;
      color: ${(props) => props.theme.secondaryTextColor};
    }
  }
`;
