/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  PageHeaderSC,
  IconHeadingContainer,
  ProjectIconContainer,
  ProjectIcon,
  DefaultProjectIcon,
  EntityName,
  HeaderData,
  Heading,
  PageHeaderWidget,
  MenuButtonContainer,
  FooterContainer,
  Footer,
} from './PageHeader.style';

export default function PageHeader({
  icon,
  iconVariant,
  entityName,
  heading,
  pageWidget,
  menuButton,
  footer,
}) {
  return (
    <PageHeaderSC data-testid="page-header" className="k-page-header">
      <IconHeadingContainer>
        <ProjectIconContainer
          iconVariant={iconVariant}
          className="k-project-icon-container"
        >
          {icon ? (
            <ProjectIcon
              iconVariant={iconVariant}
              src={icon}
              alt={entityName}
            />
          ) : (
            <DefaultProjectIcon>{heading[0]}</DefaultProjectIcon>
          )}
        </ProjectIconContainer>
        <HeaderData>
          <EntityName>{entityName}</EntityName>
          <Heading title={heading}>{heading}</Heading>
        </HeaderData>
        {pageWidget && <PageHeaderWidget>{pageWidget}</PageHeaderWidget>}
        {menuButton && <MenuButtonContainer>{menuButton}</MenuButtonContainer>}
      </IconHeadingContainer>
      <FooterContainer>
        <Footer>{footer}</Footer>
      </FooterContainer>
    </PageHeaderSC>
  );
}
PageHeader.propTypes = {
  /** Icon to show the page header */
  icon: PropTypes.string.isRequired,
  /** Icon to show the page header */
  iconVariant: PropTypes.oneOf(['box', 'circle']),
  /** EntityName of the page header. Eg: Product, team, task, etc */
  entityName: PropTypes.string,
  /** Header sting */
  heading: PropTypes.string.isRequired,
  /** page header right side widgets  */
  pageWidget: PropTypes.any,
  /** menu options for in the page header  */
  menuButton: PropTypes.element,
  /** Footer text is displayed at the bottom of the PageHeader Component  */
  footer: PropTypes.any,
};

PageHeader.defaultProps = {
  iconVariant: 'box',
  entityName: '',
  pageWidget: null,
  menuButton: null,
  footer: '',
};
