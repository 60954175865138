/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-13 17:53:45
 * @modify date 2021-03-13 17:53:45
 */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import ToastMessage from 'components/common/toast-message/ToastMessage';

import EditProfile from './EditProfile';

import {
  getProfileData,
  updateUserData,
  changePassword,
  updateProfilePicture,
  removeProfilePicture,
} from './EditProfile.service';

import { getUserData as getUserDataAction } from 'actions/user-actions';

import {
  UNACCECPTABLE_PASSWORD_SCORE,
  calculatePasswordStrength,
} from 'utils/password-util';

const DEFAULT_FORM_DATA = {
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  designation: '',
  email: '',
  img: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export default function EditProfileContainer({ onModalClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordVisibility, setPasswordVisibility] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);

  const getUserData = () => {
    setLoading(true);
    getProfileData().then((response) => {
      setFormData({
        id: response.data.contributor.id,
        name: response.data.contributor.name,
        firstName: response.data.contributor.firstName,
        lastName: response.data.contributor.lastName,
        designation: response.data.contributor.designation,
        email: response.data.email,
        img: response.data.contributor.img,
      });
      setLoading(false);
    });
  };

  useEffect(getUserData, []);

  const handleFormDataChange = (_, { id, value }) => {
    setFormData((old) => ({
      ...old,
      [id]: value,
    }));
  };

  const handlePasswordVisibilityToggle = (type) => {
    setPasswordVisibility((old) => ({ ...old, [type]: !old[type] }));
  };

  const handleForgotPasswordClick = () => {};

  const handleChangePasswordSubmit = () => {
    if (
      !formData.oldPassword ||
      !formData.newPassword ||
      !formData.confirmPassword
    ) {
      setErrors((old) => ({
        ...old,
        oldPassword: !formData.oldPassword ? t('required') : null,
        newPassword: !formData.newPassword ? t('required') : null,
        confirmPassword: !formData.confirmPassword ? t('required') : null,
      }));
      return;
    } else {
      clearPasswordErrors();
    }

    if (
      calculatePasswordStrength(formData.newPassword).score <=
      UNACCECPTABLE_PASSWORD_SCORE
    ) {
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setErrors((old) => ({
        ...old,
        confirmPassword: `Password doesn't match`,
      }));
      return;
    } else {
      clearPasswordErrors();
    }
    setPasswordChangeLoading(true);
    changePassword(formData.oldPassword, formData.newPassword)
      .then((response) => {
        if (response.error) {
          if (response?.error?.response?.status === 500) {
            // Show something went wrong message
            toast(
              <ToastMessage
                title={t('error')}
                message={t('something_went_wrong')}
                status="error"
                isDarkText
              />,
            );
          } else {
            setErrors((old) => ({
              ...old,
              oldPassword: t('incorrect_password'),
            }));
            setFormData((old) => ({ ...old, oldPassword: '' }));
          }
        } else if (response.status === 200) {
          // Show success message
          toast(
            <ToastMessage
              title={t('success')}
              message={t('password_updated_successfully')}
              isDarkText
            />,
          );

          setShowPasswordFields(false);
          clearPasswordFormData();
          clearPasswordErrors();
        }
      })
      .finally(() => {
        setPasswordChangeLoading(false);
      });
  };

  const clearPasswordFormData = () => {
    setFormData((old) => ({
      ...old,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }));
  };

  const clearPasswordErrors = () => {
    setErrors((old) => ({
      ...old,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    }));
  };

  const handleCancelPasswordUpdate = () => {
    setShowPasswordFields(false);
    clearPasswordFormData();
    clearPasswordErrors();
  };

  const handleFileChange = (event) => {
    if (event?.target?.files?.length) {
      if (event.target.files[0]?.type?.split('/')[0] === 'image') {
        setPhotoLoading(true);
        updateProfilePicture(formData.id, event.target.files[0])
          .then((response) => {
            if (response.status === 200) {
              getUserData();
              // Show success message
              toast(
                <ToastMessage
                  title={t('success')}
                  message={'Updated your Photo!'}
                  isDarkText
                />,
              );
            } else {
              // Show Error
              toast(
                <ToastMessage
                  title={t('error')}
                  message={t('something_went_wrong')}
                  status="error"
                  isDarkText
                />,
              );
            }
          })
          .catch(() => {
            // Show Error
            toast(
              <ToastMessage
                title={t('error')}
                message={t('something_went_wrong')}
                status="error"
                isDarkText
              />,
            );
          })
          .finally(() => {
            setPhotoLoading(false);
          });
      }
    }
  };

  const handlePhotoRemove = () => {
    setPhotoLoading(true);
    removeProfilePicture(formData.id)
      .then((response) => {
        if (response.status === 200) {
          getUserData();
          // Show success message
          toast(
            <ToastMessage
              title={t('success')}
              message={'Removed your photo!'}
              isDarkText
            />,
          );
        } else {
          // Show Error
          toast(
            <ToastMessage
              title={t('error')}
              message={t('something_went_wrong')}
              status="error"
              isDarkText
            />,
          );
        }
      })
      .finally(() => {
        setPhotoLoading(false);
      });
  };

  const handleUserDataSubmit = () => {
    let isValid = true;
    if (!formData.firstName) {
      setErrors((old) => ({ ...old, firstName: 'This field is required!' }));
      isValid = false;
    }
    if (!formData.lastName) {
      setErrors((old) => ({ ...old, lastName: 'This field is required!' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    setErrors({});
    setUserDataLoading(true);
    updateUserData(formData.id, formData)
      .then(async (response) => {
        if (response.error) {
          // Show something went wrong message
          toast(
            <ToastMessage
              title={t('error')}
              message={t('something_went_wrong')}
              status="error"
              isDarkText
            />,
          );
        } else {
          // Show success message
          toast(
            <ToastMessage
              title={t('success')}
              message={t('Changed Profile information!')}
              isDarkText
            />,
          );
          await dispatch(getUserDataAction());
        }
      })
      .finally(() => {
        setUserDataLoading(false);
      });
  };

  return (
    <EditProfile
      loading={loading}
      photoLoading={photoLoading}
      formData={formData}
      showPasswordFields={showPasswordFields}
      passwordVisibility={passwordVisibility}
      errors={errors}
      passwordChangeLoading={passwordChangeLoading}
      userDataLoading={userDataLoading}
      onUserDataSubmit={handleUserDataSubmit}
      onPasswordVisibilityToggle={handlePasswordVisibilityToggle}
      onPhotoRemove={handlePhotoRemove}
      onFormDataChange={handleFormDataChange}
      onModalClose={onModalClose}
      onShowPasswordFields={() => setShowPasswordFields(true)}
      onChangePasswordSubmit={handleChangePasswordSubmit}
      onCancelPasswordUpdate={handleCancelPasswordUpdate}
      onForgotPasswordClick={handleForgotPasswordClick}
      onFileChange={handleFileChange}
    />
  );
}

EditProfileContainer.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};
