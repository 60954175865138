/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:14:04
 * @modify date 2021-01-08 23:10:23
 */

import styled from 'styled-components';

export const DragHandle = styled.div`
  display: flex;
  border-radius: 100px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
  opacity: 0;
  cursor: pointer;

  .k-icon {
    color: ${(props) => props.theme.blackText};
  }

  &:hover {
    background: ${(props) => props.theme.lightBackgroundColor};
  }
`;

export const SwimlaneTicketCardSC = styled.div`
  display: grid;
  padding: 15px;
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  margin: 8px 0;

  &:hover {
    ${DragHandle} {
      opacity: 1;
    }
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const EntityNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TimeRangeContainer = styled.div`
  padding: 8px 0 8px 22px;
`;

export const AvatarContainer = styled.div`
  margin-top: 8px;
  padding-left: 53px;
`;
