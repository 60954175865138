/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-23 16:34:47
 * @modify date 2021-02-23 16:34:47
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import {
  AttachmentsSC,
  DropRoot,
  DropInput,
  DropMessage,
} from './Attachments.style';

import Loading from 'components/common/loading/Loading';
import AttachmentGrid from 'components/common/attachment-grid/AttachmentGrid';

export default function Attachments({ loading, files, onAdd, onRemove }) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    onAdd(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <AttachmentsSC>
      <DropRoot {...getRootProps()} isDragActive={isDragActive}>
        <DropInput {...getInputProps()} />

        {!files.length && loading ? (
          <Loading gap={100} />
        ) : files.length ? (
          <AttachmentGrid
            files={files}
            loading={loading}
            onRemove={onRemove}
            isAbsolute
          />
        ) : (
          <DropMessage>
            {isDragActive
              ? t('drop_file_hovering_placeholder')
              : t('drop_file_placeholder')}
          </DropMessage>
        )}
      </DropRoot>
    </AttachmentsSC>
  );
}

Attachments.propTypes = {
  loading: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
