/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-03-13 22:23:19
 */

import { axiosInstance } from 'utils/api-util';

export function getTicketService(ticketKey) {
  return axiosInstance({
    url: 'entity/ticket',
    params: {
      key: ticketKey,
    },
  });
}

export function generateTicketBreadcrumbs(data) {
  let newBreadcrumbs = [];
  if (data.project) {
    newBreadcrumbs.push({
      label: data.project.title,
      link: `/ticket-${data.project.key}`,
    });
  }
  if (data.feature) {
    newBreadcrumbs.push({
      label: data.feature.title,
      link: `/ticket-${data.feature.key}`,
    });
  }
  newBreadcrumbs.push({ label: 'Ticket-' + data?.key });
  return newBreadcrumbs;
}

export function getTabs(type, ticketKey) {
  const typeMap = {
    project: ['tasks', 'updates'],
    feature: ['tasks', 'updates'],
  };

  return typeMap[type].map((tab) => ({
    id: tab,
    name: tab,
    link: `/ticket-${ticketKey}/${tab}`,
  }));
}

export function addTicketComment(
  description,
  attachments,
  ticketId,
  ticketType,
) {
  const formData = new FormData();

  formData.append('id', ticketId);
  formData.append('entity', ticketType);
  formData.append('description', JSON.stringify(description));
  formData.append('attachments', JSON.stringify(attachments));

  return axiosInstance({
    url: 'entity/update',
    method: 'POST',
    data: formData,
  });
}
