/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-12 12:31:53
 * @modify date 2021-04-18 16:31:50
 */

/**
 * To check of the keyword is present in the search string.
 * @param {string} str is the complete string on which search needs to be performed
 * @param {key} key is the set of characters that will be used as keywords
 */
export function search(str, key) {
  return str.toLowerCase().search(key.toLowerCase()) !== -1;
}

export function truncate(text, length) {
  if (text && text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
}

export const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const isJSONString = (str) => {
  if (!str) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const createMarkup = (str) => {
  return { __html: str };
};

export const useQuery = (location) => {
  return new URLSearchParams(location.search);
};
