/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 15:46:04
 * @modify date 2020-12-27 15:46:04
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MemberNavigator from './MemberNavigator';

import Loading from 'components/common/loading/Loading';
import Error from 'components/common/error/Error';
import AppLogo from 'components/common/app-logo/AppLogo';

import { toggleAppDrawer, updatePageBottom } from 'actions/common-actions';
import {
  getUserData as getUserDataAction,
  getProfileData as getProfileDataAction,
} from 'actions/user-actions';

import { modalList } from 'utils/modal-util';

export default function MemberNavigatorContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAppDrawerOpen = useSelector(
    (state) => state.commonStates.isAppDrawerOpen,
  );

  const isOnPageBottom = useSelector(
    (state) => state.commonStates.isOnPageBottom,
  );

  const userData = useSelector((state) => state.userStates.userData);
  const workspaces = useSelector((state) => state.userStates.workspaces);

  const userDataError = useSelector((state) => state.userStates.userDataError);
  const activeModal = useSelector((state) => state.commonStates.activeModal);

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (!userData) {
      setLoading(true);
      const workspacesData = await dispatch(getUserDataAction());

      await dispatch(getProfileDataAction(workspacesData.length === 0));
      setLoading(false);
    }
  }, []);

  const handleToggleAppDrawer = () => {
    dispatch(toggleAppDrawer());
  };

  const handlePageScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom) {
      dispatch(updatePageBottom(true));
    } else if (isOnPageBottom) {
      dispatch(updatePageBottom(false));
    }
  };

  if (userDataError) {
    return <Error gap={100}>{t('something_went_wrong')}</Error>;
  }

  if (loading || !userData) {
    return (
      <>
        <Loading gap={200} />
        <AppLogo isFixedAtBottom />
      </>
    );
  }

  return (
    <MemberNavigator
      isAppDrawerOpen={isAppDrawerOpen}
      workspaces={workspaces}
      showNewTicket={activeModal === modalList.CREATE_TICKET_MODAL}
      onToggleAppDrawer={handleToggleAppDrawer}
      onPageScroll={handlePageScroll}
    />
  );
}
