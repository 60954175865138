/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-11 23:25:25
 * @modify date 2021-04-17 21:54:18
 */

import axios from 'axios';

import { axiosInstance } from 'utils/api-util';

import { dummyNewTicketActionResults } from 'constants/dummy-api-responses';

import { truncate } from 'utils/common-util';

export const PRIORITIES = [
  {
    key: 'normal_task',
    text: 'Normal Task',
    value: 'normal_task',
    apiValue: false,
  },
  {
    key: 'priority_task',
    text: 'Priority Task',
    value: 'priority_task',
    apiValue: true,
  },
];

export function getAllEntityData() {
  return axios.all([
    getListData('status'),
    getListData('type'),
    getListData('root'),
    getListData('assignee'),
    getListData('component'),
  ]);
}

export function getListData(attribute, term = '') {
  return axiosInstance({
    url: 'entity/search',
    params: {
      term,
      attribute,
      page: 0,
    },
  });
}

export const getAction = (title) => {
  for (let word in dummyNewTicketActionResults) {
    if (title.toLowerCase().search(word) !== -1) {
      return dummyNewTicketActionResults[word];
    }
  }
  return 0;
};

export const createTicket = (context, data) => {
  let formData = new FormData();

  if (context === 'edit') {
    formData.append('id', data.id);
  }

  formData.append('title', data.title);
  formData.append('isPriority', data.isPriority);
  formData.append('entity', data.entity);
  formData.append('description', JSON.stringify(data.description));
  formData.append('attachments', JSON.stringify(data.files));
  formData.append('img', data.image);
  if (data.assignee) {
    formData.append('assignee', data.assignee);
  }
  if (data.teamId) {
    formData.append('teamId', data.teamId);
  }
  if (data.projectId) {
    formData.append('projectId', data.projectId);
  }
  if (data.featureId) {
    formData.append('featureId', data.featureId);
  }

  if (data.status) {
    formData.append('status', data.status);
  }

  if (data.components) {
    formData.append('components', data.components.join(','));
  }

  return axiosInstance({
    method: context === 'create' ? 'POST' : 'PUT',
    url: `entity/ticket`,
    data: formData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

export const uploadFiles = (files) => {
  return axios.all(files.map((file) => uploadFile(file)));
};

export const uploadFile = (file) => {
  const newFormData = new FormData();
  newFormData.append('file', file);

  return axiosInstance({
    url: `upload_file`,
    method: 'POST',
    data: newFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export function parseTicketTypes(ticketTypes) {
  return ticketTypes.map((ticketType) => ({
    key: ticketType,
    text: ticketType,
    value: ticketType,
  }));
}

export function parseRoots(roots) {
  return roots.map((root) => ({
    id: root.id,
    img: root.img,
    title: root.title,
    description: root.entity,
  }));
}

export function parseContributors(contributors) {
  return contributors.map((contributor) => ({
    id: contributor.id,
    img: contributor.img,
    name: truncate(contributor.name, 14),
    description:
      contributor.entity === 'team'
        ? contributor.entity
        : truncate(contributor.designation, 10),
  }));
}

export function parseComponents(components) {
  return components.map((component) => ({
    key: component.id,
    text: component.tag,
    value: component.id,
  }));
}
