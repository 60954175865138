/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 15:22:25
 * @modify date 2021-02-24 15:22:25
 */

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ToastMessage from 'components/common/toast-message/ToastMessage';

import Workspace from './Workspace';

import {
  updateSelectedWorkspace,
  getWorkspaces,
  getProfileData,
} from 'actions/user-actions';

import {
  createWorkspace,
  editWorkspace,
  isEditMode,
} from './Workspace.service';

import { links } from 'utils/navigation-util';

export default function WorkspaceContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const userData = useSelector((state) => state.userStates.userData);
  const selectedWorkspace = useSelector(
    (state) => state.userStates.selectedWorkspace,
  );
  const workspaces = useSelector((state) => state.userStates.workspaces);

  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceError, setWorkspaceError] = useState('');
  const [workspaceNameLoading, setWorkspaceNameLoading] = useState(false);

  const setSelectedWorkspaceName = () => {
    if (selectedWorkspace && isEditMode(location.pathname)) {
      setWorkspaceName(selectedWorkspace.name);
    }
  };

  useEffect(setSelectedWorkspaceName, [selectedWorkspace, location.pathname]);

  const validateWorkspaceStep = (value = null) => {
    if (value === null) {
      value = workspaceName;
    }
    if (value) {
      setWorkspaceError('');
      return true;
    } else {
      setWorkspaceError(t('new_workspace.empty_workspace_error'));
      return false;
    }
  };

  const handleWorkspaceNameChange = (_, { value }) => {
    validateWorkspaceStep(value);
    setWorkspaceName(value);
  };

  const handleWorkspaceSubmit = () => {
    if (validateWorkspaceStep()) {
      setWorkspaceNameLoading(true);
      createWorkspace(workspaceName).then(async (response) => {
        await dispatch(updateSelectedWorkspace(response?.data));
        await dispatch(getWorkspaces());
        await dispatch(getProfileData());
        setWorkspaceNameLoading(false);

        history.push(links.invite_teammates.path);
      });
    }
  };

  const handleEditWorkspaceSubmit = () => {
    if (validateWorkspaceStep()) {
      setWorkspaceNameLoading(true);
      editWorkspace(selectedWorkspace.id, workspaceName).then(
        async (response) => {
          if (response.error) {
            // Show the error toast
            toast(
              <ToastMessage
                title={t('error')}
                // TODO: i18n integration
                message={t('There was some issue. Please try again later.')}
                status="error"
                isDarkText
              />,
            );
            return;
          }
          // Show Success toast
          toast(
            <ToastMessage
              title={t('success')}
              // TODO: i18n integration
              message={t('Workspace name updated!')}
              status="success"
              isDarkText
            />,
          );
          await dispatch(updateSelectedWorkspace(response?.data));
          await dispatch(getWorkspaces());
          await dispatch(getProfileData());
          setWorkspaceNameLoading(false);

          history.push(links.home.path);
        },
      );
    }
  };

  return (
    <Workspace
      isFirstWorkspace={workspaces.length === 0}
      mode={isEditMode(location.pathname) ? 'edit' : 'create'}
      userData={userData}
      workspaceName={workspaceName}
      workspaceNameLoading={workspaceNameLoading}
      workspaceError={workspaceError}
      onWorkspaceNameChange={handleWorkspaceNameChange}
      onWorkspaceSubmit={handleWorkspaceSubmit}
      onEditWorkspaceSubmit={handleEditWorkspaceSubmit}
    />
  );
}
