/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 18:30:25
 * @modify date 2021-01-12 17:11:09
 */

import styled from 'styled-components';

export const AuthWelcomeSC = styled.div`
  display: grid;
  height: 100%;
  padding: 0 20px;
  min-width: 416px;
  width: 416px;
  gap: 16px;
  justify-items: center;
  align-content: center;
`;

export const WelcomeMessage = styled.div`
  font-size: 22.4px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;

export const IllustrationCard = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
  justify-items: center;
  align-content: center;
`;

export const WelcomeSubText = styled.div`
  font-size: 13.6px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 12px;
`;

export const IllustrationImage = styled.img`
  height: 240px;
`;
