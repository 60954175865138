/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-22 17:46:01
 */

import styled from 'styled-components';
import { deviceBreakpoints, variables, zIndex } from 'variables';

export const MemberNavigatorSC = styled.div`
  display: flex;
`;

export const AppDrawerOverlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.overlayBackground};
  z-index: ${zIndex.appDrawerOverlay};

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: ${(props) => (props.isAppDrawerOpen ? 'block' : 'none')};
  }
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  background: ${(props) => props.theme.pageBackgroundColor};
  max-width: calc(
    100vw -
      ${(props) =>
        props.isAppDrawerOpen ? variables.appDrawerExpandedWidth : 0}px
  );
  transition: all 250ms ease;
`;

export const PageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: calc(100vh - ${variables.pageContentPadding * 2}px);
  width: 100%;
  overflow: auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex: 1;
  padding: ${variables.pageContentPadding}px;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: calc(
    100vh - ${variables.headerHeight + variables.pageContentPadding * 2}px
  );
  min-width: 860px;
`;
