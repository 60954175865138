/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:36:36
 */

import styled from 'styled-components';

export const SeparatorSC = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;
