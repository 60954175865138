/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-08 17:07:19
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const IconSC = styled.div`
  display: flex;
  width: ${(props) =>
    variables.buttonSize[props.size].height -
    variables.buttonSize[props.size].iconSizeReduction}px;
  height: ${(props) =>
    variables.buttonSize[props.size].height -
    variables.buttonSize[props.size].iconSizeReduction}px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;

  .k-icon {
    color: ${(props) => props.theme.button[props.buttonType].iconColor};
  }
`;

export const TextSC = styled.div`
  display: flex;
  flex: ${(props) => (props.centered ? 'unset' : '1')};
  align-items: center;
  padding: 0 5px;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  font-size: ${(props) => variables.buttonSize[props.size].fontSize}px;
  color: ${(props) => props.theme.button[props.buttonType].iconColor};
  transition: all 250ms ease;
`;

export const ButtonSC = styled.button`
  display: flex;
  border: none;
  outline: none;
  height: ${(props) => variables.buttonSize[props.size].height}px;
  width: ${(props) =>
    props.iconButton ? `${variables.buttonSize[props.size].height}px` : 'auto'};
  border-radius: ${(props) => (props.rounded ? '100px' : '4px')};
  background: ${(props) =>
    props.theme.button[props.buttonType].backgroundColor};
  color: ${(props) => props.theme.button[props.buttonType].fontColor};
  margin: 0;
  padding: 0 ${(props) => (props.iconButton ? '0' : '8px')};
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.iconButton ? 'unset' : 1)};
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.outlined ? props.theme.dividerColor : 'transparent')};
  box-shadow: ${(props) =>
    props.elevated ? props.theme.button[props.buttonType].boxShadow : 'none'};
  transition: all 250ms ease;

  .loading-img {
    height: 25px !important;
    * {
      fill: ${(props) => props.theme.white};
    }
  }

  &:hover {
    background: ${(props) =>
      props.theme.button[props.buttonType].backgroundHoverColor};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    box-shadow: none;
  }
`;
