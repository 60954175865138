/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:56:52
 * @modify date 2021-01-04 15:56:52
 */

import React from 'react';

import GuestNavigator from './GuestNavigator';

export default function GuestNavigatorContainer() {
  return <GuestNavigator />;
}
