/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-12 10:27:23
 * @modify date 2021-04-13 19:03:34
 */

import zxcvbn from 'zxcvbn';

export const UNACCECPTABLE_PASSWORD_SCORE = 2;

const PASSWORD_SCORE_LABEL = {
  0: 'Weak',
  1: 'Fair',
  2: 'Average',
  3: 'Good',
  4: 'Strong',
};

const PASSWORD_LABEL_COLORS = {
  Weak: '#aa0033',
  Fair: '#ffcc33',
  Average: '#ffcc33',
  Good: '#2d98f3',
  Strong: '#76c261',
};

export function calculatePasswordStrength(password) {
  const result = zxcvbn(password);
  if (password.length < 8) {
    return {
      score: 0,
      message: result?.feedback?.warning ?? '',
    };
  }

  return {
    score: result.score,
    message: result?.feedback?.warning ?? '',
  };
}

export function getPasswordLabelFromScore(score) {
  for (let key in PASSWORD_SCORE_LABEL) {
    if (score <= key) {
      return PASSWORD_SCORE_LABEL[key];
    }
  }
}

export function getPasswordColorFromLabel(label) {
  return PASSWORD_LABEL_COLORS[label];
}
