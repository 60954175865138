/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-06 20:05:16
 */

import styled from 'styled-components';

export const CustomIconSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  transition: all 250ms ease;

  svg {
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
  }
`;
