/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-23 16:07:34
 * @modify date 2021-01-23 16:08:22
 */

import styled from 'styled-components';

export const ErrorSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.gap}px auto;
  color: ${(props) => props.theme.errorColor};
`;
