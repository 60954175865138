/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-12 19:43:51
 * @modify date 2021-01-12 19:43:51
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckEmailSC, Heading, Body, LoginLink } from './CheckEmail.style';

import AppLogo from 'components/common/app-logo/AppLogo';

import { links } from 'utils/navigation-util';

export default function CheckEmail() {
  const { t } = useTranslation();

  return (
    <CheckEmailSC>
      <Heading>{t('verify_email_heading')}</Heading>
      <Body>{t('verify_email_text')}</Body>
      <LoginLink to={links.login.path}>{t('go_to_login')}</LoginLink>
      <AppLogo isFixedAtBottom />
    </CheckEmailSC>
  );
}
