/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-23 17:21:49
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const ItemCarouselSC = styled.div`
  flex: 1;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  gap: ${variables.carousel.gap}px;
  grid-template-columns: ${variables.carousel.buttonsWidth}px 1fr ${variables
      .carousel.buttonsWidth}px;
`;

export const CarouselContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: ${variables.highlightCardWidth}px;
  overflow-x: hidden;
  height: 100%;
`;

export const CarouselCotentInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  transition: all 250ms ease;
  transform: translateX(
    -${(props) => props.activeCardIndex * variables.highlightCardWidth}px
  );
`;
