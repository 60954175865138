/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-17 22:00:01
 * @modify date 2021-03-01 20:21:34
 */

import { axiosInstance } from 'utils/api-util';

const whiteListedEntities = ['project', 'feature', 'task', 'team', 'user'];

export function getSearchData(term = '', page = 0) {
  return axiosInstance({
    url: `entity/search`,
    params: {
      term,
      page,
    },
  }).then((response) => {
    const data = response?.data?.data ?? [];
    return {
      hasMore: response?.data?.hasMore,
      data: data.filter(
        (item) => whiteListedEntities.indexOf(item.entity) !== -1,
      ),
    };
  });
}

export function getEntityLink(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
    case 'task':
      return `/ticket-${result.key}`;
    case 'team':
      return `/team/${result.id}`;
    case 'user':
      return `/user/${result.id}`;
    default:
      return '/';
  }
}

export function getEntityImage(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
    case 'task':
    case 'team':
    case 'user':
      return result.img;
    default:
      return '';
  }
}

export function getEntityName(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
    case 'task':
      return result.title;
    case 'team':
    case 'user':
      return result.name;
    default:
      return '-';
  }
}

export function getEntityType(result) {
  switch (result.entity) {
    case 'project':
    case 'feature':
    case 'task':
    case 'team':
      return result.entity;
    case 'user':
      return result.designation;
    default:
      return '';
  }
}
