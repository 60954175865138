/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-09 16:59:42
 * @modify date 2021-01-09 17:24:19
 */

import styled from 'styled-components';

export const ToastMessageSC = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  justify-content: start;
`;

export const IconSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 100px;
  background: ${(props) => props.theme.toast.icon.backgroundColor};
`;

export const TextSC = styled.div`
  display: grid;
  gap: 5px;
`;

export const TitleSC = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14.3px;
  line-height: 17px;
  color: ${(props) =>
    props.isDarkText ? props.theme.black : props.theme.white};
`;

export const MessageSC = styled.div`
  display: flex;
  font-size: 13.5px;
  line-height: 16px;
  color: ${(props) =>
    props.isDarkText ? props.theme.blackText2 : props.theme.grayE};
`;
