/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { useState, useEffect } from 'react';

import GanttChart from './GanttChart';
import PropTypes from 'prop-types';

import Loading from 'components/common/loading/Loading';
// import NoData from 'components/common/no-data/NoData';

import { getGanttInfoService } from './GanttChart.service';

export default function GanttChartContainer({ entity, id }) {
  const [ganttInfo, setGanttInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const getGanttInfo = () => {
    getGanttInfoService(entity, id).then(() => {
      setGanttInfo(null);
      setLoading(false);
    });
    // getGanttInfoService(entity, id).then((response) => {
    //   setGanttInfo(response);
    //   setLoading(false);
    // });
  };

  useEffect(getGanttInfo, []);

  if (loading) {
    return <Loading gap={200} />;
  }

  if (!ganttInfo) {
    // return <NoData />;
    return null;
  }

  return (
    <GanttChart
      numberOfTimePeriods={ganttInfo.totalWeeks}
      tickets={ganttInfo.data}
    />
  );
}

GanttChartContainer.propTypes = {
  entity: PropTypes.string,
  id: PropTypes.string,
};

GanttChartContainer.defaultProps = {
  entity: '',
  id: '',
};
