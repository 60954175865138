/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-20 17:54:37
 * @modify date 2021-01-20 17:54:37
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  NavbarSC,
  NavbarContainer,
  Brand,
  LogoSC,
  Name,
  Links,
  PhoneToggleButton,
  LinksPhone,
  LinkSC,
  PhoneLinkContainer,
  PhoneLinkSC,
} from './Navbar.style';

import Icon from 'components/common/icon/Icon';

import Logo from 'assets/images/logo.svg';
import Config from 'app.config';

const links = ['vision', 'solution', 'features', 'contact_us'];

export default function Navbar({ refs }) {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeLink, setActiveLink] = useState('');
  const [isPhoneDropdownOpen, setIsPhoneDropdownOpen] = useState(false);

  const findActiveLinkIndex = () => {
    let sectionDistances = [];

    refs.forEach((ref) => {
      const x = ref.current.offsetTop;
      const y = ref.current.getBoundingClientRect().height;
      const s = window.pageYOffset;
      const h = window.innerHeight;
      sectionDistances.push(Math.abs(Math.floor(x + y / 2 - (s + h / 2))));
    });

    const minDistance = Math.min(...sectionDistances);

    const activeDistanceIndex = sectionDistances.indexOf(minDistance);
    if (activeDistanceIndex !== -1) {
      setActiveLink(links[activeDistanceIndex]);
    }
  };

  useEffect(findActiveLinkIndex, [scrollPosition]);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NavbarSC isScrolled={scrollPosition !== 0 || isPhoneDropdownOpen}>
      <NavbarContainer isScrolled={scrollPosition !== 0}>
        <Brand href="/#home">
          <LogoSC src={Logo} alt={Config.appName} />
          <Name>{Config.appName}</Name>
        </Brand>
        <Links>
          {links.map((link) => (
            <LinkSC key={link} href={`/#${link}`} active={activeLink === link}>
              {t(link)}
            </LinkSC>
          ))}
          <LinkSC href="/login" highlight>
            {t('sign_in')}
          </LinkSC>
          <LinkSC href="/register" isBlock>
            {t('try_it')}
          </LinkSC>
        </Links>

        <PhoneToggleButton
          onClick={() => setIsPhoneDropdownOpen((old) => !old)}
        >
          <Icon
            name={isPhoneDropdownOpen ? 'close' : 'menu'}
            isDark
            size={23}
          />
        </PhoneToggleButton>
      </NavbarContainer>

      <LinksPhone show={isPhoneDropdownOpen}>
        {links.map((link) => (
          <PhoneLinkContainer key={link}>
            <PhoneLinkSC
              href={`/#${link}`}
              active={activeLink === link}
              onClick={() => setIsPhoneDropdownOpen(false)}
            >
              {t(link)}
            </PhoneLinkSC>
          </PhoneLinkContainer>
        ))}
        <PhoneLinkSC href="/login" highlight>
          {t('sign_in')}
        </PhoneLinkSC>
        <PhoneLinkSC href="/register" isBlock>
          {t('try_it')}
        </PhoneLinkSC>
      </LinksPhone>
    </NavbarSC>
  );
}

Navbar.propTypes = {
  refs: PropTypes.array.isRequired,
};
