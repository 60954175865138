/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-02-04 18:21:23
 */

import styled from 'styled-components';

export const TicketsSC = styled.div`
  display: grid;
  width: calc(100% + 12px);
  gap: 7px;
`;

export const TicketStatus = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  height: 34px;
  border-radius: 4px;
  width: 100%;
  background: ${(props) => props.theme.white};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 100px;
  margin: 5px 8px;
  box-shadow: ${(props) => props.theme.boxShadow2};

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const TextData = styled.div`
  display: grid;
  grid-template-rows: 12px 12px;
  align-items: start;
`;

export const Number = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-family: Lato;
  font-weight: bold;
`;

export const Label = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${(props) => props.theme.secondaryTextColor};
  text-transform: capitalize;
`;
