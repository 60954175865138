/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-26 16:10:37
 * @modify date 2021-02-26 16:10:37
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  WorkspaceNameSC,
  WorkspaceLogo,
  WorkspaceDefaultLogo,
  WorkspaceText,
} from './WorkspaceName.style';

import { truncate } from 'utils/common-util';

export default function WorkspaceName({
  workspaceName,
  workspaceLogo,
  variant,
}) {
  return (
    <WorkspaceNameSC data-testid="workspace-name">
      {workspaceLogo ? (
        <WorkspaceLogo src={workspaceLogo} alt={workspaceName} />
      ) : (
        <WorkspaceDefaultLogo variant={variant}>
          {workspaceName[0]}
        </WorkspaceDefaultLogo>
      )}
      <WorkspaceText variant={variant}>
        {truncate(workspaceName, 15)}
      </WorkspaceText>
    </WorkspaceNameSC>
  );
}

WorkspaceName.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  workspaceLogo: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
};

WorkspaceName.defaultProps = {
  workspaceLogo: '',
  variant: 'light',
};
