/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-14 01:08:20
 * @modify date 2021-03-14 01:08:20
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  DashboardFilterSC,
  Overlay,
  SearchInputContainer,
  Popup,
  SearchResultRow,
  RowContent,
  ResultTitle,
  Type,
  NoResult,
  InputContainer,
  Input,
  TagLabel,
  Tag,
  RemoveIcon,
} from './DashboardFilter.style';

import Loading from 'components/common/loading/Loading';
import Icon from 'components/common/icon/Icon';
import SearchEntityImage from 'components/common/search-entity-image/SearchEntityImage';

import {
  getTagLabel,
  getEntityName,
  getEntityType,
} from './DashboardFilter.service';

export default function DashboardFilter({
  filters,
  showPopup,
  searchResults,
  loading,
  pageLoading,
  searchValue,
  onSearchChange,
  onPopupClose,
  onLinkClick,
  onScroll,
  onSearchFieldFocus,
  onRemoveFilter,
}) {
  const { t } = useTranslation();

  return (
    <DashboardFilterSC data-testid="filter" open={showPopup}>
      {showPopup ? <Overlay onClick={onPopupClose} /> : null}

      <SearchInputContainer open={showPopup}>
        {filters.map((filter) => (
          <Tag key={filter.id}>
            <TagLabel title={getTagLabel(filter)}>
              {getTagLabel(filter)}
            </TagLabel>
            <RemoveIcon onClick={() => onRemoveFilter(filter.id)}>
              <Icon name="close" size={12} isDark />
            </RemoveIcon>
          </Tag>
        ))}
        <InputContainer>
          <Input
            placeholder={t('add_filter')}
            value={searchValue}
            onChange={onSearchChange}
            onFocus={onSearchFieldFocus}
          />
          <Popup open={showPopup} onScroll={onScroll}>
            {loading ? (
              <Loading />
            ) : searchResults.length ? (
              searchResults.map((result) => (
                <SearchResultRow
                  key={result.id}
                  className="animate__animated animate__fadeIn"
                  onClick={() => onLinkClick(result)}
                >
                  <RowContent>
                    <SearchEntityImage
                      src={result.img}
                      alt={getEntityName(result)}
                      title={getEntityName(result)}
                    />
                    <ResultTitle>{getEntityName(result)}</ResultTitle>
                    <Type>{getEntityType(result)}</Type>
                  </RowContent>
                </SearchResultRow>
              ))
            ) : (
              <NoResult>{t('no_result_found')}</NoResult>
            )}
            {searchResults.length && pageLoading ? <Loading /> : null}
          </Popup>
        </InputContainer>
      </SearchInputContainer>
    </DashboardFilterSC>
  );
}

DashboardFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  showPopup: PropTypes.bool.isRequired,
  searchResults: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onSearchFieldFocus: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};
