/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 17:56:20
 * @modify date 2021-01-08 17:56:20
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TaskSC,
  PageHeaderContainer,
  HighlightCardContainer,
} from './Task.style';

import PageHeader from 'components/common/page-header/PageHeader';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import Card from 'components/common/card/Card';
import CardHeader from 'components/common/card/card-header/CardHeader';
import CardBody from 'components/common/card/card-body/CardBody';
import TimeRange from 'components/common/time-range/TimeRange';
import MenuButton from 'components/common/menu-button/MenuButton';

import NewCommentContainer from 'components/layout/new-comment/NewComment.container';
import Highlights from 'components/layout/highlights/Highlights.container';
import UpdatesContainer from 'components/layout/updates/Updates.container';
import SummaryCardContainer from 'components/layout/summary-card/SummaryCard.container';
import ChangeableAvatarContainer from 'components/layout/changeable-avatar/ChangeableAvatar.container';

import { getTimeRange, getExtremeRelativeTime } from 'utils/date-formats-util';
import { getIconByTicket } from 'utils/ticket-status-util';

export default function Task({
  data,
  breadcrumbs,
  onPageHeaderOptionClick,
  onCommentSubmit,
}) {
  const { t } = useTranslation();

  return (
    <TaskSC data-testid="task-page">
      <PageHeaderContainer className="animate__animated animate__fadeIn">
        <PageHeader
          icon={data.img}
          entityName={t('task')}
          heading={data.title}
          pageWidget={
            <>
              <TimeRange
                isPriority={data.isPriority}
                icon={getIconByTicket(data)}
                range={getTimeRange(data.createdAt, data.endDate ?? new Date())}
                duration={getExtremeRelativeTime(
                  data.createdAt,
                  data.endDate ?? new Date(),
                )}
                updateStatus
                ticket={data}
              />
              <ChangeableAvatarContainer ticket={data} user={data.assignee} />
            </>
          }
          menuButton={
            <MenuButton
              options={[
                {
                  key: 'edit',
                  label: t('edit'),
                },
              ]}
              onOptionClick={(option) => onPageHeaderOptionClick(option, data)}
            />
          }
          footer={<Breadcrumbs links={breadcrumbs} />}
        />
      </PageHeaderContainer>

      <HighlightCardContainer>
        <Highlights
          entity="task"
          id={data.id}
          restProps={{ isAnimated: true }}
        />
      </HighlightCardContainer>

      <SummaryCardContainer
        summary={data.description}
        attachments={data.attachments ?? []}
      />

      <Card className="animate__animated animate__fadeIn">
        <CardHeader bold>{t('comment')}</CardHeader>
        <CardBody padded>
          <NewCommentContainer onSubmit={onCommentSubmit} />
        </CardBody>
      </Card>

      <UpdatesContainer entity="task" id={data.id} />
    </TaskSC>
  );
}

Task.propTypes = {
  /** data object */
  data: PropTypes.object,
  /** breadcrums for page header */
  breadcrumbs: PropTypes.array.isRequired,
  /** handler for option click on page header */
  onPageHeaderOptionClick: PropTypes.func.isRequired,
  /** handler for comment submit */
  onCommentSubmit: PropTypes.func.isRequired,
};
