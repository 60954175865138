/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-09 23:20:08
 * @modify date 2021-04-09 23:20:08
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

export default function Redirects({ links, redirectLink }) {
  const location = useLocation();

  return links.map((link) => (
    <Route key={link.id} path={link.path}>
      <Redirect
        to={{
          pathname: redirectLink,
          state: { from: location.pathname },
        }}
      />
    </Route>
  ));
}

Redirects.propTypes = {
  links: PropTypes.array.isRequired,
  redirectLink: PropTypes.string.isRequired,
};
