/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-15 23:09:20
 * @modify date 2021-01-16 16:11:06
 */

import { notifications } from 'constants/notifications-api';
import { messages } from 'constants/messages-api';
import { bookmarks } from 'constants/bookmarks-api';

import { delay } from 'utils/common-util';

export function getDataService(type) {
  if (type === 'notifications') {
    return delay(500).then(() => ({ data: notifications }));
  } else if (type === 'messages') {
    return delay(500).then(() => ({ data: messages }));
  } else if (type === 'bookmarks') {
    return delay(500).then(() => ({ data: bookmarks }));
  }
}
