/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 15:36:07
 * @modify date 2020-12-27 15:36:07
 */

import React from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';

export default function HeaderContainer() {
  const isAppDrawerOpen = useSelector(
    (state) => state.commonStates.isAppDrawerOpen,
  );

  return <Header isAppDrawerOpen={isAppDrawerOpen} />;
}
