/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CustomIconSC } from './CustomIcon.style';

import { ReactComponent as PlateauLogo } from 'assets/images/logo.svg';
import { ReactComponent as PlateauLogoNoBG } from 'assets/images/logo-no-bg-blue.svg';
import { ReactComponent as Priority } from 'assets/images/icons/priority.svg';
import { ReactComponent as Google } from 'assets/images/icons/google.svg';
import { ReactComponent as LinkedIn } from 'assets/images/icons/linkedin.svg';
import { ReactComponent as File } from 'assets/images/icons/file.svg';

const iconMap = {
  PlateauLogo,
  PlateauLogoNoBG,
  Priority,
  Google,
  LinkedIn,
  File,
};

export default function CustomIcon({ name, size }) {
  return (
    <CustomIconSC
      data-testid="custom-icon"
      className="k-custom-icon"
      size={size}
    >
      {React.createElement(iconMap[name])}
    </CustomIconSC>
  );
}

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

CustomIcon.defaultProps = {
  size: 16,
};
