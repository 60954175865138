/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:27
 * @modify date 2021-03-13 23:53:38
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const DashboardListSC = styled.div`
  flex: 1;
  display: grid;
  gap: 16px;
`;

export const FilterBodyContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 88px;
  gap: 20px;

  .k-button {
    .k-button-text,
    .k-icon {
      color: ${(props) => props.theme.primaryColor} !important;
    }
  }
`;

export const DashboardListContainer = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
`;

export const DashboardCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 150px 150px;
  align-items: center;
  justify-items: start;
  flex: 1;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow1};
  border-radius: 8px;
  padding: ${variables.cardPadding}px;
  animation-delay: ${(props) => props.index * 100}ms;
`;

export const TagContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
`;
