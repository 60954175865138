/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 17:20:22
 * @modify date 2021-03-10 17:20:22
 */

import styled, { css } from 'styled-components';

export const NewCommentSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 34px 56px;
  gap: 10px;
  align-items: end;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.black};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.blackA2};
  min-height: 34px;
  transition: min-height 250ms ease;

  ${(props) =>
    props.cut &&
    css`
      max-height: 34px;
      overflow: hidden;
    `};

  .k-editor-container {
    margin: 0 auto;
    height: 100%;

    &&& {
      .codex-editor__redactor {
        padding-bottom: 0 !important;
      }
    }
    .ce-block__content {
      max-width: calc(100% - 40px);
    }
    .ce-toolbar__content {
      max-width: calc(100% - 40px);
    }
    .ce-toolbar__actions {
      right: -20px;
    }
  }
`;

export const AttachmentButton = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 34px;

  &:hover {
    background: ${(props) => props.theme.lightBackgroundColor};
  }

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const AttachmentInput = styled.input`
  position: absolute;
  height: 34px;
  width: 34px;
  visibility: hidden;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: visible;
    cursor: pointer;
  }
`;
