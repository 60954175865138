/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 22:04:45
 * @modify date 2021-02-24 22:04:45
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Label, Button, Icon } from 'semantic-ui-react';

import {
  CreateWorkspaceSC,
  Heading,
  SubHeading,
  FormSC,
  SubmitButtonContainer,
} from './CreateWorkspace.style';

import { links } from 'utils/navigation-util';

export default function CreateWorkspace({
  mode,
  workspaceName,
  workspaceNameLoading,
  error,
  showCancelButton,
  onWorkspaceNameChange,
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <CreateWorkspaceSC>
      <Heading>{t('new_workspace.step1_heading')}</Heading>
      <SubHeading>{t('new_workspace.step1_sub_heading')}</SubHeading>

      <FormSC>
        <FormSC.Field>
          <Input
            type="text"
            placeholder={t('new_workspace.create_workspace_placeholder')}
            value={workspaceName}
            onChange={onWorkspaceNameChange}
            fluid
            error={error ? true : false}
            autoFocus
          />
          {error ? (
            <Label pointing prompt>
              {error}
            </Label>
          ) : null}
        </FormSC.Field>

        <SubmitButtonContainer>
          <Button
            primary
            className="c-button"
            loading={workspaceNameLoading}
            onClick={onSubmit}
          >
            {mode === 'create' ? t('create_workspace') : t('edit_workspace')}
            <Icon name="chevron right" />
          </Button>
          {showCancelButton ? (
            <Button href={links.home.path} basic className="c-basic-button">
              {t('cancel')}
            </Button>
          ) : null}
        </SubmitButtonContainer>
      </FormSC>
    </CreateWorkspaceSC>
  );
}

CreateWorkspace.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']),
  workspaceName: PropTypes.string.isRequired,
  workspaceNameLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  showCancelButton: PropTypes.bool,
  onWorkspaceNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

CreateWorkspace.defaultProps = {
  mode: 'create',
  showCancelButton: false,
};
