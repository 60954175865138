/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-12 19:49:39
 * @modify date 2021-01-25 18:27:55
 */

import styled from 'styled-components';

export const ActivatingAccountSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Heading = styled.h2`
  display: flex;
  text-align: center;
`;
