/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-23 16:34:55
 * @modify date 2021-03-03 21:52:57
 */

import styled from 'styled-components';

export const AttachmentsSC = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const DropRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.lightBackgroundColor};
  border: 5px dashed
    ${(props) =>
      props.isDragActive
        ? props.theme.primaryColorA3
        : props.theme.dividerColor};
  border-radius: 8px;
  font-size: 18px;
  color: ${(props) => props.theme.blackText2};
  cursor: pointer;
`;

export const DropInput = styled.input`
  display: flex;
  width: 100%;
  outline: none;
  border: none;

  &:focus {
    outline: none;
    border: none;
  }
`;

export const DropMessage = styled.p`
  padding: 10px;
  text-align: center;
`;
