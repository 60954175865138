/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 13:43:19
 * @modify date 2021-04-19 18:59:41
 */

import {
  FEATCH_USER_DATA,
  FEATCH_USER_DATA_LOADING,
  FEATCH_USER_DATA_ERROR,
  FEATCH_USER_WORKSPACES,
  UPDATE_SELECTED_WORKSPACE,
} from 'types';

import {
  saveInLocalStore,
  getFromLocalStore,
} from 'utils/state-persistence-util';

const PREFIX = 'userStates';

const initialState = {
  userData: getFromLocalStore('userData', PREFIX, null),
  userDataLoading: false,
  userDataError: false,
  workspaces: getFromLocalStore('workspaces', PREFIX, []),
  selectedWorkspace: getFromLocalStore('selectedWorkspace', PREFIX, null),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FEATCH_USER_DATA:
      saveInLocalStore('userData', action.payload, PREFIX);
      return {
        ...state,
        userData: action.payload,
        userDataLoading: false,
        userDataError: false,
      };
    case FEATCH_USER_DATA_LOADING:
      return {
        ...state,
        userDataLoading: action.payload,
      };
    case FEATCH_USER_DATA_ERROR:
      return {
        ...state,
        userDataError: action.payload,
      };
    case FEATCH_USER_WORKSPACES:
      saveInLocalStore('workspaces', action.payload, PREFIX);
      return {
        ...state,
        workspaces: action.payload,
      };
    case UPDATE_SELECTED_WORKSPACE:
      saveInLocalStore('selectedWorkspace', action.payload, PREFIX);
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    default:
      return state;
  }
}
