/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-05 15:26:01
 * @modify date 2021-03-05 15:26:01
 */

import styled from 'styled-components';

export const ProjectLogoSC = styled.div`
  display: flex;
`;

export const DropRoot = styled.div`
  display: grid;
  gap: 16px;
`;

export const DropInput = styled.input`
  display: flex;
  width: 100%;
  outline: none;
  border: none;

  &:focus {
    outline: none;
    border: none;
  }
`;

export const DropBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: ${(props) => props.theme.primaryColorA2};
  border: 2px dashed
    ${(props) =>
      props.isDragActive
        ? props.theme.primaryColorA3
        : props.theme.primaryColorA1};
  border-radius: 8px;
  font-size: 18px;
  color: ${(props) => props.theme.blackText2};
  cursor: pointer;
`;

export const UploadTextContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const UploadText = styled.div`
  display: flex;
  margin-left: 4px;
  font-size: 16px;
  color: ${(props) => props.theme.primaryColor};
`;

export const ImagePreview = styled.img`
  display: flex;
  max-height: 100%;
  max-width: 100%;
`;
