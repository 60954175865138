/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 08:50:28
 * @modify date 2021-01-06 08:50:28
 */

import React from 'react';
import PropTypes from 'prop-types';

import { InputSC, IconContainer, InputField } from './Input.style';

export default function Input({ leftIcon, inputProps, size }) {
  return (
    <InputSC data-testid="input" size={size}>
      {leftIcon ? <IconContainer>{leftIcon}</IconContainer> : null}
      <InputField isLeftIcon={leftIcon !== null} {...inputProps} />
    </InputSC>
  );
}

Input.propTypes = {
  leftIcon: PropTypes.element,
  inputProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Input.defaultProps = {
  leftIcon: null,
  size: 'medium',
};
