/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { UpdatesSC } from './Updates.style';

import UpdateCard from 'components/common/update-card/UpdateCard';
import TicketBasicData from 'components/common/update-card/ticket-basic-data/TicketBasicData';
import TicketUpdateData from 'components/common/update-card/ticket-update-data/TicketUpdateData';
import HighlightCard from 'components/common/highlight-card/HighlightCard';
import Loading from 'components/common/loading/Loading';

export default function Updates({
  updates,
  loading,
  onLikeClick,
  onNewCommentSubmit,
}) {
  return (
    <UpdatesSC data-testid="updates">
      {updates.map((update, index) => (
        <UpdateCard
          key={update.id}
          index={index}
          update={update}
          onLikeClick={() => onLikeClick(update.id, index)}
          onNewCommentSubmit={(...params) =>
            onNewCommentSubmit(...params, update.id, index)
          }
          renderBasicData={
            <TicketBasicData
              ticket={update.updateOn}
              ticketAssignee={update.assignee}
            />
          }
          renderUpdateData={
            <TicketUpdateData
              description={update.description ?? ''}
              attachments={update.attachments ?? []}
              itemCards={update.highlights.map((highlight) => (
                <HighlightCard
                  key={highlight.id}
                  header={highlight.header}
                  type={highlight.type}
                  data={highlight.data}
                />
              ))}
            />
          }
        />
      ))}
      {loading ? <Loading gap={100} /> : null}
    </UpdatesSC>
  );
}

Updates.propTypes = {
  updates: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onNewCommentSubmit: PropTypes.func.isRequired,
};
