/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { NumberBubbleSC } from './NumberBubble.style';

export default function NumberBubble({ number }) {
  return <NumberBubbleSC>{number}</NumberBubbleSC>;
}
NumberBubble.propTypes = {
  /** number to be shown in the bubble */
  number: PropTypes.number,
};

NumberBubble.defaultProps = {
  number: 0,
};
