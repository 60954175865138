/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:55:55
 * @modify date 2021-01-04 15:55:55
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonSM, Icon as IconSM } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  LoginSC,
  BackButtonContainer,
  LeftSection,
  RightSection,
  FormContainer,
  FormInner,
  FormHeading,
  FormGroup,
  RememberMeForgotPasswordContainer,
  ForgotPasswordLink,
  LoginButtonContainer,
  CreateAccountContainer,
  NotMemberText,
  CreateAccountLink,
} from './Login.style';

import AuthWelcome from 'components/layout/auth-welcome/AuthWelcome';
import OAuthButtonsContainer from 'components/layout/oauth-buttons/OAuthButtons.container';

import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';
import Input from 'components/common/input/Input';
import Checkbox from 'components/common/checkbox/Checkbox';
import Divider from 'components/common/divider/Divider';
import FormError from 'components/common/form-error/FormError';

import { links } from 'utils/navigation-util';

export default function Login({
  rememberMeCheckbox,
  loading,
  isError,
  errorMessages,
  onRememberMeToggle,
  onLoginSubmit,
}) {
  const { t } = useTranslation();

  return (
    <LoginSC>
      <BackButtonContainer to={links.landing.path}>
        <ButtonSM basic size="small">
          <IconSM name="arrow left" />
          {t('back')}
        </ButtonSM>
      </BackButtonContainer>
      <LeftSection>
        <AuthWelcome />
      </LeftSection>

      <RightSection>
        <FormContainer className="animate__animated animate__slideInRight">
          <FormInner>
            <FormHeading>{t('sign_in')}</FormHeading>

            <OAuthButtonsContainer />

            <FormGroup onSubmit={onLoginSubmit}>
              <Input
                leftIcon={<Icon name="mail" iconTheme="outlined" />}
                inputProps={{
                  placeholder: t('email'),
                  type: 'email',
                  required: true,
                }}
              />
              <Input
                leftIcon={<Icon name="lock" iconTheme="outlined" />}
                inputProps={{
                  placeholder: t('password'),
                  type: 'password',
                  required: true,
                }}
              />
              <RememberMeForgotPasswordContainer>
                <Checkbox
                  id="remember_me"
                  label={t('remember_me')}
                  checked={rememberMeCheckbox}
                  onChange={onRememberMeToggle}
                />
                <ForgotPasswordLink to={links.forgot_password.path}>
                  {t('forgot_password')}
                </ForgotPasswordLink>
              </RememberMeForgotPasswordContainer>
              <LoginButtonContainer>
                {isError ? <FormError errorMessages={errorMessages} /> : null}
                <Button
                  size="large"
                  centered
                  fullWidth
                  loading={loading}
                  text={t('login')}
                />
              </LoginButtonContainer>
              <Divider />
              <CreateAccountContainer>
                <NotMemberText>{t('not_a_member_yet')}</NotMemberText>
                &nbsp;
                <CreateAccountLink to={links.register.path}>
                  {t('create_account')}
                </CreateAccountLink>
              </CreateAccountContainer>
            </FormGroup>
          </FormInner>
        </FormContainer>
      </RightSection>
    </LoginSC>
  );
}

Login.propTypes = {
  rememberMeCheckbox: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessages: PropTypes.array.isRequired,
  onRememberMeToggle: PropTypes.func.isRequired,
  onLoginSubmit: PropTypes.func.isRequired,
};
