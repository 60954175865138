/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-25 18:40:18
 * @modify date 2021-01-25 20:30:10
 */

import { axiosInstanceNoAuth } from 'utils/api-util';

export function verifyToken(token) {
  return axiosInstanceNoAuth.get(`verify`, {
    params: {
      token,
    },
  });
}
