/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { GanttChartSC } from './GanttChart.style';

import GanttChart from 'components/common/gantt-chart/GanttChart';

export default function GanttChartCard({ numberOfTimePeriods, tickets }) {
  if (!tickets.length) return null;

  return (
    <GanttChartSC>
      <GanttChart
        numberOfTimePeriods={numberOfTimePeriods}
        entities={tickets}
      />
    </GanttChartSC>
  );
}

GanttChartCard.propTypes = {
  numberOfTimePeriods: PropTypes.number.isRequired,
  tickets: PropTypes.array.isRequired,
};
