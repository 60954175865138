/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-27 20:45:03
 * @modify date 2021-04-13 17:25:18
 */

import styled from 'styled-components';

export const OAuthButtonsSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;

  .k-button {
    height: 40px;
    .k-button-text {
      font-weight: bold;
      font-size: 12.8px;
      line-height: 15px;
    }
  }
`;
