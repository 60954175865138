/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-12 18:01:04
 */

import styled from 'styled-components';

export const NotFoundSC = styled.div`
  flex: 1;
  height: 100%;
`;

export const Card = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 8px;
  padding: 100px 0;
  text-align: center;
  color: ${(props) => props.theme.notFound.fontColor};
  background: ${(props) => props.theme.notFound.backgroundColor};
  transition: all 250ms ease;
`;

export const Heading = styled.h2`
  font-size: 96px;
`;

export const SubHeading = styled.p`
  text-align: center;
`;
