/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-04-17 17:28:31
 */

export const links = {
  login: {
    id: 'login',
    path: '/login',
    label: 'login',
    icon: 'login',
  },
  register: {
    id: 'register',
    path: '/register',
    label: 'register',
    icon: 'register',
  },
  check_email: {
    id: 'check_email',
    path: '/check-email',
    label: 'check-email',
    icon: 'check-email',
  },
  verify: {
    id: 'verify',
    path: '/verify',
    label: 'verify',
    icon: 'verify',
  },
  terms_of_service: {
    id: 'terms_of_service',
    path: '/terms-of-service',
    label: 'terms-of-service',
    icon: 'terms-of-service',
  },
  privacy_policy: {
    id: 'privacy_policy',
    path: '/privacy-policy',
    label: 'privacy-policy',
    icon: 'privacy-policy',
  },
  landing: {
    id: 'landing',
    path: '/',
    label: 'landing',
    icon: 'landing',
  },

  home: {
    id: 'home',
    path: '/home',
    label: 'home',
    icon: 'home',
  },
  forgot_password: {
    id: 'forgot_password',
    path: '/forgot-password',
    label: 'forgot_password',
    icon: 'forgot_password',
  },
  my_tickets: {
    id: 'my_tickets',
    path: '/my-tickets',
    label: 'my_tickets',
    icon: 'assignment_turned_in',
  },
  tickets: {
    id: 'tickets',
    path: '/tickets',
    label: 'tickets',
    icon: 'featured_play_list',
  },
  people: {
    id: 'people',
    path: '/people',
    label: 'people',
    icon: 'people',
  },
  dashboards: {
    id: 'dashboards',
    path: '/dashboards',
    label: 'dashboards',
    icon: 'equalizer',
  },

  workspace: {
    id: 'workspace',
    path: '/workspace',
    label: 'workspace',
    icon: 'workspace',
  },
  workspace_edit: {
    id: 'workspace_edit',
    path: '/workspace/edit',
    label: 'workspace_edit',
    icon: 'workspace_edit',
  },
  invite_teammates: {
    id: 'invite_teammates',
    path: '/workspace/invite-teammates',
    label: 'invite_teammates',
    icon: 'invite_teammates',
  },
  user: {
    id: 'user',
    path: '/user/:userId',
    label: 'user',
    icon: 'user',
  },
  team: {
    id: 'team',
    path: '/team/:teamId',
    label: 'team',
    icon: 'team',
  },
  dashboard: {
    id: 'dashboard',
    path: '/dashboard/:dashboardId',
    label: 'dashboard',
    icon: 'dashboard',
  },
  ticket: {
    id: 'ticket',
    path: '/ticket-:ticketKey',
    label: 'ticket',
    icon: 'ticket',
  },
};

export const appDrawerLinks = [
  links.home,
  links.my_tickets,
  links.tickets,
  links.people,
  links.dashboards,
];

export const guestLinks = [
  links.login,
  links.register,
  links.check_email,
  links.verify,
  links.terms_of_service,
  links.privacy_policy,
  links.landing,
];

export const memberLinks = [
  links.home,
  links.my_tickets,
  links.tickets,
  links.people,
  links.dashboards,
  links.workspace,
  links.user,
  links.team,
  links.dashboard,
  links.ticket,
];
