/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 22:06:42
 * @modify date 2021-03-10 22:06:42
 */

import styled, { css } from 'styled-components';

export const AttachmentGridSC = styled.div`
  height: calc(100% - 10px);
  max-height: calc(100% - 10px);
  width: calc(100% - 10px);
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 72px);
  gap: 12px;
  padding: 12px;
  align-items: start;
  align-content: start;

  ${(props) =>
    props.isAbsolute &&
    css`
      position: absolute;
    `};
`;

export const CloseIconContainer = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  background: ${(props) => props.theme.actionColor};
  border-radius: 100px;
  box-shadow: ${(props) => props.theme.boxShadow2};
`;

export const FileBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 72px;
  width: 72px;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.dividerColor};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadow1};
  cursor: default;

  &:hover {
    ${CloseIconContainer} {
      display: flex;
    }
  }
`;

export const FilePreview = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ImagePreview = styled.img`
  display: flex;
  max-height: 100%;
  max-width: 72px;
  border-radius: 8px;
`;

export const FileName = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${(props) => props.theme.blackText};
  padding: 0 8px;
  height: 20px;
  background: ${(props) => props.theme.secondaryColorA1};
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadow1};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
