/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 21:36:19
 * @modify date 2021-03-10 21:36:19
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NewComment from './NewComment';

import Loading from 'components/common/loading/Loading';

import { uploadFiles } from './NewComment.service';

export default function NewCommentContainer({ onSubmit }) {
  const [commentText, setCommentText] = useState({});
  const [editorReady, setEditorReady] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileUplaod = (event) => {
    let filesObj = event.target.files;
    let newFiles = [];

    for (let file in filesObj) {
      if (typeof filesObj[file] === 'object') {
        newFiles.push(filesObj[file]);
      }
    }

    setFileUploadLoading(true);
    let newFilesData = [];
    uploadFiles(newFiles).then((responses) => {
      for (let response of responses) {
        newFilesData.push(response?.data?.file);
      }
      setFiles((old) => [...old, ...newFilesData]);
      setFileUploadLoading(false);
    });
  };

  const handleFileRemove = (file) => {
    setFiles((old) => {
      return old.filter((item) => item.id !== file.id);
    });
  };

  const handleSubmit = () => {
    setFiles([]);
    setCommentText({});

    setLoading(true);
    setEditorReady(false);
    onSubmit(commentText, files);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  if (loading) {
    return <Loading gap={0} />;
  }

  return (
    <NewComment
      files={files}
      fileUploadLoading={fileUploadLoading}
      onFileRemove={handleFileRemove}
      commentText={commentText}
      onCommentTextChange={(_, newData) => setCommentText(newData)}
      editorReady={editorReady}
      onEditorReady={() => setEditorReady(true)}
      onFileUpload={handleFileUplaod}
      onSubmit={handleSubmit}
    />
  );
}

NewCommentContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
