/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-31 16:38:08
 * @modify date 2021-03-31 16:38:08
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  SearchEntityImageSC,
  EntityImage,
  EntityLetterImage,
} from './SearchEntityImage.style';

export default function SearchEntityImage({ src, alt, title }) {
  return (
    <SearchEntityImageSC>
      {src ? (
        <EntityImage src={src} alt={alt} title={title} />
      ) : (
        <EntityLetterImage>{title[0]}</EntityLetterImage>
      )}
    </SearchEntityImageSC>
  );
}

SearchEntityImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};
