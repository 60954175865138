/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-02 23:01:17
 * @modify date 2021-03-02 23:01:17
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  AttachmentBlockSC,
  FilePreview,
  ImagePreview,
  FileInfo,
  FileName,
  FileSize,
  DownloadLink,
} from './AttachmentBlock.style';

import Icon from 'components/common/icon/Icon';
import CustomIcon from 'components/common/custom-icon/CustomIcon';

import { isImageType, getFileSizeFromBytes } from 'utils/file-util';

export default function AttachmentBlock({
  fileName,
  fileType,
  fileSize,
  fileUrl,
}) {
  const { t } = useTranslation();

  return (
    <AttachmentBlockSC>
      <FilePreview>
        {isImageType(fileType) ? (
          <ImagePreview src={fileUrl} alt={fileName} />
        ) : (
          <CustomIcon name="File" size={80} />
        )}
      </FilePreview>
      <FileInfo>
        <FileName>{fileName + '.' + fileType ?? 'File'}</FileName>
        {fileSize ? (
          <FileSize>{getFileSizeFromBytes(fileSize)}</FileSize>
        ) : null}
        <DownloadLink href={fileUrl} rel="noreferrer">
          <Icon name="file_download" iconTheme="outlined" isDark />
          {t('download')}
        </DownloadLink>
      </FileInfo>
    </AttachmentBlockSC>
  );
}

AttachmentBlock.propTypes = {
  /** file name which will be used to show the attachment */
  fileName: PropTypes.string.isRequired,
  /** file type */
  fileType: PropTypes.string,
  /** file size in bytes */
  fileSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** file url which can we used to download the file */
  fileUrl: PropTypes.string.isRequired,
};

AttachmentBlock.defaultProps = {
  fileType: '',
  fileSize: null,
};
