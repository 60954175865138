/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:13:57
 * @modify date 2020-12-31 00:13:57
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  SwimlaneTicketCardSC,
  EntityNameContainer,
  TimeRangeContainer,
  AvatarContainer,
  DragHandle,
} from './SwimlaneTicketCard.style';

import Avatar from 'components/common/avatar/Avatar';
import EntityName from 'components/common/entity-name/EntityName';
import TimeRange from 'components/common/time-range/TimeRange';
import Icon from 'components/common/icon/Icon';
import ImageIcon from 'components/common/image-icon/ImageIcon';

import { getTimeRange, getExtremeRelativeTime } from 'utils/date-formats-util';

export default function SwimlaneTicketCard({ ticket }) {
  const { t } = useTranslation();

  return (
    <SwimlaneTicketCardSC id={ticket.id} data-testid="swimlane-ticket-card">
      <EntityNameContainer>
        <EntityName
          icon={<ImageIcon src={ticket.img} alt={ticket.title} size="small" />}
          entityName={t('task')}
          link={`/ticket-${ticket.key}`}
          title={ticket.title}
          size="small"
        />
        <DragHandle className="handle">
          <Icon name="drag_indicator" isDark size={17} />
        </DragHandle>
      </EntityNameContainer>
      <TimeRangeContainer>
        <TimeRange
          isPriority={ticket.isPriority}
          range={getTimeRange(ticket.createdAt, ticket.endDate ?? new Date())}
          duration={getExtremeRelativeTime(
            ticket.createdAt,
            ticket.endDate ?? new Date(),
          )}
        />
      </TimeRangeContainer>
      <AvatarContainer>
        <Avatar
          username={ticket.assignee.name}
          img={ticket.assignee.img}
          link={`/user/${ticket.assignee.id}`}
        />
      </AvatarContainer>
    </SwimlaneTicketCardSC>
  );
}

SwimlaneTicketCard.propTypes = {
  ticket: PropTypes.object.isRequired,
};
