/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 14:24:15
 * @modify date 2021-04-19 18:59:38
 */

import {
  FEATCH_USER_DATA,
  FEATCH_USER_DATA_LOADING,
  FEATCH_USER_DATA_ERROR,
  FEATCH_USER_WORKSPACES,
  UPDATE_SELECTED_WORKSPACE,
} from 'types';

import { axiosInstance } from 'utils/api-util';
import { getCurrentWorkspace } from 'utils/workspace-util';

export const getUserData = () => (dispatch) => {
  // Get Workspaces
  return axiosInstance({
    url: 'company',
    skipWorkspaceId: true,
  }).then(async (workspacesResponse) => {
    await dispatch({
      type: FEATCH_USER_WORKSPACES,
      payload: workspacesResponse.data,
    });

    if (!getCurrentWorkspace()) {
      await dispatch({
        type: UPDATE_SELECTED_WORKSPACE,
        payload: workspacesResponse.data?.[0],
      });
    }

    return workspacesResponse.data;
  });
};

export const getProfileData = (skipWorkspaceId) => (dispatch) => {
  dispatch({ type: FEATCH_USER_DATA_LOADING, payload: true });
  return axiosInstance({
    url: 'profile/',
    skipWorkspaceId,
  })
    .then((userResponse) => {
      return dispatch({
        type: FEATCH_USER_DATA,
        payload: userResponse?.data?.contributor ?? userResponse?.data,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: FEATCH_USER_DATA_ERROR, payload: true });
    })
    .finally(() => {
      dispatch({ type: FEATCH_USER_DATA_LOADING, payload: false });
    });
};

export const getWorkspaces = () => (dispatch) => {
  return axiosInstance({
    url: 'company',
    skipWorkspaceId: true,
  }).then((response) => {
    dispatch({
      type: FEATCH_USER_WORKSPACES,
      payload: response.data,
    });
  });
};

export const updateSelectedWorkspace = (newWorkspace) => async (dispatch) => {
  console.log('UPDATE_SELECTED_WORKSPACE');
  return await dispatch({
    type: UPDATE_SELECTED_WORKSPACE,
    payload: newWorkspace,
  });
};
