/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  AppDrawerSC,
  AppDrawerContainer,
  CreateButtonContainer,
  MenuContainer,
  MenuHeader,
  MenuLinksContainer,
} from './AppDrawer.style';

import AppDrawerHeader from 'components/layout/app-drawer/app-drawer-header/AppDrawerHeader';
import MenuLinks from 'components/layout/app-drawer/menu-links/MenuLinks';
import Icon from 'components/common/icon/Icon';
import InversePrimaryButton from 'components/common/button/inverse-primary-button/InversePrimaryButton';

export default function AppDrawer({
  isAppDrawerOpen,
  selectedWorkspace,
  onToggleAppDrawer,
  onCreateModalClick,
}) {
  const { t } = useTranslation();

  return (
    <AppDrawerSC
      data-testid="app-drawer"
      isAppDrawerOpen={isAppDrawerOpen}
      className="animate__animated animate__fadeIn"
    >
      <AppDrawerContainer data-testid="app-drawer-container">
        <AppDrawerHeader
          selectedWorkspace={selectedWorkspace}
          isAppDrawerOpen={isAppDrawerOpen}
          onToggleAppDrawer={onToggleAppDrawer}
        />

        <CreateButtonContainer>
          <InversePrimaryButton
            rounded
            leftIcon={<Icon name="add" size={20} />}
            text={t('create_new')}
            size="large"
            onClick={onCreateModalClick}
          />
        </CreateButtonContainer>

        <MenuContainer>
          <MenuHeader>{t('menu')}</MenuHeader>
          <MenuLinksContainer>
            <MenuLinks />
          </MenuLinksContainer>
        </MenuContainer>
      </AppDrawerContainer>
    </AppDrawerSC>
  );
}

AppDrawer.propTypes = {
  isAppDrawerOpen: PropTypes.bool.isRequired,
  selectedWorkspace: PropTypes.object.isRequired,
  onToggleAppDrawer: PropTypes.func.isRequired,
  onCreateModalClick: PropTypes.func.isRequired,
};
