/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-12 20:38:59
 * @modify date 2021-01-12 20:38:59
 */

import React, { useState } from 'react';

import Dora from './Dora';

export default function DoraContainer() {
  const [showChat, setShowChat] = useState(false);

  const handleToggleChatVisibility = (newState = null) => {
    if (newState) {
      setShowChat(newState);
      return;
    }
    setShowChat((old) => !old);
  };

  return (
    <Dora
      showChat={showChat}
      onToggleChatVisibility={handleToggleChatVisibility}
    />
  );
}
