/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:33:07
 */

import styled from 'styled-components';

export const CardSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.card.backgroundColor};
  border-radius: 8px;
  flex: 1;
  box-shadow: ${(props) => props.theme.boxShadow1};
`;
