/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-04-01 14:27:52
 */

import styled, { css } from 'styled-components';

import { variables } from 'variables';

export const ImageIconSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) =>
    props.rounded ? 500 : variables.imageIconSize[props.size].borderRadius}px;
  background: ${(props) => props.theme.white};
  height: ${(props) => variables.imageIconSize[props.size].height}px;
  width: ${(props) => variables.imageIconSize[props.size].height}px;
  box-shadow: ${(props) => props.theme.boxShadow2};
  overflow: hidden;
  transition: all 250ms ease;

  ${(props) =>
    props.variant === 'colored' &&
    css`
      background: ${(props) => props.theme.primaryColorA1};
      box-shadow: none;
    `}
`;

export const ImageSC = styled.img`
  height: ${(props) =>
    props.padded
      ? variables.imageIconSize[props.size].imgHeight + 'px'
      : '100%'};
`;

export const DefaultLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.primaryColor};
  font-size: ${(props) => variables.imageIconSize[props.size].fontSize}px;
  text-transform: uppercase;
`;
