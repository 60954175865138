/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:27
 * @modify date 2020-12-31 23:38:47
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const DashboardSC = styled.div`
  flex: 1;
  display: grid;
  gap: 16px;

  .k-page-header {
    .k-project-icon-container {
      background: ${(props) => props.theme.lightBackgroundColor2};
      box-shadow: none;
    }
  }
`;

export const KanbanContainer = styled.div`
  height: calc(
    100vh - ${variables.headerHeight + variables.pageContentPadding + 12}px
  );
`;

export const SwimlaneHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const HeaderLabel = styled.div`
  display: flex;
  color: ${(props) => props.theme.secondaryTextColor};
  margin-left: 16px;
  font-size: 17px;
`;
