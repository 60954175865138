/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 14:40:42
 * @modify date 2021-01-06 23:45:02
 */

import styled from 'styled-components';

export const FormErrorSC = styled.div`
  display: flex;
  font-size: 12px;
  background: ${(props) => props.theme.errorBackgroundColor};
  color: ${(props) => props.theme.black};
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.errorBorderColor};
`;

export const ListContainer = styled.ul`
  margin: 0;
  padding-inline-start: 24px;
`;

export const ListItem = styled.li``;
