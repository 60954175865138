/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-02-16 22:13:32
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { variables, zIndex } from 'variables';

export const GanttChartSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  min-height: 200px;
  max-height: 400px;
  box-shadow: ${(props) => props.theme.boxShadow1};
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
`;

export const TimeLabelContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  height: 36px;
  margin-bottom: 12px;
  z-index: ${zIndex.ganttChartTimeLabel};
`;

export const TimeLabel = styled.div`
  display: flex;
  flex: 1;
  min-width: ${variables.ganttChart.dayWidth * 7}px;
  align-items: center;
  background: ${(props) => props.theme.white};
  font-size: 12px;
  border-bottom: 8px solid
    ${(props) => props.theme.ganttChart.timePeriodIndicatorOddColor};

  &:nth-child(2n) {
    border-color: ${(props) =>
      props.theme.ganttChart.timePeriodIndicatorEvenColor};
  }
`;

export const Content = styled.div`
  background: ${(props) => props.theme.white};
  position: relative;
  display: grid;
  flex: 1;
`;

export const DaysBackground = styled.div`
  position: absolute;
  flex: 1;
  display: grid;
  grid-auto-flow: column;
  height: 100%;
`;

export const DayBar = styled.div`
  display: flex;
  width: ${variables.ganttChart.dayWidth}px;
  background: transparent;

  &:nth-child(2n) {
    background: ${(props) => props.theme.ganttChart.dayEvenColor};
  }
`;

export const EntityArea = styled.div``;

export const EntityList = styled.div`
  position: relative;
  display: grid;
  gap: 5px;
  padding: 5px 0;
`;

export const EntityBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 10px;
  background: ${(props) => props.color};
  border-radius: 4px;
  left: ${(props) => props.startDay * variables.ganttChart.dayWidth}px;
  width: ${(props) => props.length * variables.ganttChart.dayWidth - 20}px;
`;

export const EntityName = styled(Link)`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.white};
  text-decoration: none;
  transition: all 250ms;

  &:hover {
    margin-left: 2px;
    font-size: 13.2px;
    color: ${(props) => props.theme.grayE};
  }
`;

export const AvatarContainer = styled.div`
  position: sticky;
`;
