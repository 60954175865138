/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-03-03 19:51:04
 */

import styled from 'styled-components';

export const SummarySC = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.lightBackgroundColor3};
  padding: ${(props) => props.padding}px;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
  font-size: 14.4px;
  line-height: 19.2px;
  display: grid;
  gap: 10px;
  grid: 20px;
`;

export const BlocksContainer = styled.div`
  margin-bottom: 10px;
`;

export const BlockContainer = styled.div``;

export const Attachments = styled.div`
  display: grid;
  gap: 10px;
`;

export const AttachmentHeading = styled.div`
  font-size: 20px;
`;

export const AttachmentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  gap: 10px;
`;
