/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-13 17:54:10
 * @modify date 2021-04-23 17:51:16
 */

import styled from 'styled-components';

export const EditProfileSC = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  min-width: 750px;
  width: 750px;
  padding: 20px;
`;

export const UpdatePhotoContainer = styled.div`
  display: grid;
  gap: 16px;
  align-content: start;
  justify-content: center;
  justify-items: center;

  .k-avatar {
    justify-content: center;
  }
`;

export const RemoveLink = styled.span`
  display: flex;
  color: ${(props) => props.theme.dangerColor};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.dangerHoverColor};
  }
`;

export const LinkSC = styled.div`
  display: flex;
  cursor: pointer;
  color: ${(props) => props.theme.actionColor};

  &:hover {
    color: ${(props) => props.theme.actionHoverColor};
  }
`;

export const ActionButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: start;
  margin-top: 16px;
  padding: 8px 0;
`;
