/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 13:49:22
 * @modify date 2021-02-17 13:49:22
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  MembersAccordionSC,
  AccordionHeaderContainer,
  AccordionBodyContainer,
  TimeContainer,
} from './MembersAccordion.style';

import EntityOverviewAccordion from 'components/common/entity-overview-accordion/EntityOverviewAccordion';
import EntityName from 'components/common/entity-name/EntityName';
import OpenTickets from 'components/common/open-tickets/OpenTickets';
import ImageIcon from 'components/common/image-icon/ImageIcon';
import TimeRange from 'components/common/time-range/TimeRange';
import Loading from 'components/common/loading/Loading';

import TasksContainer from 'components/layout/tasks/Tasks.container';

import { getTimeRange, getExtremeRelativeTime } from 'utils/date-formats-util';

export default function MembersAccordion({
  members,
  activeAccordion,
  loading,
  onAccordionToggle,
}) {
  const { t } = useTranslation();

  return (
    <MembersAccordionSC>
      {members.map((member, index) => (
        <EntityOverviewAccordion
          key={member.id}
          header={
            <AccordionHeaderContainer
              className="animate__animated animate__fadeIn"
              index={index}
            >
              <EntityName
                icon={<ImageIcon src={member.img} alt={member.name} rounded />}
                entityName={t('contributor')}
                title={member.name}
                link={`/user/${member.id}`}
              />

              <OpenTickets
                openTicketsCount={member.totalTasks - member.completedTasks}
              />
              <TimeContainer>
                <TimeRange
                  range={getTimeRange(
                    member.createdAt,
                    member.endDate ?? new Date(),
                  )}
                  duration={getExtremeRelativeTime(
                    member.createdAt,
                    member.endDate ?? new Date(),
                  )}
                />
              </TimeContainer>
            </AccordionHeaderContainer>
          }
          isOpen={activeAccordion === member.id}
          onToggle={() => onAccordionToggle(member.id)}
        >
          <AccordionBodyContainer>
            <TasksContainer entity1="user" id1={member.id} />
          </AccordionBodyContainer>
        </EntityOverviewAccordion>
      ))}
      {loading ? <Loading gap={100} /> : null}
    </MembersAccordionSC>
  );
}
MembersAccordion.propTypes = {
  members: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  activeAccordion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onAccordionToggle: PropTypes.func.isRequired,
};

MembersAccordion.defaultProps = {
  activeAccordion: null,
};
