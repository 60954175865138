/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:36:03
 */

import styled from 'styled-components';

export const NumberBubbleSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 100px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow2};
`;
