/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-09 14:28:50
 * @modify date 2021-01-09 14:28:50
 */

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { OverlaySC } from './Overlay.style';

export default function Overlay({ transparent, onClick }) {
  const [overlayRoot, setOverlayRoot] = useState(null);

  useEffect(() => {
    setOverlayRoot(document.getElementById('overlay-root'));
  }, []);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  if (!overlayRoot) return null;

  return ReactDOM.createPortal(
    <OverlaySC
      data-testid="overlay"
      transparent={transparent}
      onClick={handleOverlayClick}
    />,
    overlayRoot,
  );
}

Overlay.propTypes = {
  transparent: PropTypes.bool,
  onClick: PropTypes.func,
};

Overlay.defaultProps = {
  transparent: false,
  onClick: () => {},
};
