/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 16:48:40
 * @modify date 2021-03-17 16:00:05
 */

import styled from 'styled-components';

export const MenuButtonSC = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 100px;
  cursor: pointer;
  transition: all 250ms;

  .k-icon {
    color: ${(props) => props.secondaryTextColor};
  }

  &:hover {
    background: ${(props) => props.theme.blackA1};
  }
`;
