/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 20:14:54
 * @modify date 2021-01-12 21:12:42
 */

import styled from 'styled-components';

export const CarouselSC = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;

export const CardsContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow-x: hidden;
`;

export const CardsInner = styled.div`
  min-width: calc(100% * ${(props) => props.count});
  width: calc(100% * ${(props) => props.count});
  display: flex;
  transition: all 750ms ease;
  transform: translateX(
    -${(props) => (100 * props.activeCardIndex) / props.count}%
  );
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
`;

export const DotsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 6.4px;
`;

export const Dot = styled.div`
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: ${(props) =>
    props.active
      ? props.theme.carousel.dots.activeBackgroundColor
      : props.theme.carousel.dots.backgroundColor};
  transition: all 250ms ease;
`;
