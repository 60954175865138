/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 11:20:45
 * @modify date 2021-01-06 11:48:50
 */

import styled from 'styled-components';

export const DividerSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) =>
    props.orientation === 'horizontal' ? '100%' : props.thickness + 'px'};
  height: ${(props) =>
    props.orientation === 'horizontal' ? props.thickness + 'px' : '100%'};
  background: ${(props) => props.theme.dividerColor};
`;
