/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { TabsSC } from './Tabs.style';

export default function Tabs({ tabs, isLink, activeTabId, onTabChange }) {
  const { t } = useTranslation();

  return (
    <TabsSC data-testid="tabs" className="tabs">
      {isLink
        ? tabs.map((tab) => (
            <NavLink key={tab.id} to={tab.link} className="tab">
              {t(tab.name)}
            </NavLink>
          ))
        : tabs.map((tab) => (
            <div
              key={tab.id}
              className={'tab' + (activeTabId === tab.id ? ' active' : '')}
              onClick={() => onTabChange(tab)}
            >
              {t(tab.name)}
            </div>
          ))}
    </TabsSC>
  );
}

Tabs.propTypes = {
  /** list of tabs */
  tabs: PropTypes.array.isRequired,
  /** if true, then tabs will not work as navigation points, instead they will be simple divs with onClick event */
  isLink: PropTypes.bool,
  /** This is used to identify which tab is active, only required if the tabs are not a link (isLink is false) */
  activeTabId: PropTypes.string,
  /** This triggers when a tab is clicked, only required if the tabs are not a link (isLink is false) */
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  isLink: true,
  activeTabId: '',
  onTabChange: () => {},
};
