/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-02-27 16:16:12
 */

/* eslint-disable no-undef */
let ENV = process.env.REACT_APP_ENV ?? '';
let BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
let HEROKU_BASE_URL = process.env.REACT_APP_HEROKU_BASE_URL ?? '';
let SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY ?? '';
let SENTRY_ID = process.env.REACT_APP_SENTRY_ID ?? '';

const Config = {
  appName: 'Plateau',
  appTitle: 'Plateau intelligent project assistant',
  apiTimeout: 30000,
  baseUrls: BASE_URL,
  env: ENV,
  searchDelay: 350, // in ms
  sentry: {
    key: SENTRY_KEY,
    id: SENTRY_ID,
  },
  herokuBaseUrl: HEROKU_BASE_URL + 'api/',
  accessTokenLocalStoreKey: 'access_token',
};

export default Config;
