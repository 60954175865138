/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:13:49
 * @modify date 2020-12-31 23:59:49
 */

import styled from 'styled-components';

export const SwimlaneSC = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  align-content: start;
  background: ${(props) => props.theme.kanbanBoard.swimlane.backgroundColor};
  border-radius: 8px;
  min-width: 320px;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  padding: 0 16px;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const CardList = styled.div`
  display: grid;
  padding: 0 8px;
  max-height: 100%;
  overflow-y: ${(props) => (props.showScroll ? 'auto' : 'hidden')};

  .sortable-ghost {
    opacity: 0;
  }
  .sortable-chosen {
    opacity: 0;
  }
  .sortable-fallback {
    opacity: 1 !important;
    border: none;
    transform: rotate(5deg);
    box-shadow: ${(props) => props.theme.kanbanBoard.card.dragShadow};
  }

  &:after {
    content: '';
    height: 8px;
  }
`;
