/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 22:49:19
 * @modify date 2021-02-17 22:49:19
 */

import React from 'react';

import {
  TermsOfServiceSC,
  Heading1,
  Heading2,
  Para,
  Link,
  List,
  ListItem,
} from './TermsOfService.style';

export default function TermsOfService() {
  return (
    <TermsOfServiceSC>
      <Heading1>Website Terms and Conditions of Use</Heading1>

      <Heading2>1. Terms</Heading2>

      <Para>
        By accessing this Website, accessible from Plateau.ai, you are agreeing
        to be bound by these Website Terms and Conditions of Use and agree that
        you are responsible for the agreement with any applicable local laws. If
        you disagree with any of these terms, you are prohibited from accessing
        this site. The materials contained in this Website are protected by
        copyright and trade mark law.
      </Para>

      <Heading2>2. Use License</Heading2>

      <Para>
        Permission is granted to temporarily download one copy of the materials
        on Plateau Inc&apos;s Website for personal, non-commercial transitory
        viewing only. This is the grant of a license, not a transfer of title,
        and under this license you may not:
      </Para>

      <List>
        <ListItem>modify or copy the materials;</ListItem>
        <ListItem>
          use the materials for any commercial purpose or for any public
          display;
        </ListItem>
        <ListItem>
          attempt to reverse engineer any software contained on Plateau
          Inc&apos;s Website;
        </ListItem>
        <ListItem>
          remove any copyright or other proprietary notations from the
          materials; or
        </ListItem>
        <ListItem>
          transferring the materials to another person or &quot;mirror&quot; the
          materials on any other server.
        </ListItem>
      </List>

      <Para>
        This will let Plateau Inc to terminate upon violations of any of these
        restrictions. Upon termination, your viewing right will also be
        terminated and you should destroy any downloaded materials in your
        possession whether it is printed or electronic format. These Terms of
        Service has been created with the help of the{' '}
        <Link
          href="https://www.termsofservicegenerator.net"
          target="_blank"
          rel="noreferrer"
        >
          Terms Of Service Generator
        </Link>{' '}
        and the{' '}
        <Link
          href="https://www.generateprivacypolicy.com"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy Generator
        </Link>
        .
      </Para>

      <h2>3. Disclaimer</h2>

      <Para>
        All the materials on Plateau Inc’s Website are provided &quot;as
        is&quot;. Plateau Inc makes no warranties, may it be expressed or
        implied, therefore negates all other warranties. Furthermore, Plateau
        Inc does not make any representations concerning the accuracy or
        reliability of the use of the materials on its Website or otherwise
        relating to such materials or any sites linked to this Website.
      </Para>

      <Heading2>4. Limitations</Heading2>

      <Para>
        Plateau Inc or its suppliers will not be hold accountable for any
        damages that will arise with the use or inability to use the materials
        on Plateau Inc’s Website, even if Plateau Inc or an authorize
        representative of this Website has been notified, orally or written, of
        the possibility of such damage. Some jurisdiction does not allow
        limitations on implied warranties or limitations of liability for
        incidental damages, these limitations may not apply to you.
      </Para>

      <Heading2>5. Revisions and Errata</Heading2>

      <Para>
        The materials appearing on Plateau Inc’s Website may include technical,
        typographical, or photographic errors. Plateau Inc will not promise that
        any of the materials in this Website are accurate, complete, or current.
        Plateau Inc may change the materials contained on its Website at any
        time without notice. Plateau Inc does not make any commitment to update
        the materials.
      </Para>

      <Heading2>6. Links</Heading2>

      <Para>
        Plateau Inc has not reviewed all of the sites linked to its Website and
        is not responsible for the contents of any such linked site. The
        presence of any link does not imply endorsement by Plateau Inc of the
        site. The use of any linked website is at the user’s own risk.
      </Para>

      <Heading2>7. Site Terms of Use Modifications</Heading2>

      <Para>
        Plateau Inc may revise these Terms of Use for its Website at any time
        without prior notice. By using this Website, you are agreeing to be
        bound by the current version of these Terms and Conditions of Use.
      </Para>

      <Heading2>8. Your Privacy</Heading2>

      <Para>Please read our Privacy Policy.</Para>

      <Heading2>9. Governing Law</Heading2>

      <Para>
        Any claim related to Plateau Inc&apos;s Website shall be governed by the
        laws of us without regards to its conflict of law provisions.
      </Para>
    </TermsOfServiceSC>
  );
}
