/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-04 01:01:44
 * @modify date 2021-02-04 01:01:44
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Highlights from './Highlights';

import Loading from 'components/common/loading/Loading';

import { getHighlightsService } from './Highlights.service';

export default function HighlightsContainer({ data, entity, id, restProps }) {
  const [highlights, setHighlights] = useState(data ?? null);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    if (!data || !data.length) {
      getHighlightsService(entity, id).then((response) => {
        setHighlights(response?.data?.data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(getData, []);

  if (loading) {
    return <Loading gap={90} />;
  }

  return <Highlights highlights={highlights} {...restProps} />;
}

HighlightsContainer.propTypes = {
  data: PropTypes.array,
  entity: PropTypes.string,
  id: PropTypes.string,
  restProps: PropTypes.object,
};
