/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-03-01 14:38:15
 */

import { axiosInstance } from 'utils/api-util';
import { getDiff } from 'utils/date-formats-util';

export function getGanttInfoService(entity, id) {
  return axiosInstance({
    url: 'entity/gantt',
    params: {
      entity,
      id,
    },
  }).then((response) => extractInformation(response?.data?.data ?? []));
}

/**
 * generate data to calculate data for gantt chart
 * Complexity: O(2n)
 * @param {object} data ticket data from the API
 */
function extractInformation(data) {
  // If there is no data return empty data set.
  if (!data.length) {
    return { totalWeeks: 0, data: [] };
  }

  const defaultDueDate = new Date().getTime() + 1209600000; // Id due date is not present use two weeks from present time;

  // First we need to calculate the refrences for starting and ending dates.
  let earliestStartingDate = data[0].createdAt;
  let lastDueDate = data[0]?.dueDate ?? defaultDueDate;

  for (let item of data) {
    // If current item's starting date is less then earliestStaringDate (in hours) it is new earliestStartingDate
    if (getDiff(item.createdAt, earliestStartingDate, 'hour') < 0) {
      earliestStartingDate = item.createdAt;
    }
    // If dueDate of the current item is more then the lastDueDate (in hours) it is new lastDueDate
    if (
      getDiff(
        item?.dueDate ?? item?.endDate ?? defaultDueDate,
        lastDueDate,
        'hour',
      ) > 0
    ) {
      lastDueDate = item?.dueDate ?? item?.endDate ?? defaultDueDate;
    }
  }

  // Now we need to calculate the starting and ending days with refrence to earliest starting date.
  for (let item of data) {
    item.startDay = getDiff(item.createdAt, earliestStartingDate, 'day');
    item.projectLengthInDays = getDiff(
      item?.dueDate ?? item?.endDate ?? defaultDueDate,
      item.createdAt,
      'day',
    );
    item.color = '#' + (item.status?.color ?? '2176ff');
  }

  return {
    totalWeeks: getDiff(lastDueDate, earliestStartingDate, 'week') + 1,
    data,
  };
}
