/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 01:52:04
 * @modify date 2020-12-24 01:52:04
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  MyTicketsSC,
  PageHeaderContainer,
  HighlightCardContainer,
} from './MyTickets.style';

import PageHeader from 'components/common/page-header/PageHeader';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';

import Highlights from 'components/layout/highlights/Highlights.container';
import GanttChartContainer from 'components/layout/gantt-chart/GanttChart.container';
import UpdatesContainer from 'components/layout/updates/Updates.container';
import ProjectsContainer from 'components/layout/projects/Projects.container';
import TabRouter from 'components/layout/tab-router/TabRouter';

export default function MyTickets({ userData, tabs }) {
  const { t } = useTranslation();

  return (
    <MyTicketsSC data-testid="user-page">
      <PageHeaderContainer className="animate__animated animate__fadeIn">
        <PageHeader
          entityName={t('contributor')}
          icon={userData.img}
          heading={userData.name}
          iconVariant="circle"
          footer={
            <Breadcrumbs
              links={[
                { label: 'Data Science Team' },
                { label: userData.designation },
              ]}
            />
          }
        />
      </PageHeaderContainer>

      <HighlightCardContainer className="animate__animated animate__fadeIn">
        <Highlights
          entity={userData.entity}
          id={userData.id}
          restProps={{ isAnimated: true }}
        />
      </HighlightCardContainer>

      <TabRouter
        tabs={tabs}
        renders={[
          <ProjectsContainer
            key={1}
            user={userData}
            entity="user"
            id={userData.id}
          />,
          <>
            <GanttChartContainer entity={userData.entity} id={userData.id} />
            <UpdatesContainer entity={userData.entity} id={userData.id} />
          </>,
        ]}
      />
    </MyTicketsSC>
  );
}

MyTickets.propTypes = {
  /** userData object */
  userData: PropTypes.object,
  /** list of tabs */
  tabs: PropTypes.array.isRequired,
};
