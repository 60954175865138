/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  ItemCarouselSC,
  CarouselContent,
  CarouselCotentInner,
} from './ItemCarousel.style';

import ArrowButton from 'components/common/arrow-button/ArrowButton';

import { variables } from 'variables';

export default function ItemCarousel({ cards }) {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const itemCarouselRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', setNewWidth);
    return () => {
      window.removeEventListener('resize', setNewWidth);
    };
  }, []);

  const handleLeftArrowClick = () => {
    setActiveCardIndex((old) => old - 1);
  };

  const handleRightArrowClick = () => {
    setActiveCardIndex((old) => old + 1);
  };

  const setNewWidth = () => {
    setContainerWidth(itemCarouselRef.current.clientWidth);
  };

  useLayoutEffect(setNewWidth, [itemCarouselRef]);

  const calculateCaruselWidth = () => {
    // calculating the content width as per current UI state.
    const currentContentWidth =
      containerWidth -
      (variables.carousel.gap + variables.carousel.buttonsWidth) * 2;

    if (
      contentWidth < 1 ||
      currentContentWidth % variables.highlightCardWidth
    ) {
      // If true, that means contentWidth haven't been setted or it is not perfect for number of cards
      setContentWidth(
        Math.floor(currentContentWidth / variables.highlightCardWidth) *
          variables.highlightCardWidth,
      );
    }
  };

  useEffect(calculateCaruselWidth, [containerWidth]);

  const checkActiveIndex = () => {
    if (
      activeCardIndex - 1 >=
        cards.length - containerWidth / variables.highlightCardWidth &&
      activeCardIndex !== 0
    ) {
      setActiveCardIndex((old) => old - 1);
    }
  };

  useEffect(checkActiveIndex, [contentWidth]);

  const isPrevDisabled = () => {
    return activeCardIndex === 0;
  };

  const isNextDisabled = () => {
    const currentContentWidth =
      containerWidth -
      (variables.carousel.gap + variables.carousel.buttonsWidth) * 2;

    return (
      activeCardIndex >=
      cards.length -
        Math.floor(currentContentWidth / variables.highlightCardWidth)
    );
  };

  return (
    <ItemCarouselSC data-testid="item-carousel" ref={itemCarouselRef}>
      <ArrowButton
        direction="left"
        disabled={isPrevDisabled()}
        onClick={handleLeftArrowClick}
      />
      <CarouselContent
        data-testid="carousel-content"
        style={{
          maxWidth: contentWidth + 'px',
        }}
      >
        <CarouselCotentInner
          data-testid="carousel-content-inner"
          activeCardIndex={activeCardIndex}
        >
          {cards}
        </CarouselCotentInner>
      </CarouselContent>
      <ArrowButton
        direction="right"
        disabled={isNextDisabled()}
        onClick={handleRightArrowClick}
      />
    </ItemCarouselSC>
  );
}

ItemCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.element).isRequired,
};
