/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 17:20:11
 * @modify date 2021-03-10 17:20:11
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  NewCommentSC,
  InputContainer,
  AttachmentButton,
  AttachmentInput,
} from './NewComment.style';

import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';
import EditorContainer from 'components/common/editor-container/EditorContainer';
import AttachmentGrid from 'components/common/attachment-grid/AttachmentGrid';

export default function NewComment({
  files,
  commentText,
  onCommentTextChange,
  editorReady,
  onEditorReady,
  onFileUpload,
  fileUploadLoading,
  onFileRemove,
  onSubmit,
}) {
  const { t } = useTranslation();
  return (
    <NewCommentSC data-testid="new-comment">
      <InputContainer cut={editorReady ? 0 : 1}>
        <EditorContainer
          editorProps={{
            placeholder: t('type_your_comment_here'),
            data: commentText,
            onChange: onCommentTextChange,
            onReady: onEditorReady,
          }}
        />
        {files.length ? (
          <AttachmentGrid
            files={files}
            loading={fileUploadLoading}
            onRemove={onFileRemove}
          />
        ) : null}
      </InputContainer>

      <AttachmentButton title={t('add_attachments')}>
        <Icon name="attach_file" />
        <AttachmentInput type="file" multiple onChange={onFileUpload} />
      </AttachmentButton>

      <Button text={t('post')} centered onClick={onSubmit} />
    </NewCommentSC>
  );
}

NewComment.propTypes = {
  files: PropTypes.array.isRequired,
  fileUploadLoading: PropTypes.bool.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  commentText: PropTypes.object.isRequired,
  onCommentTextChange: PropTypes.func.isRequired,
  editorReady: PropTypes.bool.isRequired,
  onEditorReady: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
