/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 16:40:41
 * @modify date 2021-01-19 15:14:31
 */

import Config from 'app.config';

export const isLoggedIn = () => {
  const accessToken = localStorage.getItem(Config.accessTokenLocalStoreKey);
  if (accessToken) {
    return true;
  }

  return false;
};

export const setToken = (token) => {
  localStorage.setItem(Config.accessTokenLocalStoreKey, token);
};

export const getToken = () => {
  return 'Token ' + localStorage.getItem(Config.accessTokenLocalStoreKey);
};

export const logout = () => {
  localStorage.clear();
  window.location = '/login';
};
