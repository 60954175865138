/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { MenuLinksSC, MenuLink, IconSC, TextSC } from './MenuLinks.style';

import Icon from 'components/common/icon/Icon';

import { appDrawerLinks } from 'utils/navigation-util';

export default function MenuLinks() {
  const { t } = useTranslation();

  return (
    <MenuLinksSC>
      {appDrawerLinks.map((link) => (
        <NavLink key={link.id} className="link" to={link.path}>
          <MenuLink>
            <IconSC>
              <Icon name={link.icon} />
            </IconSC>
            <TextSC>{t(link.label)}</TextSC>
          </MenuLink>
        </NavLink>
      ))}
    </MenuLinksSC>
  );
}
