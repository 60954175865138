/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 21:39:31
 * @modify date 2021-02-06 00:25:16
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const TasksSC = styled.div`
  flex: 1;
  display: grid;
`;

export const TaskListContainer = styled.div`
  display: grid;
  gap: 8px;
  max-height: 480px;
  overflow-y: auto;
  padding: ${variables.cardPadding}px;
  transition: all 250ms ease;
`;

export const TaskBar = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 180px 120px;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-radius: 8px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 52px;
    width: calc(100% - 52px);
    height: 1px;
    border-bottom: 1px solid ${(props) => props.theme.dividerColor};
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
