/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-10 22:08:18
 */

export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_ACTIVE_MODAL = 'UPDATE_ACTIVE_MODAL';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const UPDATE_PAGE_BOTTOM = 'UPDATE_PAGE_BOTTOM';

// Users
export const FEATCH_USER_DATA = 'FEATCH_USER_DATA';
export const FEATCH_USER_DATA_LOADING = 'FEATCH_USER_DATA_LOADING';
export const FEATCH_USER_DATA_ERROR = 'FEATCH_USER_DATA_ERROR';
export const FEATCH_USER_WORKSPACES = 'FEATCH_USER_WORKSPACES';
export const UPDATE_SELECTED_WORKSPACE = 'UPDATE_SELECTED_WORKSPACE';
