/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:38:17
 * @modify date 2020-12-21 21:38:17
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ArrowButtonSC } from './ArrowButton.style';

import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';

const titleMap = {
  left: 'prev',
  up: 'close',
  right: 'next',
  down: 'open',
};

export default function ArrowButton({ direction, disabled, onClick }) {
  const { t } = useTranslation();

  return (
    <ArrowButtonSC>
      <Button
        size="small"
        text=""
        rounded
        iconButton
        elevated
        disabled={disabled}
        leftIcon={<Icon size={21} name={'keyboard_arrow_' + direction} />}
        title={t(titleMap[direction])}
        onClick={onClick}
      />
    </ArrowButtonSC>
  );
}
ArrowButton.propTypes = {
  /** Direction of the arrow */
  direction: PropTypes.oneOf(['left', 'up', 'right', 'down']).isRequired,
  /** disabled button */
  disabled: PropTypes.bool,
  /** onClick event on the button */
  onClick: PropTypes.func,
};

ArrowButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};
