/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-02 18:46:33
 * @modify date 2021-04-02 18:46:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { AppLogoSC, Logo, AppName } from './AppLogo.style';

import LOGO_IMG from 'assets/images/logo-no-bg-blue.svg';

import Config from 'app.config';

export default function AppLogo({ isFixedAtBottom }) {
  return (
    <AppLogoSC data-testid="app-logo" fixedAtBottom={isFixedAtBottom}>
      <Logo src={LOGO_IMG} alt={Config.appName} />
      <AppName>{Config.appName}</AppName>
    </AppLogoSC>
  );
}

AppLogo.propTypes = {
  isFixedAtBottom: PropTypes.bool,
};

AppLogo.defaultProps = {
  isFixedAtBottom: false,
};
