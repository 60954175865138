/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CardBodySC } from './CardBody.style';

export default function CardBody({ children, padded }) {
  return (
    <CardBodySC data-testid="card-body" padded={padded}>
      {children}
    </CardBodySC>
  );
}

CardBody.propTypes = {
  /** children of the CardBody */
  children: PropTypes.element.isRequired,
  padded: PropTypes.bool,
};

CardBody.defaultProps = {
  padded: false,
};
