/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-02-04 18:45:19
 */

import styled from 'styled-components';

export const TicketBasicDataSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex: 1;
  padding-right: 36px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
