/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-13 15:17:49
 * @modify date 2021-01-13 15:17:49
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import Chat from './Chat';

import { getDefaultConversation, getReply } from './Chat.service';

import { updateActiveModal } from 'actions/common-actions';

import { modalList } from 'utils/modal-util';

export default function ChatContainer({ onClose }) {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userStates.userData);

  const [conversation, setConversation] = useState(getDefaultConversation());
  const [typedMessage, setTypedMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGetReply = (message) => {
    setLoading(true);
    getReply(message).then((reply) => {
      setConversation((old) => {
        const newMessage = {
          id: uuid(),
          sent: false,
          message: reply,
        };
        return [...old, newMessage];
      });

      if (message.toLowerCase().search('new ticket') !== -1) {
        dispatch(updateActiveModal(modalList.CREATE_TICKET_MODAL));
      }

      setLoading(false);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      onClose();
    }
    if (event.key === 'Enter' && typedMessage !== '') {
      event.preventDefault();
      setConversation((old) => {
        const newMessage = {
          id: uuid(),
          sent: true,
          message: typedMessage,
        };
        return [...old, newMessage];
      });
      handleGetReply(typedMessage);
      setTypedMessage('');
    }
  };

  const handleTypedMessageChange = (event) => {
    setTypedMessage(event.target.value);
  };

  return (
    <Chat
      loading={loading}
      conversation={conversation}
      typedMessage={typedMessage}
      onTypedMessageChange={handleTypedMessageChange}
      onTypedMessageKeyDown={handleKeyDown}
      user={userData}
      onClose={onClose}
    />
  );
}

ChatContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};
