/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-13 23:57:31
 */

import styled from 'styled-components';
import { variables, zIndex } from 'variables';

export const DoraSC = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: ${zIndex.dora};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ChatContainerBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  max-height: 600px;
  width: ${variables.dora.width}px;
  max-width: ${(props) => (props.showChat ? variables.dora.width : 48)}px;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.dora.boxShadow};
  border-radius: 16px;
  margin-bottom: 12px;
  z-index: ${zIndex.dora + 1};
  transition: all 500ms ease;

  max-height: ${(props) => (props.showChat ? '600px' : 0)};
`;

export const CollapsedButtonContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.white};
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  height: 48px;
  box-shadow: ${(props) =>
    props.showChat ? props.theme.dora.boxShadow : props.theme.boxShadow2};
  cursor: pointer;
  transition: all 500ms ease;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.showChat ? 40 : 48)}px;
  min-width: ${(props) => (props.showChat ? 40 : 48)}px;
  width: 0;
  height: 0;
  border-radius: 100px;
  margin-left: ${(props) => (props.showChat ? '4px' : 0)};
  background: ${(props) => props.theme.primaryColor};
  transform: ${(props) => (props.showChat ? 'rotate(180deg)' : 'none')};
  transition: all 500ms ease;
`;

export const ButtonText = styled.div`
  display: flex;
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  margin: 0 ${(props) => (props.showChat ? '27px' : '0')};
  font-size: 14px;
  width: 100%;
  max-width: ${(props) => (props.showChat ? '160px' : '0')};
  overflow: hidden;
  transition: all 500ms ease;
`;
