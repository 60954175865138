/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-02 18:46:24
 * @modify date 2021-04-02 19:01:56
 */

import styled, { css } from 'styled-components';

export const AppLogoSC = styled.div`
  display: flex;
  opacity: 0.7;

  ${(props) =>
    props.fixedAtBottom &&
    css`
      position: fixed;
      bottom: 20vh;
      left: 50%;
      transform: translateX(-50%);
    `};
`;

export const Logo = styled.img`
  height: 20px;
  margin-right: 10px;
`;

export const AppName = styled.div`
  display: flex;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
