/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-15 23:09:13
 * @modify date 2021-01-15 23:09:13
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import {
  HeaderDropdownSC,
  HeaderDropdownHeader,
  HeaderText,
  HeaderDropdownBody,
  HeaderDropdownRow,
  RowContent,
  EntityImage,
  EntityImageIcon,
  MessageContainer,
  MessageItem,
  Time,
} from './HeaderDropdown.style';

import Loading from 'components/common/loading/Loading';

export default function HeaderDropdown({ loading, data, headerLabel, type }) {
  return (
    <HeaderDropdownSC>
      <HeaderDropdownHeader>
        <HeaderText>
          {headerLabel}
          {loading ? '' : ` (${data.length})`}
        </HeaderText>
      </HeaderDropdownHeader>

      <HeaderDropdownBody>
        {loading ? (
          <Loading />
        ) : (
          data.map((item, index) => (
            <HeaderDropdownRow
              key={item.id}
              index={index}
              className="animate__animated animate__fadeIn"
            >
              <RowContent>
                {type === 'messages' ? (
                  <EntityImageIcon>
                    <Icon name="mail outline" className="s-icon" />
                  </EntityImageIcon>
                ) : (
                  <EntityImage
                    src={item.entityImage}
                    alt={item.entityName}
                    title={item.entityName}
                  />
                )}
                <MessageContainer>
                  {item.message.map((messageItem) => (
                    <MessageItem key={messageItem.id} type={messageItem.type}>
                      {messageItem.text}
                    </MessageItem>
                  ))}
                </MessageContainer>
                <Time>{item.time}</Time>
              </RowContent>
            </HeaderDropdownRow>
          ))
        )}
      </HeaderDropdownBody>
    </HeaderDropdownSC>
  );
}

HeaderDropdown.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
};
