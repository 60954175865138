/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 12:40:53
 * @modify date 2021-03-10 12:40:53
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  CommentSectionSC,
  CommentsList,
  Comment,
  UserInfo,
  TimeInfo,
  CommentText,
} from './CommentSection.style';

import NewCommentContainer from 'components/layout/new-comment/NewComment.container';

import Avatar from 'components/common/avatar/Avatar';
import Summary from 'components/common/summary/Summary';

import { getExtremeRelativeTime } from 'utils/date-formats-util';

export default function CommentSection({ comments, onNewCommentSubmit }) {
  return (
    <CommentSectionSC>
      <NewCommentContainer onSubmit={onNewCommentSubmit} />
      <CommentsList>
        {comments.map((comment) => (
          <Comment key={comment.id}>
            <UserInfo>
              <Avatar
                boldUsername
                username={comment.reporter.name}
                img={comment.reporter.img}
                link={`/user/${comment.reporter.id}`}
              />
              <TimeInfo>{getExtremeRelativeTime(comment.createdAt)}</TimeInfo>
            </UserInfo>
            <CommentText>
              <Summary
                summaryText={comment.description}
                attachments={comment.attachments ?? []}
              />
            </CommentText>
          </Comment>
        ))}
      </CommentsList>
    </CommentSectionSC>
  );
}

CommentSection.propTypes = {
  comments: PropTypes.array.isRequired,
  onNewCommentSubmit: PropTypes.func.isRequired,
};
