/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 01:46:55
 * @modify date 2020-12-24 01:46:55
 */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Team from './Team';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getTeamService } from './Team.service';

export default function TeamContainer() {
  const { t } = useTranslation();
  const { teamId } = useParams();

  const tabs = [
    {
      id: 'members',
      name: 'Members',
      link: `/team/${teamId}/members`,
    },
    {
      id: 'updates',
      name: 'Updates',
      link: `/team/${teamId}/updates`,
    },
  ];

  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    getTeamService(teamId).then((response) => {
      setTeamData(response?.data?.data);
      setLoading(false);
    });
  };

  useEffect(getData, [teamId]);

  if (loading) {
    return <Loading />;
  }

  if (!teamData) {
    return <NoData msg={t('no_team_found')} />;
  }

  return <Team data={teamData} tabs={tabs} />;
}
