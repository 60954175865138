/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 14:57:51
 * @modify date 2020-12-24 14:57:51
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ProjectsSC,
  AccordionHeaderContainer,
  PriorityBoardName,
  IconContainer,
  PriorityBoardTitle,
  AccordionBodyContainer,
} from './Projects.style';

import EntityOverviewAccordion from 'components/common/entity-overview-accordion/EntityOverviewAccordion';
import EntityName from 'components/common/entity-name/EntityName';
import LabelDonut from 'components/common/label-donut/LabelDonut';
import AvatarGroup from 'components/common/avatar-group/AvatarGroup';
import Avatar from 'components/common/avatar/Avatar';
import CustomIcon from 'components/common/custom-icon/CustomIcon';
import ImageIcon from 'components/common/image-icon/ImageIcon';
import TasksContainer from 'components/layout/tasks/Tasks.container';

import { getRangeAndDuration } from 'utils/date-formats-util';

export default function Projects({
  showPriorityBoard,
  user,
  projects,
  activeAccordion,
  onAccordionToggle,
}) {
  const { t } = useTranslation();

  return (
    <ProjectsSC>
      {showPriorityBoard ? (
        <EntityOverviewAccordion
          header={
            <AccordionHeaderContainer className="animate__animated animate__fadeIn priority-board">
              <PriorityBoardName>
                <IconContainer>
                  <CustomIcon name="Priority" size={42} />
                </IconContainer>
                <PriorityBoardTitle>{t('priority_board')}</PriorityBoardTitle>
              </PriorityBoardName>
              <LabelDonut
                value={user.completedTasks}
                total={user.totalTasks}
                label={t('tasks_completed')}
              />
              <Avatar username={user.name} img={user.img} alt={user.name} />
            </AccordionHeaderContainer>
          }
          isOpen={activeAccordion === 'priority_board'}
          onToggle={() => onAccordionToggle('priority_board')}
        >
          <AccordionBodyContainer>
            <TasksContainer entity1="user" id1={user.id} isPriority />
          </AccordionBodyContainer>
        </EntityOverviewAccordion>
      ) : null}

      {projects.map((project, index) => (
        <EntityOverviewAccordion
          key={project.id}
          header={
            <AccordionHeaderContainer
              className="animate__animated animate__fadeIn"
              index={index}
            >
              <EntityName
                icon={<ImageIcon src={project.img} alt={project.title} />}
                entityName={t('project')}
                title={project.title}
                link={`/ticket-${project.key}`}
                footer={getRangeAndDuration(project.createdAt, project.dueDate)}
              />
              <LabelDonut
                value={project.completedTasks}
                total={project.totalTasks}
                label={t('tasks_completed')}
              />
              <AvatarGroup
                users={project.contributors}
                view="line"
                maxCount={4}
              />
            </AccordionHeaderContainer>
          }
          isOpen={activeAccordion === project.id}
          onToggle={() => onAccordionToggle(project.id)}
        >
          <AccordionBodyContainer>
            <TasksContainer
              entity1="project"
              id1={project.id}
              entity2={user ? 'user' : ''}
              id2={user ? user.id : ''}
            />
          </AccordionBodyContainer>
        </EntityOverviewAccordion>
      ))}
    </ProjectsSC>
  );
}

Projects.propTypes = {
  showPriorityBoard: PropTypes.bool,
  user: PropTypes.object,
  projects: PropTypes.array.isRequired,
  activeAccordion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onAccordionToggle: PropTypes.func.isRequired,
};

Projects.defaultProps = {
  showPriorityBoard: false,
  activeAccordion: null,
};
