/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import SummaryCard from './SummaryCard';

export default function SummaryCardContainer({ summary, attachments }) {
  return <SummaryCard summary={summary} attachments={attachments} />;
}

SummaryCardContainer.propTypes = {
  summary: PropTypes.string.isRequired,
  attachments: PropTypes.array,
};

SummaryCardContainer.propTypes = {
  summary: PropTypes.string.isRequired,
  attachments: [],
};
