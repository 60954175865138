/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:33:58
 */

import styled from 'styled-components';

export const ProductsSC = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.count < 3 ? props.count : 3)},
    1fr
  );
  row-gap: 10px;
  column-gap: 5px;
`;

export const ProductIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 29px;
  border-radius: 4px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow2};
`;

export const ProductIcon = styled.img`
  display: flex;
  height: 16px;
`;

export const More = styled.div`
  display: flex;
  width: 29px;
  height: 29px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${(props) => props.theme.white};
  font-size: 11px;
  color: ${(props) => props.theme.secondaryTextColor};
  cursor: default;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.boxShadow2};
`;
