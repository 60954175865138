/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-23 16:23:49
 * @modify date 2021-02-16 23:11:11
 */

import moment from 'moment';

export function getUpadteCardDate(date) {
  return moment(date).format('MMM DD,YYYY');
}

export function getTimeRange(from, to) {
  let isToToday = false;

  if (getDiff(new Date(), to, 'hour') <= 0) {
    isToToday = true;
  }

  return (
    moment(from).format('MMM DD, YYYY') +
    ' - ' +
    (isToToday ? 'Present' : moment(to).format('MMM DD, YYYY'))
  );
}

export function getDuration(from, to) {
  let diff = moment(to).diff(moment(from), 'years');
  let label = diff > 1 ? ' years' : ' year';
  if (!diff) {
    diff = moment(to).diff(moment(from), 'months');
    label = diff > 1 ? ' months' : ' month';
  }
  if (!diff) {
    diff = moment(to).diff(moment(from), 'days');
    label = diff > 1 ? ' days' : ' day';
  }
  return `${diff} ${label}`;
}

export function getRangeAndDuration(from, to) {
  return getTimeRange(from, to) + ', ' + getExtremeRelativeTime(from, to);
}

/**
 * returns the number of "time interval" in two dates.
 * result will be in nagative if the date1 is less than date2
 * @param {date} date1 first date
 * @param {date} date2 second date
 * @param {string} intervalType the of interval; 'second', 'minute', 'hour', 'day', 'week', 'month', 'year'
 */
export function getDiff(date1, date2, intervalType = 'week') {
  return moment(date1).diff(moment(date2), intervalType);
}

/**
 * Relative time list
 * Time                  |  Display
 * --------------------- |  ----------------
 * Less than 1 minute    |  Just Now
 * Less than 1 hour      |  N mins ago
 * Within 24 hours       |  N hours ago
 * Within 7 days         |  N days ago
 * Longer than 7 days    |  MM-DD HH:MM, e.g 12-08 08:00
 * Longer than one year  |  YYYY-MM-DD HH:MM, e.g. 2019-12-08 08:00
 */
export function getRelativeTime(from, to) {
  if (!to) {
    to = new Date();
  }

  let diff = moment(to).diff(moment(from), 'years');

  // Longer than one year
  if (diff > 1) {
    return moment(from).format('YYYY-MM-DD HH:MM');
  }

  diff = moment(to).diff(moment(from), 'days');

  // Longer than 7 days
  if (diff > 7) {
    return moment(from).format('MM-DD HH:MM');
  }

  // Within 7 days
  if (diff >= 1 && diff <= 7) {
    return `${diff} days ago`;
  }

  diff = moment(to).diff(moment(from), 'hours');

  // Within 24 hours
  if (diff < 24 && diff >= 1) {
    return `${diff} hours ago`;
  }

  diff = moment(to).diff(moment(from), 'minutes');

  // Less than 1 hour
  if (diff < 59 && diff >= 1) {
    return `${diff} mins ago`;
  }

  // Less than 1 minute
  return 'Just Now';
}

/**
 * Relative time list
 * Time                  |  Display
 * --------------------- |  ----------------
 * Less than 1 minute    |  Just Now
 * Less than 1 hour      |  N mins ago
 * Within 24 hours       |  N hours ago
 * Within 7 days         |  N days ago
 * Less than 9 weeks     |  N weeks ago
 * Less than 12 months   |  N months ago
 * More than 12 months   |  Older than 1 year
 */
export function getExtremeRelativeTime(from, to) {
  if (!to) {
    to = new Date();
  }

  let diff = moment(to).diff(moment(from), 'months');

  // More than 12 months
  if (diff >= 12) {
    return 'Older than 1 year';
  }

  // Less than 12 months
  if (diff < 12 && diff > 2) {
    return `${diff} months ago`;
  }

  diff = moment(to).diff(moment(from), 'days');
  const diffWeek = moment(to).diff(moment(from), 'weeks');

  // Longer than 7 days
  if (diff > 7) {
    return `${diffWeek} weeks ago`;
  }

  // Within 7 days
  if (diff >= 1 && diff <= 7) {
    return `${diff} days ago`;
  }

  diff = moment(to).diff(moment(from), 'hours');

  // Within 24 hours
  if (diff < 24 && diff >= 1) {
    return `${diff} hours ago`;
  }

  diff = moment(to).diff(moment(from), 'minutes');

  // Less than 1 hour
  if (diff < 59 && diff >= 1) {
    return `${diff} mins ago`;
  }

  // Less than 1 minute
  return 'Just Now';
}
