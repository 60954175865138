/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-02-04 20:29:31
 */

export const highlightCardTypes = {
  components: 'tags',
  tags: 'tags',
  user: 'user',
  contributor: 'users',
  users: 'users',
  contributors: 'users',
  products: 'products',
  tickets: 'tickets',
  statuses: 'tickets',
};
