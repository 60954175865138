/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-02-04 20:28:47
 */

import styled from 'styled-components';

export const AvatarGroupSC = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) =>
      props.view === 'grid'
        ? props.userCount < 3
          ? props.userCount
          : '3'
        : props.maxCount},
    1fr
  );
  row-gap: 10px;
  column-gap: 5px;
`;

export const More = styled.div`
  display: flex;
  width: 29px;
  height: 29px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: ${(props) => props.theme.white};
  font-size: 11px;
  color: ${(props) => props.theme.secondaryTextColor};
  cursor: default;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.boxShadow1};
`;
