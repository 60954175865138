/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-25 16:47:02
 * @modify date 2021-04-17 19:12:41
 */

import { axiosInstance } from 'utils/api-util';

import { links } from 'utils/navigation-util';

export function createWorkspace(workspaceName) {
  const formData = new FormData();

  formData.append('name', workspaceName);

  return axiosInstance({
    url: 'company/',
    method: 'POST',
    data: formData,
    skipWorkspaceId: true,
  });
}

export function editWorkspace(id, workspaceName) {
  const formData = new FormData();

  formData.append('name', workspaceName);

  return axiosInstance({
    url: `company/${id}/`,
    method: 'PUT',
    data: formData,
    skipWorkspaceId: true,
  });
}

const locationPathAndStepMap = {
  [links.workspace.path]: 0,
  [links.workspace_edit.path]: 0,
  [links.invite_teammates.path]: 1,
};

export const getStepFromURL = (pathname) => {
  pathname = trimUrl(pathname);

  return locationPathAndStepMap[pathname];
};

export const trimUrl = (url) => {
  if (url[url.length - 1] === '/') {
    return url.slice(0, -1);
  }
  return url;
};

export const isEditMode = (pathname) => {
  pathname = trimUrl(pathname);
  return links.workspace_edit.path === pathname;
};
