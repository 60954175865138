/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-10 22:18:33
 * @modify date 2021-01-10 22:18:33
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import NewTicket from './NewTicket';

import ToastMessage from 'components/common/toast-message/ToastMessage';

import { updateActiveModal } from 'actions/common-actions';

import {
  PRIORITIES,
  getAllEntityData,
  getListData,
  createTicket,
  parseTicketTypes,
  parseRoots,
  parseContributors,
  parseComponents,
  uploadFiles,
  uploadFile,
  // getAction,
} from './NewTicket.service';

// import { getRandomInt } from 'utils/common-util';

const TABS = [
  {
    id: 'description',
    name: 'description',
  },
  {
    id: 'attachments',
    name: 'attachments',
  },
];

const FORM_DEFAULT_VALUE = {
  title: '',
  description: {},
  files: [],
  fileLoading: false,
  ticketType: '',
  rootValue: '',
  projectImage: '',
  projectImageLoading: false,
  contributorValue: '',
  selectedComponents: [],
  priorityValue: PRIORITIES[0].value,
  statusValue: '',
};

export default function NewTicketContainer({ context, data }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  // Dropdowns Data
  const [ticketTypes, setTicketTypes] = useState([]);
  const [roots, setRoots] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [components, setComponents] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [formData, setFormData] = useState(FORM_DEFAULT_VALUE);

  const [internalLoading, setInternalLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState(TABS[0].id);
  const [viewLayout, setViewLayout] = useState('default');

  const getData = () => {
    setLoading(true);
    getAllEntityData().then((responses) => {
      // Statuses
      setStatuses(responses[0]?.data?.data);

      // Ticket Type
      setTicketTypes(parseTicketTypes(responses[1]?.data?.data));

      // Root
      setRoots(parseRoots(responses[2]?.data?.data));

      // Contributors
      setContributors(parseContributors(responses[3]?.data?.data));

      // Components
      setComponents(parseComponents(responses[4]?.data?.data));

      if (context === 'edit') {
        // Fill the form with the existing values
        handleEditFormPrefill();
      }

      setLoading(false);
    });
  };

  useEffect(getData, []);

  const handleEditFormPrefill = () => {
    let description = {};

    if (data.description) {
      try {
        description = JSON.parse(data.description);
      } catch (e) {
        console.log(`Couldn't parse description!`, e);
      }
    }

    const mappedData = {
      title: data.title,
      description: description,
      files: data.attachments ?? [],
      fileLoading: false,
      ticketType: data.entity,
      rootValue:
        data.entity !== 'project' ? data.featureId ?? data.projectId ?? '' : '',
      projectImage: data.img,
      projectImageLoading: false,
      contributorValue: data.assignee.id ?? '',
      priorityValue:
        typeof data.isPriority === 'boolean'
          ? data.isPriority
            ? PRIORITIES[0].value
            : PRIORITIES[1].value
          : data.isPriority === 'true'
          ? PRIORITIES[0].value
          : PRIORITIES[1].value,
    };

    if (data.entity !== 'project') {
      mappedData.selectedComponents = data.components
        ? data.components.split(',')
        : '';
      mappedData.statusValue = data.status.id;
    }

    setFormData(mappedData);
    handleTicketTypeChange(data.entity);
  };

  const setDefaultStatus = () => {
    if (statuses && statuses.length) {
      setFormData((old) => {
        old.statusValue = statuses[0].id;
        return old;
      });
    }
  };

  useEffect(setDefaultStatus, [statuses]);

  const handleCloseModal = () => {
    dispatch(updateActiveModal(''));
  };

  // TODO: dummy API populdation not required.
  /* const handleTitleBlur = () => {
    if (title !== '' && roots.length) {
      const actionData = getAction(title);
      setTicketType('');
      setRootValue(roots[getRandomInt(0, roots.length - 1)].id);

      if (actionData === 0) {
        return;
      }

      setContributorValue(actionData.contributor);
      setSelectedComponents(actionData.components);
    }
  }; */

  const handleTicketTypeChange = (value) => {
    // TODO: This is currently hardcoded, may be later we can get isRoot as key in the response
    // from API to know that this ticket type is the highest root type
    if (value === 'project') {
      setViewLayout('project');
    } else {
      setViewLayout('default');
    }
  };

  const clearForm = () => {
    setFormData(FORM_DEFAULT_VALUE);
  };

  const handleFormSubmit = () => {
    // TODO: add validations

    let finalData = {};
    if (context === 'edit') {
      finalData.id = data.id;
    }
    finalData.title = formData.title;
    finalData.isPriority =
      formData.priorityValue === 'priority_task' ? true : false;
    finalData.entity = formData.ticketType;
    finalData.files = formData.files;
    finalData.description = formData.description;
    if (formData.contributorValue) {
      const selectedContributor = contributors.find(
        (c) => c.id === formData.contributorValue,
      );
      if (selectedContributor.description === 'Team') {
        finalData.teamId = formData.contributorValue;
      } else {
        finalData.assignee = formData.contributorValue;
      }
    }

    if (formData.ticketType === 'project') {
      finalData.image = formData.projectImage ? formData.projectImage : '';
    } else if (formData.ticketType !== 'project' && formData.rootValue) {
      const selectedRoot = roots.find((r) => r.id === formData.rootValue);
      if (selectedRoot.description === 'Project') {
        finalData.projectId = formData.rootValue;
      } else {
        finalData.featureId = formData.rootValue;
      }
      finalData.image = selectedRoot.img;

      // Components are not required for projects
      finalData.components = formData.selectedComponents;

      // Status are not required for projects
      finalData.status = formData.statusValue;
    }

    setSaveLoading(true);
    createTicket(context, finalData).then((response) => {
      if (response?.data?.status === 'created') {
        toast(
          <ToastMessage
            title={t('new_ticket_created')}
            message={
              <span>
                {t('new_ticket') + ': '}
                <Link
                  to={'/ticket-' + response.data.key}
                  onClick={handleCloseModal}
                >
                  {'ticket-' + response.data.key}
                </Link>
              </span>
            }
            isDarkText
          />,
        );
      }

      if (context === 'create') {
        clearForm();
      } else {
        // handleCloseModal();
        window.location.reload();
      }
      setSaveLoading(false);
    });
  };

  const handleSearch = (_, data) => {
    if (data.id === 'root') {
      getListData('root', data.searchQuery).then((response) => {
        setRoots(parseRoots(response?.data?.data));
        setFormData((old) => ({
          ...old,
          rootValue: '',
        }));
        setInternalLoading((old) => !old);
      });
    } else if (data.id === 'contributors') {
      getListData('assignee', data.searchQuery).then((response) => {
        setContributors(parseContributors(response?.data?.data));
        setFormData((old) => ({
          ...old,
          contributorValue: '',
        }));
        setInternalLoading((old) => !old);
      });
    } else if (data.id === 'components') {
      getListData('component', data.searchQuery).then((response) => {
        setComponents(parseComponents(response?.data?.data));
        setInternalLoading((old) => !old);
      });
    }
  };

  const handleTabChange = (tab) => {
    setActiveTabId(tab.id);
  };

  const handleFileAdd = (newFiles) => {
    setFormData((old) => ({ ...old, fileLoading: true }));

    uploadFiles(newFiles).then((responses) => {
      let newFilesData = [];
      for (let response of responses) {
        newFilesData.push(response?.data?.file);
      }

      setFormData((old) => {
        old.files = [...old.files, ...newFilesData];
        old.fileLoading = false;
        return old;
      });
      setInternalLoading((old) => !old);
    });
  };

  const handleFileRemove = (selectedFile) => {
    // TODO: Call API to delete the file from the server. (When API is available)
    const newFiles = formData.files.filter(
      (file) => file.id !== selectedFile.id,
    );
    setFormData((old) => {
      old.files = [...newFiles];
      return old;
    });
    setInternalLoading((old) => !old);
  };

  const handleProjectLogoAdd = (newFiles) => {
    if (newFiles[0]) {
      setFormData((old) => ({ ...old, projectImageLoading: true }));
      uploadFile(newFiles[0]).then((response) => {
        setFormData((old) => {
          old.projectImage = response?.data?.file?.url;
          old.projectImageLoading = false;
          return old;
        });
        setInternalLoading((old) => !old);
      });
    }
  };

  const handleFormDataChange = (fieldName, value) => {
    if (fieldName === 'ticketType') {
      handleTicketTypeChange(value);
    }
    setFormData((old) => ({
      ...old,
      [fieldName]: value,
    }));
  };

  return (
    <NewTicket
      context={context}
      loading={loading}
      saveLoading={saveLoading}
      internalLoading={internalLoading}
      viewLayout={viewLayout}
      roots={roots}
      contributors={contributors}
      components={components}
      statuses={statuses}
      priorities={PRIORITIES}
      ticketTypes={ticketTypes}
      formData={formData}
      onFormDataChange={handleFormDataChange}
      onFileAdd={handleFileAdd}
      onFileRemove={handleFileRemove}
      onProjectLogoAdd={handleProjectLogoAdd}
      tabs={TABS}
      activeTabId={activeTabId}
      onTabChange={handleTabChange}
      onSearch={handleSearch}
      onModalClose={handleCloseModal}
      onFormSubmit={handleFormSubmit}
      // Removed the auto populate feature for now.
      onTitleBlur={() => {}}
    />
  );
}

NewTicketContainer.propTypes = {
  context: PropTypes.oneOf(['create', 'edit']),
  data: PropTypes.object,
};

NewTicketContainer.defaultProps = {
  context: 'create',
  data: null,
};
