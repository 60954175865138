/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 20:51:43
 * @modify date 2021-02-24 20:51:43
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StepsSC, Step, StepIcon, StepText } from './Steps.style';

import Icon from 'components/common/icon/Icon';

export default function Steps({ steps, activeStepIndex }) {
  return (
    <StepsSC>
      {steps.map((step, index) => (
        <Step
          key={step.id}
          activeStep={index <= activeStepIndex ? 1 : 0}
          as={step.link ? Link : 'div'}
          to={step.link}
        >
          <StepIcon>
            {index < activeStepIndex ? (
              <Icon name="done" isDark size={14} />
            ) : (
              ''
            )}
          </StepIcon>
          <StepText>{index + 1 + '. ' + step.text}</StepText>
        </Step>
      ))}
    </StepsSC>
  );
}
Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
};
