/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-24 01:07:55
 * @modify date 2021-01-24 02:38:41
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ToastMessage from 'components/common/toast-message/ToastMessage';

import LandingPage from './LandingPage';

import { sendUserContactDetails } from './LandingPage.service';

export default function LandingPageContainer() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleContactFormSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    sendUserContactDetails({
      name: event.target[0].value,
      company: event.target[1].value,
      emailAddress: event.target[2].value,
    })
      .then(() => {
        toast(
          <ToastMessage
            title={t('thank_you')}
            message={t('landing_page.contact.contact_success_message')}
            isDarkText
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <LandingPage
      loading={loading}
      onContactFormSubmit={handleContactFormSubmit}
    />
  );
}
