/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 22:05:26
 * @modify date 2021-02-26 02:25:04
 */

import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

export const InviteTeammatesSC = styled.div`
  display: flex;
  flex-direction: column;

  .submit-button {
    background: ${(props) => props.theme.primaryColor};
  }
`;
export const Heading = styled.h1`
  display: flex;
  font-size: 32px;
  margin: 0 0 16px;
`;

export const Highlight = styled.span`
  color: ${(props) => props.theme.primaryColor};
  margin-left: 6px;
`;

export const SubHeading = styled.h3`
  display: flex;
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  font-weight: normal;
  max-width: 600px;
  color: ${(props) => props.theme.blackText2};
`;

export const FormSC = styled(Form)`
  margin-top: 40px;
  &&& {
    max-width: 500px;
  }
`;

export const AddAnotherInviteLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.primaryColor};

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }

  .link-icon {
    transform: rotate(-45deg);
  }

  &:hover {
    color: ${(props) => props.theme.primaryDarkColor};

    .k-icon {
      color: ${(props) => props.theme.primaryDarkColor};
    }
  }
`;

export const LinkText = styled.div`
  display: flex;
  margin-left: 10px;
  font-size: 14px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  width: 170px;
  margin-top: 24px;
`;

export const SkipLink = styled(Link)`
  margin-top: 16px;
  text-decoration: underline;
  color: ${(props) => props.theme.blackText2};
  width: fit-content;
`;
