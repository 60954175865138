/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';

import {
  HeaderButtonSC,
  HeaderButtonContainer,
  Badge,
} from './HeaderButton.style';

export default function HeaderButton({ name, icon, isBadged, content }) {
  return (
    <HeaderButtonSC title={name}>
      <Popup
        trigger={
          <HeaderButtonContainer>
            <Icon name={icon} />
            {isBadged ? <Badge /> : null}
          </HeaderButtonContainer>
        }
        style={{
          padding: 0,
          borderRadius: '8px',
        }}
        position="bottom right"
        on="click"
        content={content}
        wide
        pinned
        offset={[-16, -14]}
      />
    </HeaderButtonSC>
  );
}

HeaderButton.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isBadged: PropTypes.bool,
  content: PropTypes.element,
};

HeaderButton.defaultProps = {
  isBadged: false,
  content: null,
};
