/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-19 14:24:11
 */

import { combineReducers } from 'redux';

import commonReducer from 'reducers/common-reducer';
import userReducer from 'reducers/user-reducer';

export default combineReducers({
  commonStates: commonReducer,
  userStates: userReducer,
});
