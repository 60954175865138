/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 18:18:26
 * @modify date 2021-01-19 19:29:25
 */

import styled from 'styled-components';
import { variables, zIndex } from 'variables';

export const MenuSC = styled.div`
  position: absolute;
  top: ${(props) => (props.isOverflowing ? 'unset' : props.top + 'px')};
  right: ${(props) => props.right}px;
  bottom: ${(props) => (props.isOverflowing ? '10px' : 'unset')};
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  min-width: ${variables.menu.width}px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.dividerColor};
  background: ${(props) => props.theme.white};
  z-index: ${zIndex.menu};
  box-shadow: ${(props) => props.theme.boxShadow2};
  transition: all 250ms;
`;

export const Option = styled.div`
  position: relative;
  display: flex;
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
  height: ${variables.menu.height}px;
  transition: all 250ms;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.lightBackgroundColor2};
  }
`;

export const OptionLabel = styled.span`
  font-size: 12.8px;
  flex: 1;
`;

export const OptionIcon = styled.span`
  display: flex;
  align-items: center;
`;

// export const InnerOptions = styled.div`
//   position: absolute;
//   left: -${variables.menu.width}px;
//   top: 0;
//   display: flex;
//   flex-direction: column;
//   min-width: ${variables.menu.width}px;
//   display: ${(props) => (props.isOpen ? 'flex' : 'none')};
//   flex-direction: column;
//   min-width: ${variables.menu.width}px;
//   border-radius: 8px;
//   border: 1px solid ${(props) => props.theme.dividerColor};
//   background: ${(props) => props.theme.white};
//   box-shadow: ${(props) => props.theme.boxShadow2};
//   transition: all 250ms;
// `;
