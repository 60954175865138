/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TicketBasicDataSC, AvatarContainer } from './TicketBasicData.style';

import EntityName from 'components/common/entity-name/EntityName';
import TimeRange from 'components/common/time-range/TimeRange';
import Avatar from 'components/common/avatar/Avatar';
import ImageIcon from 'components/common/image-icon/ImageIcon';

import { mapAPIEntityTypeWithTranslationKey } from 'utils/ticket-type-map-util';
import { getTimeRange, getExtremeRelativeTime } from 'utils/date-formats-util';
import { getIconByTicket } from 'utils/ticket-status-util';

export default function TicketBasicData({ ticket, ticketAssignee }) {
  const { t } = useTranslation();
  return (
    <TicketBasicDataSC>
      <EntityName
        link={`/ticket-${ticket.key}`}
        icon={
          <ImageIcon
            link={`/ticket-${ticket.key}`}
            src={ticket.img}
            alt={ticket.title}
            size="small"
          />
        }
        entityName={t(mapAPIEntityTypeWithTranslationKey[ticket.entity])}
        title={ticket.title}
        size="small"
      />
      <TimeRange
        isPriority={ticket.isPriority}
        icon={getIconByTicket(ticket)}
        range={getTimeRange(ticket.createdAt, ticket.endDate ?? new Date())}
        duration={getExtremeRelativeTime(
          ticket.createdAt,
          ticket.endDate ?? new Date(),
        )}
      />
      <AvatarContainer>
        <Avatar
          link={'/user/' + ticketAssignee.id}
          username={ticketAssignee.name}
          img={ticketAssignee.img}
          alt={ticketAssignee.name}
        />
      </AvatarContainer>
    </TicketBasicDataSC>
  );
}

TicketBasicData.propTypes = {
  ticket: PropTypes.object.isRequired,
  ticketAssignee: PropTypes.object.isRequired,
};
