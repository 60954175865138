/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 14:57:24
 * @modify date 2020-12-24 14:57:24
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Projects from './Projects';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getProjectsService } from './Projects.service';

export default function ProjectsContainer({ entity, id, user }) {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjects = () => {
    getProjectsService(entity, id).then((projects) => {
      setProjects(projects?.data?.data);
      setLoading(false);
    });
  };

  useEffect(getProjects, []);

  const handleToggleAccordion = (accordionName) => {
    if (activeAccordion === accordionName) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionName);
    }
  };

  if (loading) {
    return <Loading gap={100} />;
  }

  if (!projects.length) {
    return <NoData />;
  }

  return (
    <Projects
      // TODO: enable the below line when we decide to show the priority board
      // showPriorityBoard={user !== null}
      showPriorityBoard={false}
      user={user}
      projects={projects}
      activeAccordion={activeAccordion}
      onAccordionToggle={handleToggleAccordion}
    />
  );
}

ProjectsContainer.propTypes = {
  entity: PropTypes.string,
  id: PropTypes.string,
  user: PropTypes.object,
};

ProjectsContainer.defaultProps = {
  entity: '',
  id: '',
  user: null,
};
