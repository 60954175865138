/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CardSC } from './Card.style';

export default function Card({ children }) {
  return <CardSC data-testid="card">{children}</CardSC>;
}

Card.propTypes = {
  /** Content of the card */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};
