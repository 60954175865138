/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:34
 * @modify date 2021-04-01 12:11:57
 */

import { axiosInstance } from 'utils/api-util';

export function getDashboardsService() {
  return axiosInstance({ url: 'entity/dashboards' });
}

export function saveDashboard(filterIds) {
  const formData = new FormData();

  formData.append('filter_ids', JSON.stringify(filterIds));

  return axiosInstance({
    url: 'entity/dashboard',
    method: 'POST',
    data: formData,
  });
}
