/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:55:55
 * @modify date 2021-01-04 15:55:55
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button as ButtonSM, Form, Icon as IconSM } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  RegisterSC,
  BackButtonContainer,
  LeftSection,
  RightSection,
  FormContainer,
  FormInner,
  FormSM,
  FormHeading,
  RegisterButtonContainer,
  LoginContainer,
  AlreadyMemberText,
  LoginLink,
  TermsContainer,
} from './Register.style';

import AuthWelcome from 'components/layout/auth-welcome/AuthWelcome';
import OAuthButtonsContainer from 'components/layout/oauth-buttons/OAuthButtons.container';

import Divider from 'components/common/divider/Divider';
import FormError from 'components/common/form-error/FormError';
import PasswordStrength from 'components/common/password-strength/PasswordStrength';

import { links } from 'utils/navigation-util';

export default function Register({
  loading,
  isError,
  isEmailReadonly,
  formData,
  errorMessages,
  fieldErrors,
  onFormDataChange,
  onRegisterSubmit,
}) {
  const { t } = useTranslation();

  return (
    <RegisterSC>
      <BackButtonContainer to={links.landing.path}>
        <ButtonSM basic size="small">
          <IconSM name="arrow left" />
          {t('back')}
        </ButtonSM>
      </BackButtonContainer>
      <LeftSection>
        <AuthWelcome />
      </LeftSection>

      <RightSection>
        <FormContainer className="animate__animated animate__slideInRight">
          <FormInner>
            <FormHeading>{t('register')}</FormHeading>

            <OAuthButtonsContainer />

            <FormSM>
              <Form.Group>
                <Form.Field>
                  <Form.Input
                    placeholder={t('first_name')}
                    id="firstName"
                    value={formData.firstName}
                    onChange={onFormDataChange}
                    error={fieldErrors.firstName}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    placeholder={t('last_name')}
                    id="lastName"
                    value={formData.lastName}
                    onChange={onFormDataChange}
                    error={fieldErrors.lastName}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Form.Input
                  icon="mail"
                  iconPosition="left"
                  type="email"
                  placeholder={t('email')}
                  id="email"
                  value={formData.email}
                  onChange={onFormDataChange}
                  error={fieldErrors.email}
                  readOnly={isEmailReadonly}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  placeholder={t('password')}
                  id="password"
                  value={formData.password}
                  onChange={onFormDataChange}
                  error={fieldErrors.password}
                />
              </Form.Field>
              {formData.password ? (
                <PasswordStrength password={formData.password} />
              ) : null}
            </FormSM>
            <RegisterButtonContainer>
              {isError ? <FormError errorMessages={errorMessages} /> : null}
              <ButtonSM
                className="c-button"
                primary
                loading={loading}
                onClick={onRegisterSubmit}
              >
                {t('sign_up')}
              </ButtonSM>
            </RegisterButtonContainer>
            <Divider />
            <LoginContainer>
              <AlreadyMemberText>{t('already_a_member')}</AlreadyMemberText>
              &nbsp;
              <LoginLink to={links.login.path}>{t('sign_in')}</LoginLink>
            </LoginContainer>
            <TermsContainer>
              By signing up, I accept the Plateau{' '}
              <Link
                to={links.terms_of_service.path}
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </Link>{' '}
              and acknowledge the{' '}
              <Link
                to={links.privacy_policy.path}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Link>
              .
            </TermsContainer>
          </FormInner>
        </FormContainer>
      </RightSection>
    </RegisterSC>
  );
}

Register.propTypes = {
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isEmailReadonly: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  errorMessages: PropTypes.array.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
  onRegisterSubmit: PropTypes.func.isRequired,
};

Register.defaultProps = {
  isEmailReadonly: false,
};
