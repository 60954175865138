/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:13:20
 * @modify date 2020-12-31 00:13:20
 */

import React from 'react';
import PropTypes from 'prop-types';

import { StatusIconSC } from './StatusIcon.style';

export default function StatusIcon({ children }) {
  return (
    <StatusIconSC data-testid="status-icon" className="k-status-icon">
      {children}
    </StatusIconSC>
  );
}

StatusIcon.propTypes = {
  children: PropTypes.element.isRequired,
};
