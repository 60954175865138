/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-17 21:28:14
 * @modify date 2021-04-18 12:11:01
 */

import styled from 'styled-components';

export const ChangeableAvatarSC = styled.div`
  display: flex;
  max-height: 32px;

  .ui.dropdown.dropdown .menu > .input {
    margin: 0;

    input {
      border-bottom: none;
    }
  }
  .ui.pointing.dropdown > .menu:after {
    display: none;
  }
`;
