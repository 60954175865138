/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { SeparatorSC } from './Separator.style';

export default function Separator({ size }) {
  return <SeparatorSC data-testid="separator" size={size} />;
}

Separator.propTypes = {
  size: PropTypes.number,
};

Separator.defaultProps = {
  size: 8,
};
