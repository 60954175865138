/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'i18n';

import GuestNavigatorContainer from 'components/pages/guest/GuestNavigator.container';
import MemberNavigatorContainer from 'components/pages/member/MemberNavigator.container';

import { AppRouterSC } from './AppRouter.style';

import { getTheme } from 'utils/theme-util';
import { isLoggedIn } from 'utils/auth-util';

export default function AppRouter() {
  const theme = useSelector((state) => state.commonStates.theme);

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <AppRouterSC data-testid="app-router">
        <Router>
          <Switch>
            <Route path="/">
              {isLoggedIn() ? (
                <MemberNavigatorContainer />
              ) : (
                <GuestNavigatorContainer />
              )}
            </Route>
          </Switch>

          <div id="modal-root" />
          <div id="overlay-root" />
          <div id="menu-root" />

          <ToastContainer
            position="bottom-left"
            autoClose={8000}
            limit={8}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Router>
      </AppRouterSC>
    </ThemeProvider>
  );
}
