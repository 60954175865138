/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 22:04:45
 * @modify date 2021-02-24 22:04:45
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Input, Label, Popup } from 'semantic-ui-react';

import {
  InviteTeammatesSC,
  Heading,
  Highlight,
  SubHeading,
  FormSC,
  AddAnotherInviteLinkContainer,
  LinkContainer,
  LinkText,
  SubmitButtonContainer,
  SkipLink,
} from './InviteTeammates.style';

import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';

import { links } from 'utils/navigation-util';

let copiedTimeout;
const COPIED_TIME = 5000;

export default function InviteTeammates({
  workspaceName,
  inviteLink,
  inviteEmails,
  inviteLoading,
  onInviteEmailChange,
  onAddNewInviteEmailSlot,
  onInviteSubmit,
}) {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);
  const [isCopiedPopupOpen, setIsCopiedPopupOpen] = useState(false);

  useEffect(() => {
    if (copiedTimeout) {
      clearTimeout(copiedTimeout);
    }
    if (copied) {
      copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, COPIED_TIME);
    }
  }, [copied]);

  return (
    <InviteTeammatesSC>
      <Heading>
        <Trans
          i18nKey={'new_workspace.step2_heading'}
          values={{ workspace_name: workspaceName }}
          components={{ highlight: <Highlight /> }}
        />
      </Heading>
      <SubHeading>{t('new_workspace.step2_sub_heading')}</SubHeading>

      <FormSC>
        {inviteEmails.map((email, index) => (
          <FormSC.Field key={email.id}>
            <Input
              type="email"
              placeholder={t('new_workspace.step2_placeholder')}
              value={email.email}
              onChange={(_, { value }) => onInviteEmailChange(value, index)}
              fluid
              error={email.error ? true : false}
            />
            {email.error ? (
              <Label pointing prompt>
                {email.error}
              </Label>
            ) : null}
          </FormSC.Field>
        ))}

        <AddAnotherInviteLinkContainer>
          <LinkContainer onClick={onAddNewInviteEmailSlot}>
            <Icon name="add_circle_outline" size={23} />
            <LinkText>{t('add_another')}</LinkText>
          </LinkContainer>

          <Popup
            trigger={
              <LinkContainer
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(inviteLink);
                  setCopied(true);
                }}
                onMouseEnter={() => setIsCopiedPopupOpen(true)}
                onMouseLeave={() => setIsCopiedPopupOpen(false)}
              >
                <Icon className="link-icon" name="link" size={23} />
                <LinkText>{t('get_a_shareable_invite_link_instead')}</LinkText>
              </LinkContainer>
            }
            open={isCopiedPopupOpen}
            content={t(copied ? 'copied' : 'copy_url')}
            position="bottom center"
            size="mini"
            inverted
          />
        </AddAnotherInviteLinkContainer>

        <SubmitButtonContainer>
          <Button
            onClick={onInviteSubmit}
            text={t('new_workspace.invite_teammates')}
            rightIcon={<Icon name="keyboard_arrow_right" />}
            size="large"
            loading={inviteLoading}
          />
        </SubmitButtonContainer>
      </FormSC>

      <SkipLink to={links.home.path}>{t('skip_for_now')}</SkipLink>
    </InviteTeammatesSC>
  );
}

InviteTeammates.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  inviteLink: PropTypes.string.isRequired,
  inviteEmails: PropTypes.array.isRequired,
  inviteLoading: PropTypes.bool.isRequired,
  onInviteEmailChange: PropTypes.func.isRequired,
  onAddNewInviteEmailSlot: PropTypes.func.isRequired,
  onInviteSubmit: PropTypes.func.isRequired,
};
