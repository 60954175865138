/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-10 17:09:51
 * @modify date 2021-01-11 12:58:33
 */

import styled from 'styled-components';
import { zIndex } from 'variables';

export const ModalOverlay = styled.div`
  z-index: ${zIndex.modalOverlay};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${(props) => props.theme.overlayBackground};
`;

export const ModalSC = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  min-height: 200px;
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.modal.boxShadow};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: ${(props) =>
    props.active ? props.theme.primaryColor : props.theme.primaryColorA1};
  color: ${(props) =>
    props.active ? props.theme.white : props.theme.primaryColor};
  padding: 0 12px 0 20px;
  border-radius: 8px 8px 0 0;

  .k-icon {
    color: ${(props) =>
      props.active ? props.theme.white : props.theme.primaryColor};
  }
`;

export const ModalHeaderText = styled.div`
  font-size: 14.4;
  font-weight: bold;
`;

export const ModalCloseButton = styled.div`
  display: flex;
  font-size: 35px;
  width: 23px;
  height: 23px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 250ms ease;

  &:hover {
    background: ${(props) => props.theme.blackA1};
  }
`;
export const ModalBody = styled.div`
  display: flex;
  flex: 1;
`;

export const ModalFooter = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.dividerColor};
`;
