/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:33:33
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const EntityOverviewAccordionSC = styled.div`
  flex: 1;
  display: grid;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow1};
  border-radius: 8px;
  margin-bottom: ${(props) => (props.isOpen ? '8px' : 0)};
`;

export const HeaderContainer = styled.div`
  display: flex;
  height: 88px;
  align-items: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex: 1;
  padding: 0 ${variables.cardPadding}px;
`;

export const ToggleButton = styled.div`
  display: flex;
  justify-self: flex-end;
  padding: 0 48px;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  min-height: ${(props) => (props.isOpen ? `480px` : 0)};

  border-top: 1px solid
    ${(props) => props.theme.entityOverviewAccordion.body.borderColor};
  transition: all 250ms ease;
`;

// max-height: ${(props) => (props.isOpen ? `480px` : 0)};
// overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
