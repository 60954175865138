/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 15:23:06
 * @modify date 2021-02-24 15:23:06
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Switch, Route, useLocation } from 'react-router-dom';

import { WorkspaceSC, PageContainer, StepInfo } from './Workspace.style';

import StepsContainer from 'components/pages/member/workspace/steps-container/StepsContainer';
import CreateWorkspace from 'components/pages/member/workspace/create-workspace/CreateWorkspace';
import InviteTeammatesContainer from 'components/pages/member/workspace/invite-teammates/InviteTeammates.container';

import { links } from 'utils/navigation-util';

import { getStepFromURL } from './Workspace.service';

export default function Workspace({
  isFirstWorkspace,
  mode,
  userData,
  workspaceName,
  workspaceNameLoading,
  workspaceError,
  onWorkspaceNameChange,
  onWorkspaceSubmit,
  onEditWorkspaceSubmit,
}) {
  const location = useLocation();

  return (
    <WorkspaceSC>
      <StepsContainer
        showHomeLink={!isFirstWorkspace}
        mode={mode}
        activeStepIndex={getStepFromURL(location.pathname)}
        userData={userData}
        workspaceName={workspaceName}
      />

      <PageContainer>
        <StepInfo>
          <Trans
            i18nKey={'new_workspace.steps_info'}
            values={{
              current_step: getStepFromURL(location.pathname) + 1,
              total_steps: 2,
            }}
          />
        </StepInfo>
        <Switch>
          <Route exact path={links.workspace.path}>
            <CreateWorkspace
              mode={mode}
              workspaceName={workspaceName}
              workspaceNameLoading={workspaceNameLoading}
              error={workspaceError}
              showCancelButton={!isFirstWorkspace}
              onWorkspaceNameChange={onWorkspaceNameChange}
              onSubmit={onWorkspaceSubmit}
            />
          </Route>
          <Route exact path={links.workspace_edit.path}>
            <CreateWorkspace
              mode={mode}
              workspaceName={workspaceName}
              workspaceNameLoading={workspaceNameLoading}
              error={workspaceError}
              showCancelButton
              onWorkspaceNameChange={onWorkspaceNameChange}
              onSubmit={onEditWorkspaceSubmit}
            />
          </Route>
          <Route exact path={links.invite_teammates.path}>
            <InviteTeammatesContainer />
          </Route>
        </Switch>
      </PageContainer>
    </WorkspaceSC>
  );
}

Workspace.propTypes = {
  isFirstWorkspace: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  userData: PropTypes.object.isRequired,
  workspaceName: PropTypes.string.isRequired,
  workspaceNameLoading: PropTypes.bool.isRequired,
  workspaceError: PropTypes.string.isRequired,
  onWorkspaceNameChange: PropTypes.func.isRequired,
  onWorkspaceSubmit: PropTypes.func.isRequired,
  onEditWorkspaceSubmit: PropTypes.func.isRequired,
};
