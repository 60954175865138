/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';

import { HomeSC } from './Home.style';

import UpdatesContainer from 'components/layout/updates/Updates.container';

export default function Home() {
  return (
    <HomeSC data-testid="home-page">
      <UpdatesContainer />
    </HomeSC>
  );
}
