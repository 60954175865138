/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { HeaderSC, SearchContainerSC, ButtonContainer } from './Header.style';

import SearchContainer from 'components/layout/search/Search.container';
import HeaderButtons from 'components/layout/header/header-buttons/HeaderButtons';

export default function Header({ isAppDrawerOpen }) {
  return (
    <HeaderSC className="animate__animated animate__fadeInDown">
      <SearchContainerSC isAppDrawerOpen={isAppDrawerOpen}>
        <SearchContainer />
      </SearchContainerSC>
      <ButtonContainer isAppDrawerOpen={isAppDrawerOpen}>
        <HeaderButtons />
      </ButtonContainer>
    </HeaderSC>
  );
}

Header.propTypes = {
  isAppDrawerOpen: PropTypes.bool.isRequired,
};
