/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-17 21:30:35
 * @modify date 2021-04-17 21:30:35
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ChangeableAvatar from './ChangeableAvatar';

import {
  getUsers,
  parseContributors,
  updateAssignement,
} from './ChangeableAvatar.service';

import Config from 'app.config';

let delayTimer;

export default function ChangeableAvatarContainer({ ticket, user }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(user);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const getUserData = () => {
    setLoading(true);

    getUsers(searchValue).then((response) => {
      if (!response.error) {
        setUsers(parseContributors(response?.data?.data) ?? []);
      }

      setLoading(false);
    });
  };

  useEffect(getUserData, []);

  const handleSearchValueChange = () => {
    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
      getUserData();
    }, Config.searchDelay);
  };

  useEffect(handleSearchValueChange, [searchValue]);

  const handleDropdownChange = (user) => {
    setSelectedUser(user);
    updateAssignement(ticket, user);
  };

  const handleSearchChange = (_, { value }) => {
    setSearchValue(value);
  };

  return (
    <ChangeableAvatar
      loading={loading}
      selectedUser={selectedUser}
      users={users}
      searchValue={searchValue}
      onDropdownChange={handleDropdownChange}
      onSearchChange={handleSearchChange}
    />
  );
}

ChangeableAvatarContainer.propTypes = {
  ticket: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
