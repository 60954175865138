/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-20 17:43:08
 * @modify date 2021-04-23 17:51:29
 */

import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const SectionContainer = styled.div`
  padding: 0 20px;
  background: ${(props) =>
    props.addBackground
      ? props.theme.landingPage.lightBackgroundColor
      : 'transparent'};
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  padding: 70px 0;
`;

export const LandingPageSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .c-button {
    width: 120px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

export const CatchLink = styled.div`
  height: 0;
  width: 0;
  position: relative;
  top: -70px;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    top: -260px;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
`;

export const Heading1 = styled.h1`
  display: flex;
  font-size: 32px;
  line-height: 42px;
  font-weight: bold;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

export const Heading2 = styled.h2`
  display: flex;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  margin: 0;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
  text-transform: ${(props) => props.case ?? 'unset'};
`;

export const Heading3 = styled.h3`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
  text-transform: ${(props) => props.case ?? 'unset'};
`;

export const Para = styled.p`
  display: flex;
  margin-bottom: 30px;
  font-size: 14px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const Image = styled.img`
  height: 380px;
  max-width: 100%;
`;

export const Section1 = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    padding: 0;

    ${TextArea}, ${ImageContainer} {
      align-items: center;
      justify-content: center;

      ${Heading1} {
        text-align: center;
      }
    }
  }
`;

export const VisionSection = styled(Section)``;

export const SectionContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: flex-start;
  height: 330px;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
    height: auto;

    ${TextArea} {
      margin-top: 50px;
    }
  }
`;

export const VisionSectionContent = styled(SectionContent)`
  height: auto;
`;

export const SolutionSection = styled(Section)``;

export const SolutionSectionContent = styled(SectionContent)`
  height: auto;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const FeatureSection = styled(Section)``;

export const FeatureSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const FeatureSectionContentPhone = styled.div`
  display: flex;
  width: 200px;

  @media only screen and (min-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

export const FeatureImage = styled.img`
  height: 180px;
  margin-bottom: 30px;
`;

export const FeatureText = styled.div`
  display: inline;

  strong {
    margin-right: 2px;
  }
`;

export const ContentSection = styled(Section)`
  margin-bottom: 200px;
`;

export const ContactForm = styled.form`
  display: grid;
  gap: 24px;
  width: 350px;
  max-width: 100%;
  margin-top: 50px;

  @media only screen and (min-width: ${deviceBreakpoints.tablet}px) {
    margin-left: 40px;
  }

  .s-button {
    background: ${(props) => props.theme.primaryColor} !important;
    width: 155px;
    height: 48px;
    border-radius: 8px;
    font-size: 14px;
  }
`;

export const Input = styled.input`
  display: flex;
  border-radius: 12px;
  height: 75px;
  padding-left: 24px;
  background: ${(props) => props.theme.white};
  border: none;
  outline: none;
  box-shadow: 10.5px 9.75px 19.5px 7.3px rgba(66, 141, 255, 0.08);
  font-size: 16px;
  line-height: 20px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${(props) => props.theme.landingPage.input.placeholder};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${(props) => props.theme.landingPage.input.placeholder};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${(props) => props.theme.landingPage.input.placeholder};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${(props) => props.theme.landingPage.input.placeholder};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
