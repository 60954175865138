/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-23 16:03:30
 * @modify date 2021-03-16 15:47:24
 */

import { axiosInstance } from 'utils/api-util';

export function getUpdatesService(entity, id, page = 0) {
  let params = {};
  if (entity) params.entity = entity;
  if (id) params.id = id;

  return axiosInstance({
    url: `entity/updates`,
    params,
    page,
  });
}

export function addComment(comment, attachments, updateId) {
  const formData = new FormData();

  formData.append('comment', JSON.stringify(comment));
  if (attachments && attachments.length) {
    formData.append('attachments', JSON.stringify(attachments));
  }
  formData.append('id', updateId);

  return axiosInstance({
    url: 'entity/interact',
    method: 'POST',
    data: formData,
  });
}

export function updateLike(liked, updateId) {
  const formData = new FormData();

  formData.append('like', liked);
  formData.append('id', updateId);

  return axiosInstance({
    url: 'entity/interact',
    method: 'POST',
    data: formData,
  });
}
