/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { DonutChartSC } from './DonutChart.style';

const radius = 17;

export default function DonutChart({ percent, size }) {
  return (
    <DonutChartSC
      data-testid="donut-chart"
      className="k-donut-chart"
      size={size}
    >
      <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
        <circle
          className="donut-hole"
          cx="50%"
          cy="50%"
          r={radius}
          fill="transparent"
        />
        <circle
          className="donut-ring"
          cx="50%"
          cy="50%"
          r={radius}
          fill="transparent"
          strokeWidth="6"
        />

        <circle
          className="donut-segment"
          cx="50%"
          cy="50%"
          r={radius}
          fill="transparent"
          strokeWidth="6"
          strokeDasharray={`${percent} ${100 - percent}`}
          strokeDashoffset="25"
        />
        <text
          x="50%"
          y="51.5%"
          dominantBaseline="middle"
          textAnchor="middle"
          className="percent-text"
        >
          <tspan className="percent-number">{percent}</tspan>
          <tspan dx="0.5" dy="2" className="percent-sign">
            {'%'}
          </tspan>
        </text>
      </svg>
    </DonutChartSC>
  );
}

DonutChart.propTypes = {
  /** percent in percent for the donut-chart */
  percent: PropTypes.number.isRequired,
  /** size of the donut-chart */
  size: PropTypes.number,
};

DonutChart.defaultProps = {
  size: 40,
};
