/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-04-14 00:10:22
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const UserDownIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  .k-icon {
    color: ${(props) => props.theme.header.iconColor};
  }
`;

export const HeaderButtonsSC = styled.div`
  display: flex;
  height: ${variables.headerHeight}px;
  cursor: pointer;

  &:hover {
    ${UserDownIconContainer} {
      .k-icon {
        color: ${(props) => props.theme.header.iconHoverColor};
      }
    }
  }

  .user-dropdown {
    display: grid !important;
    gap: 10px;
    grid-auto-flow: column;
    padding-right: ${variables.pageContentPadding}px;
    align-items: center;

    .menu {
      right: 12px;
      left: unset;

      .item {
        .k-icon {
          transform: translate(-6px, 3px);
          margin-right: 6px;
          margin-left: 3px;
        }
      }
    }
  }
`;

export const UsernameContainer = styled.div`
  display: flex;
  padding: 8px 0;
  padding-left: ${variables.pageContentPadding}px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${(props) => props.theme.dividerColor};
  transition: all 250ms ease;
`;
