/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-10 22:09:30
 * @modify date 2021-03-18 18:27:43
 */

export const modalList = {
  CREATE_TICKET_MODAL: 'CREATE_TICKET_MODAL',
  EDIT_TICKET_MODAL: 'EDIT_TICKET_MODAL',
};
