/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-05 15:25:13
 * @modify date 2021-03-05 15:25:13
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  ProjectLogoSC,
  DropRoot,
  DropInput,
  DropBox,
  UploadTextContainer,
  UploadText,
  ImagePreview,
} from './ProjectLogo.style';

import Loading from 'components/common/loading/Loading';
import Icon from 'components/common/icon/Icon';

export default function ProjectLogo({ loading, imgUrl, onAdd }) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    onAdd(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/jpeg, image/png',
  });

  return (
    <ProjectLogoSC>
      <DropRoot {...getRootProps()}>
        <DropInput {...getInputProps()} />

        <DropBox isDragActive={isDragActive}>
          {loading ? <Loading /> : null}
          {imgUrl ? <ImagePreview src={imgUrl} alt="project logo" /> : null}
        </DropBox>

        <UploadTextContainer>
          <Icon name="file_upload" iconTheme="outlined" isDark size={26} />
          <UploadText>{t('upload_project_avatar')}</UploadText>
        </UploadTextContainer>
      </DropRoot>
    </ProjectLogoSC>
  );
}

ProjectLogo.propTypes = {
  /** image url after uploading */
  loading: PropTypes.bool.isRequired,
  /** image url after uploading */
  imgUrl: PropTypes.string,
  /** triggers when user adds an image */
  onAdd: PropTypes.func.isRequired,
};

ProjectLogo.defaultProps = {
  imgUrl: '',
};
