/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 09:57:22
 * @modify date 2021-01-06 09:57:22
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxSC, CheckboxInput, CheckboxLabel } from './Checkbox.style';

export default function Checkbox({ id, checked, label, onChange }) {
  return (
    <CheckboxSC data-testid="checkbox">
      <CheckboxInput
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
    </CheckboxSC>
  );
}
Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  label: '',
  onChange: () => {},
};
