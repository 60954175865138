/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 22:49:42
 * @modify date 2021-02-17 23:26:22
 */

import styled from 'styled-components';

export const PrivacyPolicySC = styled.div``;

export const Heading1 = styled.h1``;
export const Heading2 = styled.h2``;
export const Para = styled.p``;
export const Link = styled.a``;
export const List = styled.ul``;
export const ListItem = styled.li``;
