/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-06 22:34:49
 * @modify date 2021-03-06 22:34:49
 */

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Config from 'app.config';

if (Config.env !== 'local') {
  Sentry.init({
    dsn: `https://${Config.sentry.key}.ingest.sentry.io/${Config.sentry.id}`,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
