/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:20
 * @modify date 2021-01-10 22:09:12
 */

import {
  UPDATE_THEME,
  UPDATE_ACTIVE_MODAL,
  TOGGLE_APP_DRAWER,
  UPDATE_PAGE_BOTTOM,
} from 'types';

export const updateTheme = (themeName) => (dispatch) => {
  dispatch({
    type: UPDATE_THEME,
    payload: themeName,
  });
};

export const updateActiveModal = (modalName) => (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_MODAL,
    payload: modalName,
  });
};

export const toggleAppDrawer = () => (dispatch) => {
  dispatch({
    type: TOGGLE_APP_DRAWER,
  });
};

export const updatePageBottom = (isOnPageBottom) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_BOTTOM,
    payload: isOnPageBottom,
  });
};
