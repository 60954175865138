/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-08 13:32:29
 */

import styled from 'styled-components';

export const LoadingSC = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.gap}px;

  .loading-img {
    height: 40px;
  }
`;
