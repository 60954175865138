/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HighlightsSC, CarouselContainer } from './Highlights.style';

import Card from 'components/common/card/Card';
import CardHeader from 'components/common/card/card-header/CardHeader';
import CardBody from 'components/common/card/card-body/CardBody';
import ItemCarousel from 'components/common/item-carousel/ItemCarousel';
import HighlightCard from 'components/common/highlight-card/HighlightCard';
import NoData from 'components/common/no-data/NoData';

export default function Highlights({ highlights, isAnimated }) {
  const { t } = useTranslation();

  return (
    <HighlightsSC data-testid="highlights">
      <Card>
        <CardHeader bold>{t('highlights')}</CardHeader>
        <CardBody>
          <CarouselContainer>
            {highlights.length ? (
              <ItemCarousel
                cards={highlights.map((highlight, index) => (
                  <HighlightCard
                    index={index}
                    isAnimated={isAnimated}
                    key={highlight.id}
                    keyValue={highlight.id}
                    header={highlight.header}
                    type={highlight.type}
                    data={highlight.data}
                  />
                ))}
              />
            ) : (
              <NoData />
            )}
          </CarouselContainer>
        </CardBody>
      </Card>
    </HighlightsSC>
  );
}

Highlights.propTypes = {
  highlights: PropTypes.array.isRequired,
  isAnimated: PropTypes.bool,
};

Highlights.defaultProps = {
  isAnimated: false,
};
