/* eslint-disable react/no-danger */
/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-20 00:16:02
 * @modify date 2021-02-20 00:16:02
 */

import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Image } from './EditorjsBlock.style';

import { createMarkup } from 'utils/common-util';

export default function EditorjsBlock({ block }) {
  if (block.type === 'paragraph') {
    return <div dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
  } else if (block.type === 'header') {
    if (block.data.level === 1) {
      return <h1 dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
    } else if (block.data.level === 2) {
      return <h2 dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
    } else if (block.data.level === 3) {
      return <h3 dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
    } else if (block.data.level === 4) {
      return <h4 dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
    } else if (block.data.level === 5) {
      return <h5 dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
    } else if (block.data.level === 6) {
      return <h6 dangerouslySetInnerHTML={createMarkup(block.data.text)} />;
    }
  } else if (block.type === 'list') {
    if (block.data.style === 'ordered') {
      return (
        <ol>
          {block.data.items.map((item) => (
            <li key={uuidv4()} dangerouslySetInnerHTML={createMarkup(item)} />
          ))}
        </ol>
      );
    } else if (block.data.style === 'unordered') {
      return (
        <ul>
          {block.data.items.map((item) => (
            <li key={uuidv4()} dangerouslySetInnerHTML={createMarkup(item)} />
          ))}
        </ul>
      );
    }
  } else if (block.type === 'image') {
    return (
      <Image
        src={block.data.file.url}
        alt={block.data.file.caption}
        stretched={block.data.stretched}
        withBorder={block.data.withBorder}
        withBackground={block.data.withBackground}
      />
    );
  }

  return null;
}
EditorjsBlock.propTypes = {
  /** block element from editorjs */
  block: PropTypes.object.isRequired,
};
