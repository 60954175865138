/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 22:49:19
 * @modify date 2021-02-17 22:49:19
 */

import React from 'react';

import {
  PrivacyPolicySC,
  Heading1,
  Heading2,
  Para,
  Link,
  List,
  ListItem,
} from './PrivacyPolicy.style';

export default function PrivacyPolicy() {
  return (
    <PrivacyPolicySC>
      <Heading1>Privacy Policy for Plateau Inc</Heading1>

      <Para>
        At Plateau, accessible from Plateau.ai, one of our main priorities is
        the privacy of our visitors. This Privacy Policy document contains types
        of information that is collected and recorded by Plateau and how we use
        it.
      </Para>

      <Para>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us. Our Privacy Policy was
        generated with the help of{' '}
        <Link
          href="https://www.gdprprivacypolicy.net/"
          target="_blank"
          rel="noreferrer"
        >
          GDPR Privacy Policy Generator from GDPRPrivacyPolicy.net
        </Link>
      </Para>

      <Heading2>General Data Protection Regulation (GDPR)</Heading2>
      <Para>We are a Data Controller of your information.</Para>

      <Para>
        Plateau Inc legal basis for collecting and using the personal
        information described in this Privacy Policy depends on the Personal
        Information we collect and the specific context in which we collect the
        information:
      </Para>
      <List>
        <ListItem>Plateau Inc needs to perform a contract with you</ListItem>
        <ListItem>You have given Plateau Inc permission to do so</ListItem>
        <ListItem>
          Processing your personal information is in Plateau Inc legitimate
          interests
        </ListItem>
        <ListItem>Plateau Inc needs to comply with the law</ListItem>
      </List>

      <Para>
        Plateau Inc will retain your personal information only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your information to the extent necessary to comply with
        our legal obligations, resolve disputes, and enforce our policies.
      </Para>

      <Para>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. If you wish to be informed what Personal
        Information we hold about you and if you want it to be removed from our
        systems, please contact us.
      </Para>

      <Para>
        In certain circumstances, you have the following data protection rights:
      </Para>
      <List>
        <ListItem>
          The right to access, update or to delete the information we have on
          you.
        </ListItem>
        <ListItem>The right of rectification.</ListItem>
        <ListItem>The right to object.</ListItem>
        <ListItem>The right of restriction.</ListItem>
        <ListItem>The right to data portability</ListItem>
        <ListItem>The right to withdraw consent</ListItem>
      </List>

      <Heading2>Log Files</Heading2>

      <Para>
        Plateau follows a standard procedure of using log files. These files log
        visitors when they visit websites. All hosting companies do this and a
        part of hosting services&apos; analytics. The information collected by
        log files include internet protocol (IP) addresses, browser type,
        Internet Service Provider (ISP), date and time stamp, referring/exit
        pages, and possibly the number of clicks. These are not linked to any
        information that is personally identifiable. The purpose of the
        information is for analyzing trends, administering the site, tracking
        users&apos; movement on the website, and gathering demographic
        information.
      </Para>

      <Heading2>Cookies and Web Beacons</Heading2>

      <Para>
        Like any other website, Plateau uses &apos;cookies&apos;. These cookies
        are used to store information including visitors&apos; preferences, and
        the pages on the website that the visitor accessed or visited. The
        information is used to optimize the users&apos; experience by
        customizing our web page content based on visitors&apos; browser type
        and/or other information.
      </Para>

      <Para>
        For more general information on cookies, please read{' '}
        <a
          href="https://www.cookieconsent.com/what-are-cookies/"
          target="_blank"
          rel="noreferrer"
        >
          &quot;What Are Cookies&quot;
        </a>
        .
      </Para>

      <Heading2>Google DoubleClick DART Cookie</Heading2>

      <Para>
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit to www.website.com and other sites on the internet. However,
        visitors may choose to decline the use of DART cookies by visiting the
        Google ad and content network Privacy Policy at the following URL –{' '}
        <a
          href="https://policies.google.com/technologies/ads"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/technologies/ads
        </a>
      </Para>

      <Heading2>Our Advertising Partners</Heading2>

      <Para>
        Some of advertisers on our site may use cookies and web beacons. Our
        advertising partners are listed below. Each of our advertising partners
        has their own Privacy Policy for their policies on user data. For easier
        access, we hyperlinked to their Privacy Policies below.
      </Para>

      <List>
        <ListItem>
          <Para>Google</Para>
          <Para>
            <a
              href="https://policies.google.com/technologies/ads"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/technologies/ads
            </a>
          </Para>
        </ListItem>
      </List>

      <Heading2>Privacy Policies</Heading2>

      <Para>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of Plateau.
      </Para>

      <Para>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on Plateau, which are sent directly
        to users&apos; browser. They automatically receive your IP address when
        this occurs. These technologies are used to measure the effectiveness of
        their advertising campaigns and/or to personalize the advertising
        content that you see on websites that you visit.
      </Para>

      <Para>
        Note that Plateau has no access to or control over these cookies that
        are used by third-party advertisers.
      </Para>

      <Heading2>Third Party Privacy Policies</Heading2>

      <Para>
        Plateau&apos;s Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.{' '}
      </Para>

      <Para>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers&apos; respective
        websites.
      </Para>

      <Heading2>Children&apos;s Information</Heading2>

      <Para>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </Para>

      <Para>
        Plateau does not knowingly collect any Personal Identifiable Information
        from children under the age of 13. If you think that your child provided
        this kind of information on our website, we strongly encourage you to
        contact us immediately and we will do our best efforts to promptly
        remove such information from our records.
      </Para>

      <Heading2>Online Privacy Policy Only</Heading2>

      <Para>
        Our Privacy Policy created at GDPRPrivacyPolicy.net) applies only to our
        online activities and is valid for visitors to our website with regards
        to the information that they shared and/or collect in Plateau. This
        policy is not applicable to any information collected offline or via
        channels other than this website.
      </Para>

      <Heading2>Consent</Heading2>

      <Para>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </Para>
    </PrivacyPolicySC>
  );
}
