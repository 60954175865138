/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:37:05
 */

import styled from 'styled-components';

export const TagSC = styled.div`
  display: flex;
  height: 21px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background: ${(props) => props.theme.white};
  border: ${(props) => props.theme.tag.border};
  border-radius: 100px;
  color: ${(props) => props.theme.blackText};
  font-size: 10px;
  text-transform: uppercase;
  transition: all 250ms ease;
`;
