/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-20 00:31:30
 * @modify date 2021-03-03 18:34:48
 */

import styled from 'styled-components';

export const Image = styled.img`
  display: flex;
  max-width: 100%;
  width: ${(props) => (props.stretched ? '100%' : 'auto')};
  border: ${(props) => (props.withBorder ? '1px' : '0')} solid
    ${(props) => props.theme.black};
  background-color: ${(props) =>
    props.withBackground ? props.theme.white : 'none'};
`;
