/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:13:41
 * @modify date 2020-12-31 00:13:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import { useSelector } from 'react-redux';

import { SwimlaneSC, HeaderContainer, CardList } from './Swimlane.style';

import Loading from 'components/common/loading/Loading';

export default function Swimlane({
  id,
  header,
  children,
  list,
  setList,
  groupName,
  loading,
  onAdd,
  onScroll,
}) {
  const isOnPageBottom = useSelector(
    (state) => state.commonStates.isOnPageBottom,
  );

  return (
    <SwimlaneSC data-testid="swimlane">
      <HeaderContainer>{header}</HeaderContainer>

      <CardList showScroll={isOnPageBottom} onScroll={onScroll}>
        <ReactSortable
          id={id}
          className="react-sortable"
          list={list}
          setList={setList}
          group={groupName}
          delayOnTouchOnly
          animation={100}
          forceFallback
          handle=".handle"
          onAdd={onAdd}
        >
          {children}
        </ReactSortable>
        {loading ? <Loading /> : null}
      </CardList>
    </SwimlaneSC>
  );
}

Swimlane.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  list: PropTypes.array.isRequired,
  setList: PropTypes.func.isRequired,
  groupName: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
};

Swimlane.defaultProps = {
  groupName: 'swimlane',
};
