/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-04-19 20:26:30
 */

import styled, { css } from 'styled-components';

import { zIndex } from 'variables';

import PEN from 'assets/images/cursors/pen.svg';

export const TimeRangeSC = styled.div`
  display: grid;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
  height: 40px;
  gap: 9px;
  grid-auto-flow: column;
`;

export const Icons = styled.div`
  display: grid;
  grid-auto-flow: column;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-left: -3px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow2};
  z-index: ${(props) => (props.top ? zIndex.timeRangeIcons : 'unset')};

  ${(props) =>
    props.hidden &&
    css`
      visibility: hidden;
    `}

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const IconContainerLink = styled(IconContainer)`
  position: relative;
  cursor: url('${PEN}'), pointer;
`;

export const TextContainer = styled.div`
  display: grid;
  gap: 2px;
`;

export const Range = styled.div`
  display: flex;
  font-family: Lato;
  font-weight: bold;
  font-size: 13.4px;
  line-height: 17px;
`;

export const Duration = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(props) => props.theme.secondaryTextColor};
`;
