/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 20:14:51
 * @modify date 2021-01-12 17:31:40
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  CarouselSC,
  CardsContainer,
  Card,
  CardsInner,
  DotsContainer,
  Dot,
} from './Carousel.style';

let autoCompleteInterval;
let isHovered = false;

export default function Carousel({ autoplay, cards, onIndexChange }) {
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const handleAutoplay = () => {
    if (isHovered) {
      return;
    }

    setActiveCardIndex((index) => {
      if (index === cards.length - 1) {
        return 0;
      }
      return index + 1;
    });
  };

  useEffect(() => {
    if (autoplay !== 0) {
      autoCompleteInterval = setInterval(handleAutoplay, autoplay);
    }

    return () => {
      clearInterval(autoCompleteInterval);
    };
  }, []);

  useEffect(() => {
    onIndexChange(activeCardIndex);
  }, [activeCardIndex]);

  const handleMouseOver = () => {
    isHovered = true;
  };

  const handleMouseOut = () => {
    isHovered = false;
  };

  return (
    <CarouselSC
      data-testid="carousel"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <CardsContainer>
        <CardsInner count={cards.length} activeCardIndex={activeCardIndex}>
          {cards.map((card) => (
            <Card key={card.id}>{card.content}</Card>
          ))}
        </CardsInner>
      </CardsContainer>
      <DotsContainer>
        {Array.from(Array(cards.length).keys()).map((x) => (
          <Dot
            active={x === activeCardIndex}
            key={x}
            onClick={() => setActiveCardIndex(x)}
          />
        ))}
      </DotsContainer>
    </CarouselSC>
  );
}

Carousel.propTypes = {
  autoplay: PropTypes.number,
  cards: PropTypes.array,
  onIndexChange: PropTypes.func,
};

Carousel.defaultProps = {
  autoplay: 0,
  cards: [],
  onIndexChange: () => {},
};
