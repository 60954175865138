/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-23 18:44:13
 * @modify date 2021-02-23 18:44:20
 */

import styled from 'styled-components';

export const CloseIconSC = styled.div`
  display: flex;
  font-size: 35px;
  width: 23px;
  height: 23px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 250ms ease;

  &:hover {
    background: ${(props) => props.theme.blackA1};
  }
`;
