/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 21:36:13
 * @modify date 2021-03-10 21:36:13
 */

import axios from 'axios';
import { axiosInstance } from 'utils/api-util';

export const uploadFiles = (files) => {
  return axios.all(files.map((file) => uploadFile(file)));
};

export const uploadFile = (file) => {
  const newFormData = new FormData();
  newFormData.append('file', file);

  return axiosInstance({
    url: `upload_file`,
    method: 'POST',
    data: newFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
