/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-16 01:33:16
 * @modify date 2021-02-16 01:33:16
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { TabsContainer } from './TabRouter.style';

import Tabs from 'components/common/tabs/Tabs';

export default function TabRouter({ tabs, renders }) {
  return (
    <>
      <TabsContainer className="animate__animated animate__fadeIn">
        <Tabs tabs={tabs} />
      </TabsContainer>
      <Switch>
        {tabs.map((tab, index) => (
          <Route key={tab.id} exact path={tab.link}>
            {renders[index]}
          </Route>
        ))}
        <Route path="*">
          <Redirect to={tabs[0].link} />
        </Route>
      </Switch>
    </>
  );
}

TabRouter.propTypes = {
  tabs: PropTypes.array.isRequired,
  renders: PropTypes.array.isRequired,
};
