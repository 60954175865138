/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-31 16:38:13
 * @modify date 2021-03-31 16:56:03
 */

import styled from 'styled-components';

export const SearchEntityImageSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

export const EntityImage = styled.img`
  display: flex;
  border-radius: 100px;
  width: 28px;
`;

export const EntityLetterImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  color: ${(props) => props.theme.white};
  font-size: 16px;
  background-color: ${(props) => props.theme.primaryColor};
`;
