/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-19 10:37:05
 * @modify date 2021-03-19 10:37:05
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import {
  UpdateFooterSC,
  FooterButtonContainer,
  LikedUsers,
} from './UpdateFooter.style';

import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';
import Avatar from 'components/common/avatar/Avatar';

import CommentSection from 'components/layout/comment-section/CommentSection';

export default function UpdateFooter({
  update,
  onLikeClick,
  onNewCommentSubmit,
}) {
  const { t } = useTranslation();

  const [showCommentSection, setShowCommentSection] = useState(false);
  const userData = useSelector((state) => state.userStates.userData);

  return (
    <UpdateFooterSC>
      <FooterButtonContainer>
        <Popup
          on={update?.likes && update?.likes.length ? ['hover'] : []}
          hoverable
          trigger={
            <div>
              <Button
                buttonType="flat"
                size="small"
                leftIcon={
                  <Icon
                    size={15}
                    name="thumb_up"
                    iconTheme={
                      update.likes &&
                      update.likes.findIndex(
                        (likeBy) => likeBy.id === userData.id,
                      ) !== -1
                        ? 'filled'
                        : 'outlined'
                    }
                  />
                }
                text={
                  t('like') +
                  (update?.likes && update?.likes.length
                    ? ` (${update?.likes?.length})`
                    : '')
                }
                onClick={onLikeClick}
              />
            </div>
          }
          content={
            <LikedUsers>
              {update.likes
                ? update.likes.map((user) => (
                    <Avatar
                      key={user.id}
                      title={user.name}
                      username={user.name}
                      img={user.img}
                      imgSize={29}
                      link={`/user/${user.id}`}
                    />
                  ))
                : null}
            </LikedUsers>
          }
        />
        <Button
          buttonType="flat"
          size="small"
          leftIcon={<Icon size={15} name="chat_bubble_outline" />}
          text={
            t('comment') +
            (update?.comments && update?.comments.length
              ? ` (${update?.comments?.length})`
              : '')
          }
          onClick={() => setShowCommentSection((old) => !old)}
        />
      </FooterButtonContainer>

      {showCommentSection ? (
        <CommentSection
          comments={update?.comments ?? []}
          onNewCommentSubmit={onNewCommentSubmit}
        />
      ) : null}
    </UpdateFooterSC>
  );
}

UpdateFooter.propTypes = {
  update: PropTypes.object.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onNewCommentSubmit: PropTypes.func.isRequired,
};
