/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:32:51
 */

import styled from 'styled-components';

export const CardFooterSC = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 44px;
  border-top: 1px solid ${(props) => props.theme.dividerColor};
  padding: 0 16px;
`;
