/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-02-04 20:41:51
 */

import styled from 'styled-components';

import { variables } from 'variables';

export const EntityNameSC = styled.div`
  display: grid;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
  gap: 11.2px;
  grid-auto-flow: column;
`;

export const EntityLabel = styled.div`
  display: flex;
  font-size: 12.8px;
  color: ${(props) => props.theme.secondaryTextColor};
`;

export const TextContainer = styled.div`
  display: grid;
  gap: 4px;
`;

export const TextSC = styled.div`
  display: flex;
  font-family: Lato;
  font-weight: bold;
  font-size: ${(props) => variables.entityName[props.size].titleFontSize}px;
  line-height: ${(props) =>
    variables.entityName[props.size].titleFontSize + 2}px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isFooter ? 1 : 2)};
  -webkit-box-orient: vertical;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.black};
  }
`;

export const FooterSC = styled.div`
  display: flex;
  font-size: ${(props) => variables.entityName[props.size].footerFontSize}px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.secondaryTextColor};
`;
