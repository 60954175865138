/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-12 10:26:24
 * @modify date 2021-04-12 10:26:24
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  PasswordStrengthSC,
  Label,
  Bar,
  BarMeter,
  Message,
} from './PasswordStrength-style';

import {
  calculatePasswordStrength,
  getPasswordLabelFromScore,
  getPasswordColorFromLabel,
} from 'utils/password-util';

/**
 * Renders the password strength bar for the password input component
 */
export default function PasswordStrength({ password }) {
  const [score, setScore] = useState(0);
  const [label, setLabel] = useState('');
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    const strength = calculatePasswordStrength(password);
    setScore(strength.score);
    setMessage(strength.message);
  }, [password]);

  useEffect(() => {
    setLabel(getPasswordLabelFromScore(score));
  }, [score]);

  useEffect(() => {
    setColor(getPasswordColorFromLabel(label));
  }, [label]);

  return (
    <PasswordStrengthSC>
      <Label>
        <strong>Password Strength: </strong> &nbsp;{label}
      </Label>
      <Bar>
        <BarMeter style={{ width: score * 25 + '%', background: color }} />
      </Bar>
      <Message>{message}</Message>
    </PasswordStrengthSC>
  );
}

PasswordStrength.propTypes = {
  /** password text on which the strength will be calculated */
  password: PropTypes.string.isRequired,
};
