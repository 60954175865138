/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { TagSC } from './Tag.style';

export default function Tag({ children, title }) {
  return (
    <TagSC data-testid="tag" title={title ?? children}>
      {children}
    </TagSC>
  );
}

Tag.propTypes = {
  /** content of the tag */
  children: PropTypes.string,
  title: PropTypes.string,
};

Tag.defaultProps = {
  title: '',
  children: '',
};
