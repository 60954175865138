/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-17 21:40:39
 * @modify date 2021-04-18 00:09:48
 */

import { axiosInstance } from 'utils/api-util';

import { truncate } from 'utils/common-util';

export function getUsers(term = '') {
  return axiosInstance({
    url: 'entity/search',
    params: {
      term,
      attribute: 'assignee',
      page: 0,
    },
  });
}

export function parseContributors(contributors) {
  return contributors.map((contributor) => ({
    id: contributor.id,
    img: contributor.img,
    name: truncate(contributor.name, 14),
    description:
      contributor.entity === 'team'
        ? contributor.entity
        : truncate(contributor.designation, 10),
  }));
}

export function updateAssignement(ticket, newUser) {
  let formData = new FormData();

  formData.append('id', ticket.id);

  formData.append('assignee', newUser.id);

  return axiosInstance({
    method: 'PUT',
    url: `entity/ticket`,
    data: formData,
  });
}
