/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-14 01:10:04
 * @modify date 2021-03-14 01:10:04
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DashboardFilter from './DashboardFilter';

import { getSearchData } from './DashboardFilter.service';

import Config from 'app.config';

let delayTimer;

export default function DashboardFilterContainer({ onFilterIdsChange }) {
  const [touched, setTouched] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [results, setResults] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [filters, setFilters] = useState([]);

  const performSearch = (appendResults = true) => {
    if (touched) {
      setInternalLoading(true);
      getSearchData(searchValue, page).then((response) => {
        if (appendResults) {
          setResults((old) => [...old, ...response?.data]);
        } else {
          setResults(response?.data);
        }
        setHasMore(response?.hasMore);
        setLoading(false);
        setInternalLoading(false);
      });
    }
  };

  const getData = () => {
    if (touched) {
      setInternalLoading(true);
      clearTimeout(delayTimer);

      delayTimer = setTimeout(() => {
        performSearch(false);
      }, Config.searchDelay);
    }
  };

  const handleSearchValueChange = () => {
    if (!showPopup && touched) {
      setShowPopup(true);
    }
    getData();
  };

  useEffect(handleSearchValueChange, [searchValue, touched]);

  useEffect(performSearch, [page]);

  useEffect(() => {
    onFilterIdsChange(filters.map((filter) => filter.id));
  }, [filters]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleLinkClick = (result) => {
    // Check for existing filters
    const filter = filters.find((filter) => filter.id === result.id);

    if (filter) {
      return;
    }

    setFilters((old) => [...old, result]);
    setSearchValue('');
  };

  const handleRemoveFilter = (id) => {
    setFilters((old) => {
      return old.filter((oldItem) => oldItem.id !== id);
    });
  };

  const handleNextPage = () => {
    if (hasMore && !internalLoading) {
      setPage((old) => old + 1);
    }
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom) {
      handleNextPage();
    }
  };

  const handleSearchFocus = () => {
    setTouched(true);
    handleSearchValueChange();
  };

  return (
    <DashboardFilter
      filters={filters}
      showPopup={showPopup}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      loading={loading}
      pageLoading={hasMore}
      searchResults={results}
      onPopupClose={handlePopupClose}
      onLinkClick={handleLinkClick}
      onScroll={handleScroll}
      onSearchFieldFocus={handleSearchFocus}
      onRemoveFilter={handleRemoveFilter}
    />
  );
}

DashboardFilterContainer.propTypes = {
  onFilterIdsChange: PropTypes.func.isRequired,
};
