/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-09 14:28:54
 * @modify date 2021-01-19 18:25:35
 */

import styled from 'styled-components';
import { zIndex } from 'variables';

export const OverlaySC = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndex.overlay};
  background: ${(props) =>
    props.transparent ? 'none' : props.theme.overlayBackground};
`;
