/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 21:39:31
 * @modify date 2020-12-24 22:18:36
 */

import styled from 'styled-components';

export const ProjectsSC = styled.div`
  display: grid;
  gap: 8px;
  flex: 1;
`;

export const AccordionHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 150px;
  align-items: center;
  justify-items: start;
  flex: 1;
  animation-delay: ${(props) => props.index * 100}ms;
`;

export const AccordionBodyContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const PriorityBoardName = styled.div`
  display: grid;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
  gap: 11.2px;
  max-width: 250px;
  grid-auto-flow: column;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${(props) => props.theme.gradiantBackground};
  border: ${(props) => props.theme.gradiantBackgroundBorder};
  box-shadow: none;
  height: 56px;
  width: 56px;
  transition: all 250ms ease;
`;

export const PriorityBoardTitle = styled.div`
  display: flex;
  font-family: Lato;
  font-weight: bold;
  font-size: 18px;
`;
