/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import styled from 'styled-components';

export const UpdatesSC = styled.div`
  flex: 1;
  display: grid;
  gap: 16px;
`;
