/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 18:17:17
 * @modify date 2021-01-19 18:17:17
 */

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { MenuSC, Option, OptionLabel, OptionIcon } from './Menu.style';

import Icon from 'components/common/icon/Icon';

import { variables } from 'variables';

export default function Menu({ position, options, onOptionClick }) {
  const [menuRoot, setMenuRoot] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [selectedInnerMenu, setSelectedInnerMenu] = useState(null);
  const [innerMenuPosition, setInnerMenuPosition] = useState({ x: 10, y: 10 });

  useEffect(() => {
    setMenuRoot(document.getElementById('menu-root'));
  }, []);

  const handleHeightOffset = () => {
    if (
      variables.menu.height * options.length + position.y >
      window.innerHeight
    ) {
      setIsOverflowing(true);
    }
  };

  useEffect(handleHeightOffset, []);

  const handleOptionClick = (option, index) => {
    if (option.options) {
      setSelectedInnerMenu((old) => {
        if (old) {
          return null;
        }
        return option;
      });
      setInnerMenuPosition({
        x: position.x - variables.menu.width,
        y: position.y + variables.menu.height * index,
      });
    } else {
      onOptionClick(option);
    }
  };

  if (!menuRoot) return null;

  return ReactDOM.createPortal(
    <MenuSC
      isOpen={open}
      top={position.y}
      right={window.innerWidth - position.x}
      isOverflowing={isOverflowing}
    >
      {options.map((option, index) => (
        <Option
          key={option.key}
          onClick={() => handleOptionClick(option, index)}
        >
          <OptionLabel>{option.label}</OptionLabel>
          {option.options ? (
            <>
              <OptionIcon>
                <Icon name="arrow_right" size={18} isDark />
              </OptionIcon>
            </>
          ) : null}
        </Option>
      ))}
      {selectedInnerMenu ? (
        <Menu
          options={selectedInnerMenu.options}
          position={innerMenuPosition}
        />
      ) : null}
    </MenuSC>,
    menuRoot,
  );
}
Menu.propTypes = {
  position: PropTypes.object,
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
};

Menu.defaultProps = {
  position: { x: 10, y: 10 },
  options: [],
  onOptionClick: () => {},
};
