/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 15:14:48
 * @modify date 2021-01-29 21:50:34
 */

import axios from 'axios';

import Config from 'app.config';

import { logout, getToken } from 'utils/auth-util';
import { getCurrentWorkspace } from 'utils/workspace-util';

export const axiosInstance = axios.create({
  baseURL: Config.herokuBaseUrl,
  timeout: Config.apiTimeout,
  method: 'GET',
  headers: {
    Authorization: getToken(),
  },
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = getToken();

  if (config.skipWorkspaceId === true) {
    return config;
  }

  const workspace = getCurrentWorkspace();

  if (config.method.toLowerCase() === 'get') {
    if (!config.params) {
      config.params = {};
    }
    config.params.workspace_id = workspace?.id ?? '';
  } else {
    config.data.append('workspace_id', workspace?.id ?? '');
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      logout();
    }
    return Promise.resolve({ error });
  },
);

export const axiosInstanceNoAuth = axios.create({
  baseURL: Config.herokuBaseUrl,
  timeout: Config.apiTimeout,
  method: 'GET',
});
