/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-07 22:16:49
 * @modify date 2021-01-07 22:16:49
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { BreadcrumbsSC, LinkSC, Arrow, Label } from './Breadcrumbs.style';

import Icon from 'components/common/icon/Icon';

export default function Breadcrumbs({ links }) {
  return (
    <BreadcrumbsSC>
      {links.map((link) => (
        <LinkSC key={link.link + link.label}>
          <Arrow>
            <Icon name="keyboard_arrow_right" size={21} />
          </Arrow>
          <Label
            link={link.link ? true : false}
            to={link.link ?? ''}
            as={link.link ? Link : 'div'}
          >
            {link.label}
          </Label>
        </LinkSC>
      ))}
    </BreadcrumbsSC>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
};
