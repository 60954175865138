/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:56:01
 * @modify date 2021-04-23 17:51:43
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

export const RegisterSC = styled.div`
  flex: 1;
  display: flex;
  background: ${(props) => props.theme.auth.backgroundColor};
  min-height: 100vh;
`;

export const BackButtonContainer = styled(Link)`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 999;

  .k-button {
    .k-button-text {
      color: ${(props) => props.theme.blackText2};
    }
    .k-button-icon {
      .k-icon {
        color: ${(props) => props.theme.blackText2};
      }
    }
  }
`;

export const LeftSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.auth.form.boxShadow};
  padding: 0 48px;
  border-radius: 8px;
`;

export const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 320px;
  justify-content: center;
`;

export const FormHeading = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 22.4px;
  line-height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 95px;
`;

export const FormSM = styled(Form)`
  margin-top: 30px;

  #firstName,
  #lastName {
    width: 100% !important;
  }
`;

export const RegisterButtonContainer = styled.div`
  flex: 1;
  display: grid;
  gap: 16px;
  margin: 16px 0;
`;

export const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  font-size: 13.6px;
  align-items: center;
  justify-content: center;
  margin: 16px 0 48px;
`;

export const AlreadyMemberText = styled.div`
  display: flex;
  color: ${(props) => props.theme.blackText2};
`;

export const LoginLink = styled(Link)`
  display: flex;
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const TermsContainer = styled.div`
  font-size: 10px;
  position: absolute;
  bottom: 6px;
  left: 12px;
  width: 400px;
  color: ${(props) => props.theme.blackText2};
`;
