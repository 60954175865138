/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-12 23:55:32
 * @modify date 2021-03-12 23:55:32
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StatusSelection from './StatusSelection';

import Loading from 'components/common/loading/Loading';

import { getStatuses, updateTicketStatus } from './StatusSelection.service';

export default function StatusSelectionContainer({ ticket }) {
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    getStatuses().then((response) => {
      setStatuses(response?.data?.data ?? []);
      setLoading(false);
    });
  };

  useEffect(getData, []);

  const handleStatusChange = (statusId) => {
    updateTicketStatus(ticket.id, statusId).then(() => {
      location.reload();
    });
  };

  if (loading) {
    return <Loading gap={40} />;
  }

  return (
    <StatusSelection
      statuses={statuses}
      ticket={ticket}
      onStatusChange={handleStatusChange}
    />
  );
}

StatusSelectionContainer.propTypes = {
  ticket: PropTypes.object.isRequired,
};
