/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { CardHeaderSC } from './CardHeader.style';

/**
 * CardHeader renders header section of the card with dynamic child.
 */
export default function CardHeader({ children, bold }) {
  return (
    <CardHeaderSC data-testid="card-header" bold={bold}>
      {children}
    </CardHeaderSC>
  );
}

CardHeader.propTypes = {
  /** content of the header on the left side */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  bold: PropTypes.bool,
};

CardHeader.defaultProps = {
  bold: false,
};
