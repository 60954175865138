/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  TicketUpdateDataSC,
  HightlighCardsContainer,
} from './TicketUpdateData.style';

import Summary from 'components/common/summary/Summary';
import ItemCarousel from 'components/common/item-carousel/ItemCarousel';

export default function TicketUpdateData({
  description,
  attachments,
  itemCards,
}) {
  return (
    <TicketUpdateDataSC>
      <Summary summaryText={description} attachments={attachments} />
      <HightlighCardsContainer>
        <ItemCarousel cards={itemCards} />
      </HightlighCardsContainer>
    </TicketUpdateDataSC>
  );
}

TicketUpdateData.propTypes = {
  description: PropTypes.string,
  attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  itemCards: PropTypes.arrayOf(PropTypes.element),
};

TicketUpdateData.defaultProps = {
  description: '',
  attachments: [],
  itemCards: [],
};
