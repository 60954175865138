/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-17 17:04:01
 * @modify date 2021-04-17 17:11:12
 */

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { axiosInstance } from 'utils/api-util';

export function getEmptyEmailSlot() {
  return {
    id: uuidv4(),
    email: '',
    error: '',
  };
}

export function sendInvitations(emails) {
  return axios.all(emails.map((email) => sendInvitation(email)));
}

export function sendInvitation(email) {
  const formData = new FormData();
  formData.append('email', email);
  return axiosInstance({
    url: 'user/invite',
    method: 'POST',
    data: formData,
  });
}
