/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-15 23:09:27
 * @modify date 2021-01-17 22:51:13
 */

import styled from 'styled-components';

export const HeaderDropdownSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
`;

export const HeaderDropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid ${(props) => props.theme.dividerColor};
  height: 50px;
`;

export const HeaderText = styled.div`
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const HeaderDropdownBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 430px;
`;

export const HeaderDropdownRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};
  animation-delay: ${(props) => props.index * 100}ms;
`;

export const RowContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
`;

export const EntityImage = styled.img`
  display: flex;
  border-radius: 100px;
  height: 28px;
  width: 28px;
  margin-right: 12px;
`;

export const EntityImageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.2px;
  height: 28px;
  width: 28px;
  margin-right: 12px;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: ${(props) => props.theme.boxShadow1};

  .s-icon {
    margin: 0;
  }
`;

export const MessageContainer = styled.div`
  flex: 1;
  font-size: 13px;
  line-height: 15px;
`;

export const MessageItem = styled.span`
  color: ${(props) =>
    props.type === 'light' ? props.theme.blackText2 : props.theme.black};

  &:after {
    content: ' ';
  }
`;

export const Time = styled.div`
  display: flex;
  margin-left: 18px;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: ${(props) => props.theme.blackText2};
`;
