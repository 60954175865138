/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 01:46:55
 * @modify date 2020-12-24 01:46:55
 */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import User from './User';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getUserService, getBreadcrumbs, getTabs } from './User.service';

export default function UserContainer() {
  const { t } = useTranslation();
  const { userId } = useParams();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    getUserService(userId).then((response) => {
      setUserData(response?.data?.data);
      setLoading(false);
    });
  };

  useEffect(getData, [userId]);

  if (loading) {
    return <Loading />;
  }

  if (!userData) {
    return <NoData msg={t('no_user_found')} />;
  }

  return (
    <User
      data={userData}
      breadcrumbs={getBreadcrumbs(userData)}
      tabs={getTabs(userId)}
    />
  );
}
