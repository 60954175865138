/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  EntityNameSC,
  EntityLabel,
  TextContainer,
  TextSC,
  FooterSC,
} from './EntityName.style';

export default function EntityName({
  icon,
  entityName,
  title,
  link,
  footer,
  size,
}) {
  return (
    <EntityNameSC data-testid="entity-name">
      {icon}
      <TextContainer>
        <EntityLabel>{entityName}</EntityLabel>
        <TextSC
          title={title}
          size={size}
          isFooter={footer !== null ? true : false}
        >
          {link ? <Link to={link}>{title}</Link> : title}
        </TextSC>
        {footer ? (
          <FooterSC title={footer} size={size}>
            {footer}
          </FooterSC>
        ) : null}
      </TextContainer>
    </EntityNameSC>
  );
}

EntityName.propTypes = {
  icon: PropTypes.element,
  entityName: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  footer: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

EntityName.defaultProps = {
  icon: null,
  entityName: '',
  link: null,
  footer: '',
  size: 'medium',
};
