/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-23 16:13:02
 * @modify date 2021-01-23 16:14:22
 */

export const mapAPIEntityTypeWithTranslationKey = {
  project: 'project',
  feature: 'feature',
  task: 'task',
};
