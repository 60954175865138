/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';

import Tickets from './Tickets';

const tabs = [
  {
    id: 'projects',
    name: 'Projects',
    link: '/tickets/projects',
  },
  {
    id: 'updates',
    name: 'Updates',
    link: '/tickets/updates',
  },
];

export default function TicketsContainer() {
  return <Tickets tabs={tabs} />;
}
