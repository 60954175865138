/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  SummarySC,
  BlocksContainer,
  BlockContainer,
  Attachments,
  AttachmentHeading,
  AttachmentContainer,
} from './Summary.style';

import EditorjsBlock from 'components/common/editorjs-block/EditorjsBlock';
import AttachmentBlock from 'components/common/attachment-block/AttachmentBlock';

import { isJSONString } from 'utils/common-util';

export default function Summary({ summaryText, attachments, padding }) {
  const { t } = useTranslation();

  return (
    <SummarySC data-testid="summary" padding={padding}>
      <BlocksContainer>
        {isJSONString(summaryText)
          ? JSON.parse(summaryText)?.blocks?.map((block) => (
              <BlockContainer key={uuidv4()}>
                <EditorjsBlock block={block} />
              </BlockContainer>
            ))
          : summaryText}
      </BlocksContainer>

      {attachments && attachments.length ? (
        <Attachments>
          <AttachmentHeading>{t('attachments')}</AttachmentHeading>

          <AttachmentContainer>
            {attachments.map((attachment) => (
              <AttachmentBlock
                key={attachment.id}
                fileName={attachment.name ?? attachment.path}
                fileType={attachment.type}
                fileSize={attachment.size}
                fileUrl={attachment.url}
              />
            ))}
          </AttachmentContainer>
        </Attachments>
      ) : null}
    </SummarySC>
  );
}

Summary.propTypes = {
  /** Summary text to be displayed */
  summaryText: PropTypes.string,
  attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  padding: PropTypes.number,
};

Summary.defaultProps = {
  summaryText: '',
  attachments: [],
  padding: 0,
};
