/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-16 00:32:46
 * @modify date 2021-02-16 00:34:59
 */

import styled from 'styled-components';

export const EntityAccordionSC = styled.div``;

export const AccordionHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 150px;
  align-items: center;
  justify-items: start;
  flex: 1;
  animation-delay: ${(props) => props.index * 100}ms;
`;

export const AccordionBodyContainer = styled.div`
  flex: 1;
  display: flex;
`;
