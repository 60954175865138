/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-26 22:50:35
 * @modify date 2021-02-26 22:50:35
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import WorkspaceMenu from './WorkspaceMenu';

import { updateSelectedWorkspace, getProfileData } from 'actions/user-actions';

export default function WorkspaceMenuContainer() {
  const dispatch = useDispatch();

  const workspaceList = useSelector((state) => state.userStates.workspaces);
  const selectedWorkspace = useSelector(
    (state) => state.userStates.selectedWorkspace,
  );

  const handleWorkspaceChange = async (newWorkspace) => {
    await dispatch(updateSelectedWorkspace(newWorkspace));
    await dispatch(getProfileData(false));
    location.reload();
  };

  return (
    <WorkspaceMenu
      workspaceList={workspaceList}
      selectedWorkspace={selectedWorkspace}
      onWorkspaceChange={handleWorkspaceChange}
    />
  );
}
