/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:55:48
 * @modify date 2021-01-04 15:55:48
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Login from './Login';

import { loginService } from './Login.service';

import { setToken } from 'utils/auth-util';
import { links } from 'utils/navigation-util';

export default function LoginContainer({ location }) {
  const [rememberMeCheckbox, setRememberMeCheckbox] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRemeberMeToggle = () => {
    setRememberMeCheckbox((old) => !old);
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    loginService(event.target[0].value, event.target[1].value)
      .then((response) => {
        setToken(response.data.token);
        window.location = location?.state?.from ?? links.home.path;
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessages(error.response.data.non_field_errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Login
      rememberMeCheckbox={rememberMeCheckbox}
      onRememberMeToggle={handleRemeberMeToggle}
      onLoginSubmit={handleLoginSubmit}
      loading={loading}
      isError={isError}
      errorMessages={errorMessages}
    />
  );
}

LoginContainer.propTypes = {
  location: PropTypes.object,
};
