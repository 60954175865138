/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-13 15:22:51
 * @modify date 2021-01-13 23:37:50
 */

import { delay } from 'utils/common-util';
import { fakeChatReplies } from 'constants/dummy-api-responses';

const CONVERSATION = [
  {
    id: 1,
    sent: false,
    message: 'How can I help you?',
  },
];

export const getDefaultConversation = () => {
  return CONVERSATION;
};

export const getReply = (message) => {
  return delay(1500).then(() => getFakeReply(message));
};

const getFakeReply = (query) => {
  for (let reply in fakeChatReplies) {
    if (query.toLowerCase().search(reply) !== -1) {
      return fakeChatReplies[reply];
    }
  }
  return `Sorry couldn't help you with this query.`;
};
