/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 22:06:38
 * @modify date 2021-03-10 22:06:38
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  AttachmentGridSC,
  FileBox,
  FilePreview,
  ImagePreview,
  CloseIconContainer,
} from './AttachmentGrid.style';

import Icon from 'components/common/icon/Icon';
import CloseIcon from 'components/common/close-icon/CloseIcon';
import Loading from 'components/common/loading/Loading';

import { isImageType } from 'utils/file-util';

export default function AttachmentGrid({
  files,
  loading,
  onRemove,
  isAbsolute,
}) {
  const { t } = useTranslation();

  return (
    <AttachmentGridSC isAbsolute={isAbsolute ? 1 : 0}>
      {files.map((file) => (
        <FileBox key={file.id} onClick={(e) => e.stopPropagation()}>
          <FilePreview>
            {isImageType(file.type) ? (
              <ImagePreview src={file.url} alt={file.name} />
            ) : (
              <Icon name="attach_file" isDark size={30} />
            )}
          </FilePreview>
          <CloseIconContainer>
            <CloseIcon title={t('remove')} onClick={() => onRemove(file)} />
          </CloseIconContainer>
        </FileBox>
      ))}
      {loading ? (
        <FileBox>
          <Loading gap={40} />
        </FileBox>
      ) : null}
    </AttachmentGridSC>
  );
}

AttachmentGrid.propTypes = {
  files: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  isAbsolute: PropTypes.bool,
};

AttachmentGrid.defaultProps = {
  isAbsolute: false,
};
