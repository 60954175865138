/**
 * @author Paras Bansal
 * @email Paras.Bansal@rsystems.com
 * @create date 2020-10-19 19:18:54
 * @modify date 2021-04-13 18:58:19
 */

import styled from 'styled-components';

export const PasswordStrengthSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  margin: 5px 0;
`;

export const Label = styled.div`
  display: flex;
`;

export const Bar = styled.div`
  display: flex;
  height: 6px;
  background: #eee;
  border-radius: 6px;
  position: relative;
`;

export const BarMeter = styled.div`
  display: flex;
  height: 6px;
  position: absolute;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 0;
  left: 0;
  transition: all 0.3s;
`;

export const Message = styled.div`
  display: flex;
  font-size: 11px;
  color: ${(props) => props.theme.errorColor};
`;
