/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-03-19 10:39:06
 */

import styled from 'styled-components';

export const UpdateCardSC = styled.div`
  display: flex;
  width: 100%;
  animation-delay: ${(props) => props.index * 200}ms;
`;

export const UpdateHeaderLeft = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
`;

export const TimeInfo = styled.div`
  display: flex;
  color: ${(props) => props.theme.secondaryColor};
  font-size: 12px;
`;

export const BodyContainer = styled.div`
  display: grid;
  flex: 1;
  gap: 16px;
`;

export const BasicDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UpdateDataContainer = styled.div`
  display: flex;
  height: 160px;
`;
