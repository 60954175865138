/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  EntityOverviewAccordionSC,
  HeaderContainer,
  HeaderContent,
  ToggleButton,
  Body,
} from './EntityOverviewAccordion.style';

import ArrowButton from 'components/common/arrow-button/ArrowButton';

export default function EntityOverviewAccordion({
  header,
  children,
  hideToggleButton,
  isOpen,
  onToggle,
}) {
  return (
    <EntityOverviewAccordionSC
      data-testid="entity-overview-accordion"
      isOpen={isOpen}
    >
      <HeaderContainer onClick={onToggle}>
        <HeaderContent>{header}</HeaderContent>
        {hideToggleButton ? null : (
          <ToggleButton>
            <ArrowButton direction={isOpen ? 'up' : 'down'} />
          </ToggleButton>
        )}
      </HeaderContainer>
      <Body isOpen={isOpen}>{isOpen ? children : null}</Body>
    </EntityOverviewAccordionSC>
  );
}
EntityOverviewAccordion.propTypes = {
  /** header element with a container */
  header: PropTypes.element.isRequired,
  /** children element with a container */
  children: PropTypes.element.isRequired,
  /** hides the toggle button, it is generaly used when we have single entity */
  hideToggleButton: PropTypes.bool.isRequired,
  /** trigger when arrow button is clicked */
  onToggle: PropTypes.func.isRequired,
  /** tells if accordion is in open state or not */
  isOpen: PropTypes.bool,
};

EntityOverviewAccordion.defaultProps = {
  isOpen: false,
  hideToggleButton: false,
};
