/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import {
  HeaderSC,
  LogoContainer,
  ToggleButtonContainer,
} from './AppDrawerHeader.style';

import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';
import WorkspaceName from 'components/common/workspace-name/WorkspaceName';

import WorkspaceMenu from 'components/layout/app-drawer/app-drawer-header/workspace-menu/WorkspaceMenu.container';

import { zIndex } from 'variables';

export default function AppDrawerHeader({
  isAppDrawerOpen,
  selectedWorkspace,
  onToggleAppDrawer,
}) {
  return (
    <HeaderSC>
      <Popup
        trigger={
          <LogoContainer>
            <WorkspaceName workspaceName={selectedWorkspace?.name ?? 'A'} />
            <Icon name="keyboard_arrow_down" size={18} />
          </LogoContainer>
        }
        on="click"
        style={{
          zIndex: zIndex.workspaceMenu,
        }}
        position="bottom left"
        basic
        offset={[-6, -4]}
      >
        <WorkspaceMenu />
      </Popup>

      <ToggleButtonContainer isAppDrawerOpen={isAppDrawerOpen}>
        <Button
          leftIcon={
            <Icon
              name={isAppDrawerOpen ? 'keyboard_arrow_left' : 'menu'}
              size={isAppDrawerOpen ? 19 : 15}
            />
          }
          iconButton
          text=""
          rounded
          size="small"
          onClick={onToggleAppDrawer}
        />
      </ToggleButtonContainer>
    </HeaderSC>
  );
}

AppDrawerHeader.propTypes = {
  isAppDrawerOpen: PropTypes.bool.isRequired,
  selectedWorkspace: PropTypes.object.isRequired,
  onToggleAppDrawer: PropTypes.func.isRequired,
};
