/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSC, IconSC, TextSC } from './InversePrimaryButton.style';

export default function InversePrimaryButton({
  leftIcon,
  rightIcon,
  text,
  size,
  title,
  elevated,
  rounded,
  disabled,
  onClick,
}) {
  return (
    <ButtonSC
      data-testid="inverse-primary-button"
      size={size}
      elevated={elevated}
      rounded={rounded}
      title={title}
      className="k-inverse-primary-button"
      disabled={disabled}
      onClick={onClick}
    >
      {leftIcon ? <IconSC size={size}>{leftIcon}</IconSC> : null}
      {text ? <TextSC size={size}>{text}</TextSC> : null}
      {rightIcon ? <IconSC size={size}>{rightIcon}</IconSC> : null}
    </ButtonSC>
  );
}

InversePrimaryButton.propTypes = {
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  text: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
  title: PropTypes.string,
  elevated: PropTypes.bool,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

InversePrimaryButton.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  text: '',
  size: 'medium',
  title: '',
  elevated: false,
  rounded: false,
  disabled: false,
  onClick: () => {},
};
