/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 16:06:38
 * @modify date 2021-01-19 15:16:24
 */

import Config from 'app.config';
import axios from 'axios';

export function loginService(email, password) {
  let formData = new FormData();

  formData.append('email', email);
  formData.append('password', password);

  return axios({
    method: 'post',
    url: Config.herokuBaseUrl + `login/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
