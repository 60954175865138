/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-03 16:48:42
 * @modify date 2021-02-03 16:48:42
 */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';
import Error from 'components/common/error/Error';

import Project from 'components/layout/project/Project';
import Feature from 'components/layout/feature/Feature';
import Task from 'components/layout/task/Task';

import { updateActiveModal } from 'actions/common-actions';

import {
  getTicketService,
  generateTicketBreadcrumbs,
  getTabs,
  addTicketComment,
} from './Ticket.service';

import { modalList } from 'utils/modal-util';
import NewTicketContainer from 'components/layout/new-ticket/NewTicket.container';

function TicketContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { ticketKey } = useParams();

  const activeModal = useSelector((state) => state.commonStates.activeModal);

  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    getTicketService(ticketKey).then((tickets) => {
      setTicketData(tickets?.data?.data);
      setLoading(false);
    });
  };

  useEffect(getData, [ticketKey]);

  const handleTicketEdit = () => {
    dispatch(updateActiveModal(modalList.EDIT_TICKET_MODAL));
  };

  const handlePageHeaderOptionClick = (option) => {
    if (option.key === 'edit') {
      handleTicketEdit();
    }
  };

  const handleCommentSubmit = (description, attachments) => {
    addTicketComment(
      description,
      attachments,
      ticketData.id,
      ticketData.entity,
    ).then((response) => {
      console.log(response);
      getData();
    });
  };

  const renderTicketPage = () => {
    if (ticketData.entity === 'project') {
      return (
        <Project
          tabs={getTabs('project', ticketKey)}
          data={ticketData}
          breadcrumbs={generateTicketBreadcrumbs(ticketData)}
          onPageHeaderOptionClick={handlePageHeaderOptionClick}
          onCommentSubmit={handleCommentSubmit}
        />
      );
    } else if (ticketData.entity === 'feature') {
      return (
        <Feature
          tabs={getTabs('feature', ticketKey)}
          data={ticketData}
          breadcrumbs={generateTicketBreadcrumbs(ticketData)}
          onPageHeaderOptionClick={handlePageHeaderOptionClick}
          onCommentSubmit={handleCommentSubmit}
        />
      );
    } else if (ticketData.entity === 'task') {
      return (
        <Task
          data={ticketData}
          breadcrumbs={generateTicketBreadcrumbs(ticketData)}
          onPageHeaderOptionClick={handlePageHeaderOptionClick}
          onCommentSubmit={handleCommentSubmit}
        />
      );
    }
    return <Error>{t('something_went_wrong')}</Error>;
  };

  if (loading) {
    return <Loading gap={100} />;
  }

  if (!ticketData) {
    return <NoData msg="No ticket found!" />;
  }

  return (
    <>
      {activeModal === modalList.EDIT_TICKET_MODAL ? (
        <NewTicketContainer context="edit" data={ticketData} />
      ) : null}
      {renderTicketPage()}
    </>
  );
}

export default React.memo(TicketContainer);
