/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-17 16:43:34
 * @modify date 2021-04-17 16:43:34
 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import ToastMessage from 'components/common/toast-message/ToastMessage';

import InviteTeammates from './InviteTeammates';

import { links } from 'utils/navigation-util';

import {
  getEmptyEmailSlot,
  sendInvitations,
  sendInvitation,
} from './InviteTeammates.service';

export default function InviteTeammatesContainer() {
  const { t } = useTranslation();

  const [inviteLink, setInviteLink] = useState('');
  const [inviteEmails, setInviteEmails] = useState([getEmptyEmailSlot()]);
  const [inviteLoading, setInviteLoading] = useState(false);

  const selectedWorkspace = useSelector(
    (state) => state.userStates.selectedWorkspace,
  );

  useEffect(() => {
    if (!selectedWorkspace) {
      window.location = links.workspace.path;
    }
  }, [selectedWorkspace]);

  const getInviteLink = () => {
    sendInvitation('').then((response) => {
      const link = `${window.location.origin}/register?token?${response.data.token}`;
      setInviteLink(link);
    });
  };

  useEffect(getInviteLink, []);

  const handleInviteEmailChange = (value, index) => {
    setInviteEmails((old) => {
      old[index].email = value;
      return [...old];
    });
  };

  const handleAddNewInviteEmailSlot = () => {
    setInviteEmails((old) => {
      old.push(getEmptyEmailSlot());
      return [...old];
    });
  };

  const handleInviteSubmit = () => {
    if (inviteEmails.length < 1) {
      toast(
        <ToastMessage
          title={t('error')}
          // TODO: i18n integration
          message={t('Please Add at least one email address.')}
          status="error"
          isDarkText
        />,
      );
      return;
    }

    setInviteLoading(true);
    sendInvitations(inviteEmails.map((emailObj) => emailObj.email)).then(() => {
      toast(
        <ToastMessage
          title={t('success')}
          // TODO: i18n integration
          message={t('Email for invitation sent!')}
          status="success"
          isDarkText
        />,
      );
      setInviteEmails([getEmptyEmailSlot()]);
      setInviteLoading(false);
    });
  };

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <InviteTeammates
      workspaceName={selectedWorkspace.name}
      inviteLink={inviteLink}
      inviteEmails={inviteEmails}
      inviteLoading={inviteLoading}
      onInviteEmailChange={handleInviteEmailChange}
      onAddNewInviteEmailSlot={handleAddNewInviteEmailSlot}
      onInviteSubmit={handleInviteSubmit}
    />
  );
}
