/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-10 22:18:33
 * @modify date 2021-01-10 22:18:33
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, Button as ButtonSM } from 'semantic-ui-react';

import {
  NewTicketSC,
  SaveButtonContainer,
  TitleDescription,
  TabContainer,
  HighlightsContainer,
  HighlightHeader,
  HighlightBody,
  Label,
  Field,
} from './NewTicket.style';

import Loading from 'components/common/loading/Loading';
import Modal from 'components/common/modal/Modal';
import Input from 'components/common/input/Input';
import EditorContainer from 'components/common/editor-container/EditorContainer';
import Tabs from 'components/common/tabs/Tabs';
import Attachments from 'components/common/attachments/Attachments';
import ProjectLogo from 'components/common/project-logo/ProjectLogo';
import Icon from 'components/common/icon/Icon';

import { getIconByStatus } from 'utils/ticket-status-util';

export default function NewTicket({
  context,
  loading,
  saveLoading,
  viewLayout,
  roots,
  contributors,
  components,
  statuses,
  priorities,
  ticketTypes,

  formData,
  onFormDataChange,

  onFileAdd,
  onFileRemove,
  onProjectLogoAdd,

  tabs,
  activeTabId,
  onTabChange,
  onSearch,
  onModalClose,
  onFormSubmit,
  onTitleBlur,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      open
      headerText={context === 'edit' ? t('edit_ticket') : t('new_ticket')}
      onClose={onModalClose}
      modalFooter={
        <SaveButtonContainer>
          <ButtonSM
            primary
            className="c-button"
            loading={saveLoading}
            onClick={onFormSubmit}
          >
            {t('save')}
          </ButtonSM>
        </SaveButtonContainer>
      }
    >
      {loading ? (
        <Loading gap={100} />
      ) : (
        <NewTicketSC>
          <TitleDescription>
            <Input
              type="text"
              size="small"
              inputProps={{
                placeholder: t('title'),
                value: formData.title,
                onChange: (e) => {
                  onFormDataChange('title', e.target.value);
                },
                onBlur: onTitleBlur,
              }}
            />

            <Tabs
              isLink={false}
              onTabChange={onTabChange}
              activeTabId={activeTabId}
              tabs={tabs}
            />

            <TabContainer>
              {activeTabId === 'description' ? (
                <EditorContainer
                  editorProps={{
                    placeholder: t('description'),
                    data: formData.description,
                    onChange: (_, value) => {
                      onFormDataChange('description', value);
                    },
                  }}
                />
              ) : activeTabId === 'attachments' ? (
                <Attachments
                  loading={formData.fileLoading}
                  files={formData.files}
                  onAdd={onFileAdd}
                  onRemove={onFileRemove}
                />
              ) : null}
            </TabContainer>
          </TitleDescription>

          <HighlightsContainer>
            <HighlightHeader>{t('highlights')}</HighlightHeader>
            <HighlightBody>
              <Field>
                <Label>{t('type')}</Label>
                <Dropdown
                  placeholder={t('select_type')}
                  fluid
                  selection
                  options={ticketTypes.map((ticketType) => ({
                    ...ticketType,
                    text: t(ticketType.text),
                  }))}
                  value={formData.ticketType}
                  onChange={(_, { value }) => {
                    onFormDataChange('ticketType', value);
                  }}
                />
              </Field>
              {viewLayout === 'default' ? (
                <Field>
                  <Label>{t('root')}</Label>
                  <Dropdown
                    id="root"
                    className="custom-dropdown"
                    placeholder={t('select_root')}
                    fluid
                    search
                    selection
                    options={roots.map((root) => ({
                      key: root.id,
                      text: root.title,
                      image: root.img,
                      description: t(root.description),
                      value: root.id,
                    }))}
                    onSearchChange={onSearch}
                    value={formData.rootValue}
                    onChange={(_, { value }) => {
                      onFormDataChange('rootValue', value);
                    }}
                  />
                </Field>
              ) : null}
              <Field>
                <Label>{t('contributor')}</Label>
                <Dropdown
                  id="contributors"
                  placeholder={t('select_contributor')}
                  fluid
                  search
                  selection
                  options={contributors.map((contributor) => ({
                    key: contributor.id,
                    text: contributor.name,
                    image: { avatar: true, src: contributor.img },
                    description: contributor.description,
                    value: contributor.id,
                  }))}
                  onSearchChange={onSearch}
                  value={formData.contributorValue}
                  onChange={(_, { value }) => {
                    onFormDataChange('contributorValue', value);
                  }}
                />
              </Field>

              {viewLayout !== 'project' ? (
                <>
                  <Field>
                    <Label>{t('components')}</Label>
                    <Dropdown
                      id="components"
                      placeholder={t('select_components')}
                      className="multi-select-dropdown"
                      upward
                      fluid
                      search
                      multiple
                      selection
                      options={components}
                      value={formData.selectedComponents}
                      onSearchChange={onSearch}
                      onChange={(_, { value }) => {
                        onFormDataChange('selectedComponents', value);
                      }}
                    />
                  </Field>

                  <Field>
                    <Label>{t('status')}</Label>
                    <Dropdown
                      id="status"
                      placeholder={t('select_status')}
                      fluid
                      upward
                      selection
                      options={statuses.map((status) => ({
                        key: status.id,
                        text: status.tag,
                        image: (
                          <Icon
                            name={getIconByStatus(status.tag)}
                            isDark
                            size={17}
                          />
                        ),
                        value: status.id,
                      }))}
                      value={formData.statusValue}
                      onChange={(_, { value }) => {
                        onFormDataChange('statusValue', value);
                      }}
                    />
                  </Field>
                </>
              ) : null}

              <Field>
                <Label>{t('priority')}</Label>
                <Dropdown
                  id="priorities"
                  placeholder={t('select_priority')}
                  fluid
                  upward
                  selection
                  options={priorities}
                  value={formData.priorityValue}
                  onChange={(_, { value }) => {
                    onFormDataChange('priorityValue', value);
                  }}
                />
              </Field>

              {viewLayout === 'project' ? (
                <Field>
                  <Label>{t('project_avatar')}</Label>
                  <ProjectLogo
                    loading={formData.projectImageLoading}
                    imgUrl={formData.projectImage}
                    onAdd={onProjectLogoAdd}
                  />
                </Field>
              ) : null}
            </HighlightBody>
          </HighlightsContainer>
        </NewTicketSC>
      )}
    </Modal>
  );
}

NewTicket.propTypes = {
  context: PropTypes.oneOf(['create', 'edit']),
  loading: PropTypes.bool.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  viewLayout: PropTypes.string.isRequired,
  roots: PropTypes.array.isRequired,
  contributors: PropTypes.array.isRequired,
  components: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  priorities: PropTypes.array.isRequired,
  ticketTypes: PropTypes.array.isRequired,

  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,

  onFileAdd: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  onProjectLogoAdd: PropTypes.func.isRequired,

  tabs: PropTypes.array.isRequired,
  activeTabId: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onTitleBlur: PropTypes.func.isRequired,
};
