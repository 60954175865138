/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 21:39:31
 * @modify date 2021-02-26 17:45:56
 */

import { axiosInstance } from 'utils/api-util';

export function getProjectsService(entity = '', id = '') {
  return axiosInstance({
    url: `entity/projects`,
    params: {
      entity,
      id,
    },
  });
}
