/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-13 17:25:27
 */

import styled from 'styled-components';

export const ChatBlockSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.sent ? 'flex-end' : 'flex-start')};
  margin-top: ${(props) => (props.sent ? 24 : 12)}px;

  &:first-child {
    margin-top: 0;
  }
`;

export const Message = styled.div`
  margin-top: 6.4px;
  background: ${(props) =>
    props.sent ? props.theme.white : props.theme.primaryColor};
  color: ${(props) => (props.sent ? 'unset' : props.theme.primaryTextColor)};
  border: 1px solid ${(props) => (props.sent ? props.theme.blackA1 : 'none')};
  text-align: ${(props) => (props.sent ? 'right' : 'left')};
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 13px;
  width: 100%;
`;
