/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-22 14:49:27
 */

import styled from 'styled-components';

const iconThemeMap = {
  filled: 'Material Icons',
  outlined: 'Material Icons Outlined',
};

export const IconSC = styled.div`
  font-family: ${(props) => iconThemeMap[props.iconTheme]};
  font-weight: normal;
  font-style: normal;
  font-size: 19px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  transition: all 250ms ease;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  color: ${(props) =>
    props.isDark
      ? props.theme.icon.darkFontColor
      : props.theme.icon.lightFontColor};
  font-size: ${(props) => props.size}px;
`;
