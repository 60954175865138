/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { OpenTicketsSC, OpenTicketLabel } from './OpenTickets.style';

import NumberBubble from 'components/common/number-bubble/NumberBubble';

export default function OpenTickets({ openTicketsCount }) {
  const { t } = useTranslation();

  return (
    <OpenTicketsSC>
      <NumberBubble number={openTicketsCount} />
      <OpenTicketLabel>{t('open_tickets')}</OpenTicketLabel>
    </OpenTicketsSC>
  );
}
OpenTickets.propTypes = {
  openTicketsCount: PropTypes.number,
};

OpenTickets.defaultProps = {
  openTicketsCount: 0,
};
