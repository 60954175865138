/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-03-31 09:00:34
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const TicketsSC = styled.div`
  flex: 1;
  display: grid;
  gap: ${variables.cardPadding}px;
`;

export const HighlightCardContainer = styled.div`
  width: 100%;
`;
