/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 08:50:37
 * @modify date 2021-01-12 17:37:39
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const InputSC = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  height: ${(props) => variables.inputField[props.size].height}px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => props.theme.input.borderColor};
  transition: all 250ms ease;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  margin-left: 14px;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;

  .k-icon {
    color: ${(props) => props.theme.input.iconColor};
  }
`;

export const InputField = styled.input`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding-left: ${(props) => (props.isLeftIcon ? '42px' : '14px')};
  border: none;
  outline: none;
  flex: 1;
  background: transparent;
  font-size: 13.6px;
  color: ${(props) => props.theme.input.fontColor};
  min-width: 50px;
  transition: all 250ms ease;

  &:focus {
    ${InputSC} {
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
`;
