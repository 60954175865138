/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-10 12:41:01
 * @modify date 2021-03-13 21:37:08
 */

import styled from 'styled-components';

export const CommentSectionSC = styled.div`
  display: grid;
  padding: 8px 0 16px;
  gap: 16px;
`;

export const CommentsList = styled.div`
  display: grid;
  gap: 8px;
`;

export const Comment = styled.div`
  display: grid;
`;

export const UserInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 12px;

  .k-avatar-img {
    transform: translateY(10px);
  }
`;

export const TimeInfo = styled.div`
  display: flex;
  color: ${(props) => props.theme.secondaryColor};
  font-size: 12px;
`;

export const CommentText = styled.div`
  display: flex;
  color: ${(props) => props.theme.black};
  padding-left: 50px;
`;
