/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-19 17:00:17
 */

import styled from 'styled-components';

export const CardHeaderSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};
  padding: 0 16px;
  font-family: ${(props) => (props.isBold ? 'Lato' : 'Roboto')};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  transition: all 250ms ease;
`;
