/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 17:26:54
 * @modify date 2021-04-22 09:54:43
 */

import styled from 'styled-components';

import { variables } from 'variables';

export const StepsContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  width: ${variables.appDrawerExpandedWidth}px;
  background: ${(props) => props.theme.primaryColor};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${variables.headerHeight}px;
  width: 100%;
  min-height: ${variables.headerHeight}px;
  background: ${(props) => props.theme.primaryLightColor};
  padding: 0 16px;
`;

export const Logo = styled.div`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
`;

export const HeaderLogo = styled.img`
  margin: 6.5px;
  height: 19px;
`;

export const HeaderText = styled.p`
  display: flex;
  height: 19px;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  background: ${(props) => props.theme.primaryLightColor};
  color: ${(props) => props.theme.appDrawer.header.fontColor};
  letter-spacing: 1.4px;
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 2px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const BodyHead = styled.div`
  display: flex;
  color: ${(props) => props.theme.white};
  margin: 24px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
`;

export const StepsSC = styled.div`
  display: flex;
  color: ${(props) => props.theme.white};
`;
