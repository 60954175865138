/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-12 23:57:09
 * @modify date 2021-03-12 23:57:09
 */

import styled from 'styled-components';

export const StatusSelectionSC = styled.div`
  display: grid;
  width: 300px;

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 45px;
  padding: 0 16px;

  &:hover {
    background: ${(props) => props.theme.lightBackgroundColor};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  height: 22px;
  width: 22px;
  box-shadow: ${(props) => props.theme.boxShadow2};
  background: ${(props) => props.theme.white};
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;

export const Text = styled.div`
  display: flex;
  flex: 1;
`;
