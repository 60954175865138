/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { HeaderButtonsSC, UsernameContainer } from './HeaderButtons.style';

import HeaderButton from 'components/layout/header/header-buttons/header-button/HeaderButton';
import HeaderDropdownContainer from 'components/layout/header-dropdown/HeaderDropdown.container';
import EditProfileContainer from 'components/layout/edit-profile/EditProfile.container';

import Icon from 'components/common/icon/Icon';
import Avatar from 'components/common/avatar/Avatar';

import { logout } from 'utils/auth-util';

export default function HeaderButtons() {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userStates.userData);

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  return (
    <HeaderButtonsSC>
      {isProfileModalOpen ? (
        <EditProfileContainer
          onModalClose={() => setIsProfileModalOpen(false)}
        />
      ) : null}
      {/* Below two buttons are hidden for the first release */}
      {/* <HeaderButton
        name="Bookmarks"
        icon="bookmark outline"
        content={
          <HeaderDropdownContainer
            type="bookmarks"
            headerLabel={t('bookmarks')}
          />
        }
      />
      <HeaderButton
        name="Messages"
        icon="mail outline"
        isBadged
        content={
          <HeaderDropdownContainer
            type="messages"
            headerLabel={t('messages')}
          />
        }
      /> */}
      <HeaderButton
        name="Notifications"
        icon="bell outline"
        isBadged
        content={
          <HeaderDropdownContainer
            type="notifications"
            headerLabel={t('notifications')}
          />
        }
      />
      <Dropdown
        className="user-dropdown"
        inline
        trigger={
          <UsernameContainer>
            <Avatar
              username={userData.name}
              img={userData.img}
              alt={userData.name}
              reverseAlign
            />
          </UsernameContainer>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon={
              <Icon
                name="manage_accounts"
                iconTheme="outlined"
                size={18}
                isDark
              />
            }
            text={t('profile')}
            onClick={() => setIsProfileModalOpen(true)}
          />
          <Dropdown.Item
            icon={<Icon name="logout" size={18} isDark />}
            text={t('logout')}
            onClick={logout}
          />
        </Dropdown.Menu>
      </Dropdown>
    </HeaderButtonsSC>
  );
}
