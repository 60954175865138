/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  ProductsSC,
  ProductIconContainer,
  ProductIcon,
  More,
} from './Products.style';

export default function Products({ products, maxCount }) {
  return (
    <ProductsSC count={products.length}>
      {products
        .slice(0, products.length > maxCount ? maxCount - 1 : products.length)
        .map((product) => (
          <ProductIconContainer key={product.id}>
            <ProductIcon
              src={product.img}
              alt={product.name}
              title={product.name}
            />
          </ProductIconContainer>
        ))}
      {products.length > maxCount ? (
        <More
          title={products
            .slice(maxCount - 1)
            .map((product) => product.name)
            .join(', ')}
        >
          {`+${products.length - maxCount + 1}`}
        </More>
      ) : null}
    </ProductsSC>
  );
}

Products.propTypes = {
  products: PropTypes.array.isRequired,
  maxCount: PropTypes.number,
};

Products.defaultProps = {
  maxCount: 9,
};
