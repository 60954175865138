/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-03-18 21:52:06
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const ProjectSC = styled.div`
  flex: 1;
  display: grid;
  gap: ${variables.cardPadding}px;
`;

export const PageHeaderContainer = styled.div`
  width: 100%;
`;

export const HighlightCardContainer = styled.div`
  width: 100%;
`;

export const TasksList = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 8px;
`;
