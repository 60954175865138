/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-10 22:18:38
 * @modify date 2021-04-23 17:51:21
 */

import styled from 'styled-components';

export const NewTicketSC = styled.div`
  display: grid;
  width: 70vw;
  max-width: 895px;
  min-width: 782px;
  height: 60vh;
  padding: 20px;
  gap: 16px;
  overflow-x: auto;
  grid-template-columns: 1fr 350px;
`;

export const TitleDescription = styled.div`
  display: grid;
  align-content: start;
  gap: 8px;
  grid-template-rows: auto 1fr;

  .tabs {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.dividerColor};
  }
`;

export const TabContainer = styled.div`
  display: flex;
  max-height: calc(60vh - 116px);
  height: calc(60vh - 116px);
  overflow: auto;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.dividerColor};
`;

export const HighlightsContainer = styled.div`
  display: grid;
  gap: 14.4px;
  max-height: 100%;
  overflow: auto;
  background: ${(props) => props.theme.newTicket.highlight.backgroundColor};
  border-radius: 8px;
  padding: 0 12px;
  align-content: start;
`;

export const HighlightHeader = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 13.6px;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};
`;

export const HighlightBody = styled.div`
  display: grid;
  align-items: start;
  gap: 14.4px;
  padding-bottom: 10px;

  .add-button {
    background: ${(props) => props.theme.white};
    font-weight: bold;

    &:hover {
      background: ${(props) => props.theme.grayE};
    }
  }
`;

export const Label = styled.div`
  display: flex;
  color: ${(props) => props.theme.label.fontColor};
  font-size: 12px;
`;

export const Field = styled.div`
  display: grid;
  gap: 6.4px;

  .custom-dropdown {
    .ui.image {
      display: none;
    }

    .menu {
      .ui.image {
        width: 14px;
        margin-top: 0;
      }
    }
  }

  .multi-select-dropdown {
    .ui.label {
      background: ${(props) => props.theme.tag.backgroundColor};
      border-radius: 100px;
      color: ${(props) => props.theme.blackText};
      font-weight: normal;
    }
  }

  #status {
    .k-icon {
      color: ${(props) => props.theme.primaryColor};
      margin-right: 10px;
      line-height: 0;
      transform: translateY(2.5px);
    }
  }
`;

export const RootOption = styled.div`
  display: flex;
  align-items: center;

  .k-image-icon {
    height: 20px;
    width: 20px;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.dividerColor};
    border-radius: 3.2px;

    > img {
      height: 12px;
    }
  }
`;

export const RootName = styled.div`
  display: flex;
  flex: 1;
  margin-left: 14px;
  font-size: 13px;
`;

export const RootDescription = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(props) => props.theme.blackText2};
`;

export const SaveButtonContainer = styled.div`
  display: flex;
`;
