/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-04-17 21:28:21
 * @modify date 2021-04-17 21:28:21
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';

import { ChangeableAvatarSC } from './ChangeableAvatar.style';

import Avatar from 'components/common/avatar/Avatar';

import PEN from 'assets/images/cursors/pen.svg';

export default function ChangeableAvatar({
  loading,
  selectedUser,
  users,
  searchValue,
  onDropdownChange,
  onSearchChange,
}) {
  return (
    <ChangeableAvatarSC data-testid="changeable-avatar">
      <Dropdown
        trigger={
          <Avatar
            username={selectedUser.name}
            img={selectedUser.img}
            alt={selectedUser.name}
            forceCursor={`url(${PEN}), pointer`}
          />
        }
        pointing="top right"
        loading={loading}
        floating
        icon={null}
        selectOnNavigation={false}
      >
        <Dropdown.Menu>
          <Input
            icon="search"
            iconPosition="right"
            className="search"
            value={searchValue}
            onChange={onSearchChange}
            onClick={(e) => e.stopPropagation()}
          />
          <Dropdown.Menu scrolling>
            {users.map((user) => (
              <Dropdown.Item
                key={user.id}
                text={user.name}
                image={{ avatar: true, src: user.img }}
                description={user.description}
                value={user.id}
                onClick={() => onDropdownChange(user)}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
    </ChangeableAvatarSC>
  );
}

ChangeableAvatar.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectedUser: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  searchValue: PropTypes.string.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};
