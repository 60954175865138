/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-04 01:01:40
 * @modify date 2021-02-04 01:16:24
 */

import { axiosInstance } from 'utils/api-util';

export function getHighlightsService(entity, id) {
  return axiosInstance({
    url: `entity/highlights`,
    params: {
      entity,
      id,
    },
  });
}
