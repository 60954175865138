/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-10 22:08:22
 */

import {
  TOGGLE_APP_DRAWER,
  UPDATE_ACTIVE_MODAL,
  UPDATE_THEME,
  UPDATE_PAGE_BOTTOM,
} from 'types';

import { getDefaultTheme } from 'utils/theme-util';

import {
  saveInLocalStore,
  getFromLocalStore,
} from 'utils/state-persistence-util';

const PREFIX = 'commonStates';

const initialState = {
  isAppDrawerOpen: getFromLocalStore('isAppDrawerOpen', PREFIX, true),
  activeModal: '',
  theme: getFromLocalStore('theme', PREFIX, getDefaultTheme()), // Theme can be integrated with General settings API.
  isOnPageBottom: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_APP_DRAWER:
      saveInLocalStore('isAppDrawerOpen', !state.isAppDrawerOpen, PREFIX);
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    case UPDATE_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload,
      };
    case UPDATE_THEME:
      saveInLocalStore('theme', action.payload, PREFIX);
      return {
        ...state,
        theme: action.payload,
      };
    case UPDATE_PAGE_BOTTOM:
      return {
        ...state,
        isOnPageBottom: action.payload,
      };
    default:
      return state;
  }
}
