/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-31 00:12:59
 * @modify date 2020-12-31 00:12:59
 */

import React from 'react';
import PropTypes from 'prop-types';

import { KanbanBoardSC } from './KanbanBoard.style';

export default function KanbanBoard({ children }) {
  return (
    <KanbanBoardSC data-testid="kanban-board" count={children.length}>
      {children}
    </KanbanBoardSC>
  );
}

KanbanBoard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};
