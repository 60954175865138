/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  DoraSC,
  CollapsedButtonContainer,
  ChatContainerBox,
  IconContainer,
  ButtonText,
} from './Dora.style';

import Icon from 'components/common/icon/Icon';
import ChatContainer from 'components/layout/dora/chat/Chat.container';

export default function Dora({ showChat, onToggleChatVisibility }) {
  const { t } = useTranslation();

  return (
    <DoraSC data-testid="dora">
      <ChatContainerBox showChat={showChat}>
        <ChatContainer
          hidden={!showChat}
          onClose={() => onToggleChatVisibility(false)}
        />
      </ChatContainerBox>
      <CollapsedButtonContainer
        className="animate__animated animate__fadeInUp"
        showChat={showChat}
        onClick={() => onToggleChatVisibility()}
      >
        <IconContainer showChat={showChat}>
          <Icon
            showChat={showChat}
            name={showChat ? 'keyboard_arrow_up' : 'chat_bubble'}
            iconTheme="outlined"
            size={showChat ? 32 : 20}
          />
        </IconContainer>
        <ButtonText showChat={showChat}>{t('minimize')}</ButtonText>
      </CollapsedButtonContainer>
    </DoraSC>
  );
}

Dora.propTypes = {
  showChat: PropTypes.bool.isRequired,
  onToggleChatVisibility: PropTypes.func.isRequired,
};
