/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-26 22:50:41
 * @modify date 2021-02-27 01:01:39
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { variables } from 'variables';

import {
  WorkspaceDefaultLogo,
  WorkspaceText,
} from 'components/common/workspace-name/WorkspaceName.style';

export const WorkspaceMenuSC = styled.div`
  display: grid;
  width: ${variables.appDrawerExpandedWidth - 52}px;
  gap: 10px;
  padding: 0 4px;
`;

export const MainLinks = styled.div`
  display: grid;
  gap: 4px;
`;

export const MainLinkText = styled.div`
  display: flex;
  color: ${(props) => props.theme.black};
  margin-left: 10px;
  font-size: 14px;
`;

export const MainLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;

  .k-icon {
    color: ${(props) => props.theme.blackText2};
  }

  &:hover {
    ${MainLinkText} {
      color: ${(props) => props.theme.primaryColor};
    }
    .k-icon {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;

export const WorkspaceListContainer = styled.div`
  display: grid;
  margin: 10px 0;
`;

export const SubHeading = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(props) => props.theme.blackText2};
`;

export const WorkspaceList = styled.div`
  margin-top: 12px;
  width: 100%;
  display: grid;
  gap: 16px;
  max-height: 40vh;
  overflow-y: auto;
  ${WorkspaceDefaultLogo} {
    font-weight: normal;
  }
  ${WorkspaceText} {
    font-weight: normal;
  }
`;

export const WorkspaceNameContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;

  .k-icon {
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;

export const AddButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 100px;
  height: 40px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.primaryColor};
  background: ${(props) => props.theme.white};
  padding: 4px;
  margin: 10px 0;
  cursor: pointer;
`;

export const ButtonIconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.primaryColor};
`;

export const ButtonText = styled.div`
  display: flex;
  color: ${(props) => props.theme.actionColor};
  font-size: 16px;
  margin-left: 12px;

  &:hover {
    color: ${(props) => props.theme.actionHoverColor};
  }
`;
