/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  AvatarSC,
  ImageLink,
  AvatarImage,
  LetterImage,
  NameContainer,
  Username,
  Designation,
} from './Avatar.style';

import Separator from 'components/common/separator/Separator';

import { combineName } from 'utils/name-util';

export default function Avatar({
  username,
  boldUsername,
  hideUsername,
  alt,
  title,
  img,
  imgSize,
  reverseAlign,
  vertical,
  designation,
  link,
  forceCursor,
}) {
  username = combineName(username);
  alt = combineName(alt ?? username);
  title = alt;

  const renderUserImage = () => {
    return img ? (
      <AvatarImage src={img} alt={alt} title={title} imgSize={imgSize} />
    ) : (
      <LetterImage title={title} imgSize={imgSize}>
        {username[0] ?? 'A'}
      </LetterImage>
    );
  };

  return (
    <AvatarSC
      reverseAlign={reverseAlign}
      vertical={vertical}
      className="k-avatar"
      data-testid="avatar"
    >
      <ImageLink
        className="k-avatar-img"
        as={link ? Link : 'span'}
        to={link}
        disabled={!link}
        forceCursor={forceCursor}
      >
        {renderUserImage()}
      </ImageLink>
      {!hideUsername && username ? (
        <>
          <Separator size={vertical ? 8 : 16} />
          <NameContainer>
            <Username
              bold={boldUsername || designation ? 1 : 0}
              vertical={vertical ? 1 : 0}
              to={link}
              link={link ? 1 : 0}
              as={link ? Link : 'p'}
              forceCursor={forceCursor}
            >
              {username}
            </Username>
            <Designation>{designation}</Designation>
          </NameContainer>
        </>
      ) : null}
    </AvatarSC>
  );
}

Avatar.propTypes = {
  username: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  boldUsername: PropTypes.bool,
  hideUsername: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  img: PropTypes.string,
  imgSize: PropTypes.number,
  reverseAlign: PropTypes.bool,
  vertical: PropTypes.bool,
  designation: PropTypes.string,
  link: PropTypes.string,
  forceCursor: PropTypes.string,
};

Avatar.defaultProps = {
  username: '',
  boldUsername: false,
  hideUsername: false,
  alt: '',
  title: '',
  reverseAlign: false,
  img: null,
  imgSize: 32,
  vertical: false,
  designation: '',
  link: '',
  forceCursor: null,
};
