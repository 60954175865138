/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-27 20:41:51
 * @modify date 2021-02-27 20:41:51
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { OAuthButtonsSC } from './OAuthButtons.style';

import Button from 'components/common/button/Button';
import CustomIcon from 'components/common/custom-icon/CustomIcon';

export default function OAuthButtons({ onGoggleClick, onLinkedInClick }) {
  const { t } = useTranslation();

  return (
    <OAuthButtonsSC>
      <Button
        buttonType="flat"
        leftIcon={<CustomIcon name="Google" />}
        size="large"
        outlined
        centered
        fullWidth
        text={t('google')}
        onClick={onGoggleClick}
      />
      <Button
        buttonType="flat"
        leftIcon={<CustomIcon name="LinkedIn" />}
        size="large"
        outlined
        centered
        fullWidth
        text={t('linkedIn')}
        onClick={onLinkedInClick}
      />
    </OAuthButtonsSC>
  );
}
OAuthButtons.propTypes = {
  onGoggleClick: PropTypes.func.isRequired,
  onLinkedInClick: PropTypes.func.isRequired,
};
