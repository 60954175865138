/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 13:49:29
 * @modify date 2021-03-13 23:31:24
 */

import { axiosInstance } from 'utils/api-util';

export function getMembersService(entity, id, page = 0) {
  return axiosInstance({
    url: `entity/contributors`,
    params: {
      entity,
      id,
      page,
    },
  });
}
