/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 20:51:37
 * @modify date 2021-04-24 14:28:54
 */

import styled from 'styled-components';

export const StepsSC = styled.div`
  display: grid;
  gap: 24px;
`;

export const Step = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  opacity: ${(props) => (props.activeStep ? '1' : '0.6')};
  color: ${(props) => props.theme.white};

  &:hover {
    color: ${(props) => props.theme.white};
  }

  &:before {
    content: '';
    position: absolute;
    height: 28px;
    width: 2px;
    background: ${(props) => props.theme.white};
    left: 7px;
    top: -26px;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
`;

export const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background: ${(props) => props.theme.white};
  border-radius: 100px;

  .k-icon {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const StepText = styled.div`
  margin-left: 16px;
`;
