/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:36:15
 */

import styled from 'styled-components';

export const OpenTicketsSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OpenTicketLabel = styled.div`
  display: flex;
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 12px;
  margin-left: 8px;
`;
