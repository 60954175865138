/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 13:49:33
 * @modify date 2021-02-17 15:01:27
 */

import styled from 'styled-components';

export const MembersAccordionSC = styled.div`
  display: grid;
  gap: 8px;
  flex: 1;
`;

export const AccordionHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 280px;
  align-items: center;
  justify-items: start;
  flex: 1;
  animation-delay: ${(props) => props.index * 100}ms;
`;

export const AccordionBodyContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
