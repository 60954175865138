/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { IconSC } from './Icon.style';

export default function Icon({ name, className, iconTheme, isDark, size }) {
  return (
    <IconSC
      data-testid="icon"
      className={'k-icon ' + className}
      isDark={isDark}
      size={size}
      iconTheme={iconTheme}
    >
      {name}
    </IconSC>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconTheme: PropTypes.oneOf(['filled', 'outlined']),
  isDark: PropTypes.bool,
  size: PropTypes.number,
};

Icon.defaultProps = {
  className: '',
  iconTheme: 'filled',
  isDark: false,
  size: 16,
};
