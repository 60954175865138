/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 16:06:38
 * @modify date 2021-04-18 18:55:11
 */

import Config from 'app.config';
import axios from 'axios';

import { axiosInstanceNoAuth } from 'utils/api-util';

export function registerService(
  { firstName, lastName, companyId, email, password },
  token = null,
) {
  let formData = new FormData();

  formData.append('first_name', firstName);
  formData.append('last_name', lastName);
  if (companyId) {
    formData.append('company_id', companyId);
  }
  formData.append('email', email);
  formData.append('password', password);
  if (token) {
    formData.append('token', token);
  }

  return axios({
    method: 'post',
    url: Config.herokuBaseUrl + `user/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getUserEmailFromToken(token) {
  return axiosInstanceNoAuth({
    url: `user/invite?token=${token}`,
  });
}
