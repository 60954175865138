/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-14 17:18:14
 */

import styled from 'styled-components';

export const DonutChartSC = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  box-shadow: ${(props) => props.theme.donutChart.boxShadow};
  background: ${(props) => props.theme.white};

  .donut-ring {
    stroke: ${(props) => props.theme.donutChart.emptyRing};
  }

  .donut-segment {
    stroke: ${(props) => props.theme.donutChart.fillRing};
    transition: all 1000ms ease;
  }

  .percent-text {
    font-size: 11px;
    font-weight: 500;

    .percent-sign {
      font-size: 7px;
    }
  }
`;
