/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tasks from './Tasks';

import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getTasksService } from './Tasks.service';

export default function TasksContainer({
  entity1,
  id1,
  entity2,
  id2,
  isPriority,
}) {
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [internalLoading, setInternalLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getTasks = () => {
    setInternalLoading(true);
    getTasksService(entity1, id1, entity2, id2, isPriority, page).then(
      (response) => {
        setTasks((old) => [...old, ...(response?.data?.data ?? [])]);
        setHasMore(response?.data?.hasMore ?? false);
        setLoading(false);
        setInternalLoading(false);
      },
    );
  };

  useEffect(getTasks, [page]);

  const handleNextPage = () => {
    if (hasMore && !internalLoading) {
      setPage((old) => old + 1);
    }
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom) {
      handleNextPage();
    }
  };

  if (loading) {
    return <Loading gap={100} />;
  }

  if (!tasks.length) {
    return <NoData />;
  }

  return <Tasks tasks={tasks} hasMore={hasMore} onScroll={handleScroll} />;
}

TasksContainer.propTypes = {
  entity1: PropTypes.string,
  entity2: PropTypes.string,
  id1: PropTypes.string,
  id2: PropTypes.string,
  isPriority: PropTypes.bool,
};

TasksContainer.defaultProps = {
  entity1: '',
  entity2: '',
  id1: '',
  id2: '',
  isPriority: false,
};
