/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-01-08 12:48:12
 */

import styled from 'styled-components';

export const TagsSC = styled.div`
  display: grid;
  grid-auto-flow: ${(props) =>
    props.orientation === 'horizontal' ? 'column' : 'row'};
  flex: 1;
  align-items: center;
  justify-items: center;
  align-content: center;
  gap: ${(props) => props.gap}px;
`;
