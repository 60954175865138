/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-07 22:19:34
 * @modify date 2021-03-02 00:14:54
 */

import styled from 'styled-components';

export const BreadcrumbsSC = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 7px;
  align-items: center;
`;

export const Arrow = styled.div`
  display: flex;

  .k-icon {
    color: ${(props) => props.theme.breadcrumbs.arrowColor};
  }
`;

export const Label = styled.div`
  text-decoration: none;
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 12px;
  transition: all 250ms ease;
`;

export const LinkSC = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 7px;
  align-items: center;

  &:first-child {
    ${Arrow} {
      display: none;
    }
  }
`;
