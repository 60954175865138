/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-04 15:56:58
 * @modify date 2021-01-04 15:56:58
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { GuestNavigatorSC } from './GuestNavigator.style';

import Redirects from 'components/common/redirects/Redirects';

import NotFound from 'components/layout/not-found/NotFound';

import LoginContainer from 'components/pages/guest/login/Login.container';
import RegisterContainer from 'components/pages/guest/register/Register.container';
import CheckEmail from 'components/pages/guest/check-email/CheckEmail';
import LandingPageContainer from 'components/pages/guest/landing-page/LandingPage.container';
import ActivatingAccountContainer from 'components/pages/guest/activating-account/ActivatingAccount.container';
import Policies from 'components/pages/guest/policies/Policies';

import { links, memberLinks } from 'utils/navigation-util';

export default function GuestNavigator() {
  return (
    <GuestNavigatorSC data-testid="guest-navigator">
      <Switch>
        <Route exact path={links.login.path} component={LoginContainer} />
        <Route exact path={links.register.path} component={RegisterContainer} />
        <Route exact path={links.check_email.path} component={CheckEmail} />
        <Route
          exact
          path={links.verify.path}
          component={ActivatingAccountContainer}
        />
        <Route exact path={links.terms_of_service.path} component={Policies} />
        <Route exact path={links.privacy_policy.path} component={Policies} />
        <Route
          exact
          path={links.landing.path}
          component={LandingPageContainer}
        />

        <Redirects links={memberLinks} redirectLink={links.login.path} />

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </GuestNavigatorSC>
  );
}
