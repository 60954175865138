/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-16 15:47:58
 * @modify date 2021-01-16 15:53:23
 */

import { v4 as uuid } from 'uuid';

export const bookmarks = [
  {
    id: uuid(),
    entityImage:
      'https://raw.githubusercontent.com/cosmos-saas/public/master/ProjectIcons/plateaue.png',
    entityName: 'Plateau',
    time: '3 days',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'Knowledge graph model for mist documents',
      },
    ],
  },
  {
    id: uuid(),
    entityImage:
      'https://raw.githubusercontent.com/cosmos-saas/public/master/GanFaces/ebrahim.jpg',
    entityName: 'Ebrahim S.',
    time: '3 weeks',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'Ebrahim S.',
      },
      {
        id: uuid(),
        type: 'light',
        text: 'updated',
      },
      {
        id: uuid(),
        type: 'normal',
        text: 'Integrate new donut chart.',
      },
    ],
  },
  {
    id: uuid(),
    entityImage:
      'https://raw.githubusercontent.com/cosmos-saas/public/master/GanFaces/paras.jpeg',
    entityName: 'Paras B.',
    time: '2 weeks',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'Paras B.',
      },
      {
        id: uuid(),
        type: 'light',
        text: 'created',
      },
      {
        id: uuid(),
        type: 'normal',
        text: 'Integrate new donut chart.',
      },
    ],
  },
  {
    id: uuid(),
    entityImage:
      'https://raw.githubusercontent.com/cosmos-saas/public/master/GanFaces/umar.jpeg',
    entityName: 'Umar A.',
    time: '1 day',
    message: [
      {
        id: uuid(),
        type: 'normal',
        text: 'Umar A.',
      },
      {
        id: uuid(),
        type: 'light',
        text: 'created',
      },
      {
        id: uuid(),
        type: 'normal',
        text: 'Develop Auth. APIs',
      },
    ],
  },
];
