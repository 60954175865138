/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-02-26 22:19:04
 */

import styled from 'styled-components';

export const MenuLink = styled.div`
  display: flex;
  height: 48px;
  border-top-left-radius: 48px;
  border-bottom-left-radius: 48px;
  align-items: center;
`;

export const IconSC = styled.div`
  display: flex;
  width: 24px;
  margin: 0 12px;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
`;

export const TextSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  transition: all 250ms ease;
`;

export const MenuLinksSC = styled.nav`
  display: flex;
  flex-direction: column;
  transition: all 250ms ease;

  .link {
    color: ${(props) => props.theme.primaryTextColor};
    text-decoration: none;
    font-size: 13.6px;

    &:hover {
      ${MenuLink} {
        background: ${(props) => props.theme.menuLink.hoverBackgroudColor};
      }
    }

    &.active {
      ${MenuLink} {
        background: ${(props) => props.theme.menuLink.activeBackgroundColor};
      }
      ${TextSC} {
        font-family: Lato;
        font-weight: bold;
      }
    }
  }
`;
