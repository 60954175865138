/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  MemberNavigatorSC,
  AppDrawerOverlay,
  PageHeaderContainer,
  PageContainer,
  PageContent,
} from './MemberNavigator.style';

import Redirects from 'components/common/redirects/Redirects';

import NotFound from 'components/layout/not-found/NotFound';
import AppDrawerContainer from 'components/layout/app-drawer/AppDrawer.container';
import HeaderContainer from 'components/layout/header/Header.container';
import NewTicketContainer from 'components/layout/new-ticket/NewTicket.container';
import DoraContainer from 'components/layout/dora/Dora.container';

import HomeContainer from 'components/pages/member/home/Home.container';
import TicketsContainer from 'components/pages/member/tickets/Tickets.container';
import PeopleContainer from 'components/pages/member/people/People.container';
import UserContainer from 'components/pages/member/user/User.container';
import TeamContainer from 'components/pages/member/team/Team.container';
import DashboardListContainer from 'components/pages/member/dashboard-list/DashboardList.container';
import DashboardContainer from 'components/pages/member/dashboard/Dashboard.container';
import TicketContainer from 'components/pages/member/ticket/Ticket.container';
import MyTicketsContainer from 'components/pages/member/my-tickets/MyTickets.container';
import WorkspaceContainer from 'components/pages/member/workspace/Workspace.container';

import { links, guestLinks } from 'utils/navigation-util';

export default function MemberNavigator({
  isAppDrawerOpen,
  workspaces,
  showNewTicket,
  onToggleAppDrawer,
  onPageScroll,
}) {
  return (
    <MemberNavigatorSC data-testid="member-navigator">
      <Switch>
        <Route path={links.workspace.path} component={WorkspaceContainer} />
        {workspaces && workspaces.length ? (
          <Route path="*">
            <AppDrawerContainer />
            <AppDrawerOverlay
              onClick={onToggleAppDrawer}
              isAppDrawerOpen={isAppDrawerOpen}
            />

            <DoraContainer />

            <PageHeaderContainer
              isAppDrawerOpen={isAppDrawerOpen}
              data-testid="page-header-container"
            >
              <HeaderContainer />

              <PageContainer
                data-testid="page-container"
                onScroll={onPageScroll}
              >
                <PageContent data-testid="page-content">
                  <Switch>
                    <Route
                      exact
                      path={links.home.path}
                      component={HomeContainer}
                    />
                    <Route
                      path={links.tickets.path}
                      component={TicketsContainer}
                    />
                    <Route
                      path={links.my_tickets.path}
                      component={MyTicketsContainer}
                    />
                    <Route
                      path={links.people.path}
                      component={PeopleContainer}
                    />
                    <Route path={links.user.path} component={UserContainer} />
                    <Route path={links.team.path} component={TeamContainer} />
                    <Route
                      path={links.ticket.path}
                      component={TicketContainer}
                    />
                    <Route
                      exact
                      path={links.dashboards.path}
                      component={DashboardListContainer}
                    />
                    <Route
                      exact
                      path={links.dashboard.path}
                      component={DashboardContainer}
                    />

                    <Redirects
                      links={guestLinks}
                      redirectLink={links.home.path}
                    />

                    <Route path="*">
                      <NotFound />
                    </Route>
                  </Switch>
                </PageContent>
              </PageContainer>
            </PageHeaderContainer>
            {showNewTicket ? <NewTicketContainer /> : null}
          </Route>
        ) : (
          <Route path="*">
            <Redirect to={links.workspace.path} />
          </Route>
        )}
      </Switch>
    </MemberNavigatorSC>
  );
}

MemberNavigator.propTypes = {
  isAppDrawerOpen: PropTypes.bool.isRequired,
  workspaces: PropTypes.array.isRequired,
  showNewTicket: PropTypes.bool.isRequired,
  onToggleAppDrawer: PropTypes.func.isRequired,
  onPageScroll: PropTypes.func.isRequired,
};
