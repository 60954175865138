/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  UpdateCardSC,
  UpdateHeaderLeft,
  TimeInfo,
  BodyContainer,
  BasicDataContainer,
  UpdateDataContainer,
} from './UpdateCard.style';

import Avatar from 'components/common/avatar/Avatar';
import Card from 'components/common/card/Card';
import CardHeader from 'components/common/card/card-header/CardHeader';
import CardBody from 'components/common/card/card-body/CardBody';
import CardFooter from 'components/common/card/card-footer/CardFooter';
import UpdateFooter from 'components/common/update-card/update-footer/UpdateFooter';
// import MenuButton from 'components/common/menu-button/MenuButton';

import { combineName } from 'utils/name-util';

import { getExtremeRelativeTime } from 'utils/date-formats-util';

export default function UpdateCard({
  index,
  update,
  renderBasicData,
  renderUpdateData,
  onLikeClick,
  onNewCommentSubmit,
}) {
  return (
    <UpdateCardSC
      data-testid="update-card"
      className="animate__animated animate__fadeIn"
      index={index}
    >
      <Card>
        <CardHeader>
          <UpdateHeaderLeft>
            <Avatar
              link={'/user/' + update.createdBy.id}
              username={
                combineName(update.createdBy.name) + ' updated the ticket'
              }
              img={update.createdBy.img}
              imgSize={29}
            />

            <TimeInfo>{getExtremeRelativeTime(update.createdAt)}</TimeInfo>
          </UpdateHeaderLeft>

          {/* <MenuButton
            options={[
              {
                key: 'bookmark',
                label: t('bookmark'),
              },
              {
                key: 'follow',
                label: t('follow'),
              },
              {
                key: 'edit',
                label: t('edit'),
              },
              {
                key: 'duplicate',
                label: t('duplicate'),
              },
              {
                key: 'archive',
                label: t('archive'),
              },
              {
                key: 'expand',
                label: t('expand'),
              },
              {
                key: 'delete',
                label: t('delete'),
              },
            ]}
          /> */}
        </CardHeader>
        <CardBody padded>
          <BodyContainer>
            <BasicDataContainer>{renderBasicData}</BasicDataContainer>
            <UpdateDataContainer>{renderUpdateData}</UpdateDataContainer>
          </BodyContainer>
        </CardBody>
        <CardFooter>
          <UpdateFooter
            update={update}
            onLikeClick={onLikeClick}
            onNewCommentSubmit={onNewCommentSubmit}
          />
        </CardFooter>
      </Card>
    </UpdateCardSC>
  );
}

UpdateCard.propTypes = {
  index: PropTypes.number.isRequired,
  update: PropTypes.object.isRequired,
  renderBasicData: PropTypes.element.isRequired,
  renderUpdateData: PropTypes.element.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onNewCommentSubmit: PropTypes.func.isRequired,
};
