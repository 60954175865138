/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-02-23 15:25:52
 */

import styled from 'styled-components';

export const TabsSC = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-content: center;
  gap: 10px;
  height: 40px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow1};
  border-radius: 8px;
  padding: 0 8px;
  transition: all 250ms ease;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: 'transparent';
    color: ${(props) => props.theme.blackText};
    border: none;
    outline: none;
    height: 30px;
    padding: 0 21px;
    border-radius: 100px;
    cursor: pointer;
    transition: all 250ms ease;

    &:hover {
      background: ${(props) => props.theme.tabs.button.hoverBackgroundColor};
    }

    &.active {
      background: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.primaryTextColor};

      &:hover {
        background: ${(props) => props.theme.primaryColor};
      }
    }
  }
`;
