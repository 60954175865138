/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-25 18:40:25
 * @modify date 2021-01-25 18:40:25
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ActivatingAccount from './ActivatingAccount';

import { verifyToken } from './ActivatingAccount.service';

export default function ActivatingAccountContainer() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const [error, setError] = useState('');

  const handleVerifyToken = () => {
    if (token) {
      verifyToken(token)
        .then(() => {
          window.location = '/login';
        })
        .catch((error) => {
          setError(error.response.data?.detail);
        });
    }
  };

  useEffect(handleVerifyToken, [token]);

  return <ActivatingAccount error={error} />;
}
