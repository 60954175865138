/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 18:30:29
 * @modify date 2021-01-06 18:30:29
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AuthWelcomeSC,
  WelcomeMessage,
  WelcomeSubText,
  IllustrationCard,
  IllustrationImage,
} from './AuthWelcome.style';

import CustomIcon from 'components/common/custom-icon/CustomIcon';
import Carousel from 'components/common/carousel/Carousel';

import Illustration1 from 'assets/images/auth-screen-illustration-1.svg';
import Illustration2 from 'assets/images/auth-screen-illustration-2.svg';
import Illustration3 from 'assets/images/auth-screen-illustration-3.svg';

export default function AuthWelcome() {
  const { t } = useTranslation();

  return (
    <AuthWelcomeSC
      className="animate__animated animate__slideInLeft"
      data-testid="auth-welcome"
    >
      <CustomIcon name="PlateauLogoNoBG" size={24} />

      <WelcomeMessage>{t('welcome_to_plateau')}</WelcomeMessage>

      <Carousel
        autoplay={5000}
        cards={[
          {
            id: 1,
            content: (
              <IllustrationCard>
                <WelcomeSubText>{t('welcome_sub_text1')}</WelcomeSubText>
                <IllustrationImage src={Illustration1} />
              </IllustrationCard>
            ),
          },
          {
            id: 2,
            content: (
              <IllustrationCard>
                <WelcomeSubText>{t('welcome_sub_text2')}</WelcomeSubText>
                <IllustrationImage src={Illustration2} />
              </IllustrationCard>
            ),
          },
          {
            id: 3,
            content: (
              <IllustrationCard>
                <WelcomeSubText>{t('welcome_sub_text3')}</WelcomeSubText>
                <IllustrationImage src={Illustration3} />
              </IllustrationCard>
            ),
          },
        ]}
      />
    </AuthWelcomeSC>
  );
}
