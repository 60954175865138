/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-19 16:48:37
 * @modify date 2021-01-19 16:48:37
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MenuButtonSC, IconContainer } from './MenuButton.style';

import Icon from 'components/common/icon/Icon';
import Overlay from 'components/common/overlay/Overlay';
import Menu from 'components/common/menu/Menu';

export default function MenuButton({
  orientation,
  iconSize,
  options,
  onOptionClick,
}) {
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 10, y: 10 });

  const handleIconClick = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
    setOpen((old) => !old);
  };

  return (
    <MenuButtonSC data-testid="menu-button" size={iconSize}>
      {open ? <Overlay transparent onClick={() => setOpen(false)} /> : null}
      <IconContainer size={iconSize} onClick={handleIconClick}>
        <Icon
          name={orientation === 'horizontal' ? 'more_horiz' : 'more_vert'}
          size={iconSize * 0.6}
          isDark
        />
      </IconContainer>
      {open ? (
        <Menu
          position={position}
          options={options}
          onOptionClick={(option) => {
            setOpen(false);
            onOptionClick(option);
          }}
        />
      ) : null}
    </MenuButtonSC>
  );
}

MenuButton.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  iconSize: PropTypes.number,
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
};

MenuButton.defaultProps = {
  iconSize: 32,
  orientation: 'horizontal',
  options: [],
  onOptionClick: () => {},
};
