/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSC, IconSC, TextSC } from './Button.style';

import Loading from 'components/common/loading/Loading';

export default function Button({
  leftIcon,
  rightIcon,
  loading,
  className,
  text,
  size,
  buttonType,
  iconButton,
  title,
  elevated,
  centered,
  rounded,
  outlined,
  fullWidth,
  disabled,
  onClick,
}) {
  return (
    <ButtonSC
      data-testid="button"
      className={'k-button ' + className}
      buttonType={buttonType}
      size={size}
      title={title}
      elevated={elevated}
      centered={centered}
      rounded={rounded}
      fullWidth={fullWidth}
      outlined={outlined}
      iconButton={iconButton}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? <Loading gap={0} /> : null}
      {!loading && leftIcon ? (
        <IconSC className="k-button-icon" buttonType={buttonType} size={size}>
          {leftIcon}
        </IconSC>
      ) : null}
      {!loading && text ? (
        <TextSC
          className="k-button-text"
          buttonType={buttonType}
          size={size}
          centered={centered}
        >
          {text}
        </TextSC>
      ) : null}
      {!loading && rightIcon ? (
        <IconSC className="k-button-icon" buttonType={buttonType} size={size}>
          {rightIcon}
        </IconSC>
      ) : null}
    </ButtonSC>
  );
}

Button.propTypes = {
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  loading: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  buttonType: PropTypes.oneOf(['default', 'flat']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  title: PropTypes.string,
  elevated: PropTypes.bool,
  centered: PropTypes.bool,
  rounded: PropTypes.bool,
  iconButton: PropTypes.bool,
  fullWidth: PropTypes.bool,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  loading: false,
  className: '',
  text: 'button',
  buttonType: 'default',
  size: 'medium',
  title: '',
  elevated: false,
  centered: false,
  rounded: false,
  iconButton: false,
  fullWidth: false,
  outlined: false,
  disabled: false,
  onClick: () => {},
};
