/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Updates from './Updates';

import Error from 'components/common/error/Error';
import Loading from 'components/common/loading/Loading';
import NoData from 'components/common/no-data/NoData';

import { getUpdatesService, addComment, updateLike } from './Updates.service';

export default function UpdatesContainer({ entity, id }) {
  const { t } = useTranslation();

  const isOnPageBottom = useSelector(
    (state) => state.commonStates.isOnPageBottom,
  );
  const userData = useSelector((state) => state.userStates.userData);

  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [internalLoading, setInternalLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(0);
  const [page, setPage] = useState(0);

  const getUpdates = () => {
    getUpdatesService(entity, id, page)
      .then((updates) => {
        setUpdates(updates?.data?.data ?? []);
        setHasMore(updates?.data?.hasMore ?? false);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
        setInternalLoading(false);
      });
  };

  const getMoreUpdates = () => {
    if (loading || internalLoading || !isOnPageBottom || !hasMore) {
      return;
    }
    setInternalLoading(true);
    getUpdatesService(entity, id, page)
      .then((updates) => {
        setUpdates((old) => [...old, ...updates?.data?.data]);
        setHasMore(updates?.data?.hasMore ?? false);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setInternalLoading(false);
        setPage((old) => old + 1);
      });
  };

  useEffect(getUpdates, []);
  useEffect(getMoreUpdates, [isOnPageBottom]);

  const handleLikeClick = (updateId, index) => {
    let likedByUserIndex = -1;
    if (updates[index].likes) {
      likedByUserIndex = updates[index].likes.findIndex(
        (likeBy) => likeBy.id === userData.id,
      );
    }

    setInternalLoading(true);
    updateLike(likedByUserIndex === -1, updateId).then((response) => {
      setUpdates((old) => {
        old[index] = response?.data.update;
        return old;
      });
      setInternalLoading(false);
    });
  };

  const handleNewCommentSubmit = (comment, attachments, updateId, index) => {
    setInternalLoading(true);
    addComment(comment, attachments, updateId).then((response) => {
      setUpdates((old) => {
        old[index] = response?.data.update;
        return old;
      });
      setInternalLoading(false);
    });
  };

  if (error) {
    return <Error gap={100} />;
  }

  if (loading) {
    return <Loading gap={100} />;
  }

  if (!updates.length) {
    return <NoData msg={t('no_updates_available')} />;
  }

  return (
    <Updates
      internalLoading={internalLoading}
      updates={updates}
      loading={hasMore}
      onLikeClick={handleLikeClick}
      onNewCommentSubmit={handleNewCommentSubmit}
    />
  );
}

UpdatesContainer.propTypes = {
  entity: PropTypes.string,
  id: PropTypes.string,
};

UpdatesContainer.defaultProps = {
  entity: '',
  id: '',
};
