/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-16 00:31:56
 * @modify date 2021-02-16 00:31:56
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  EntityAccordionSC,
  AccordionHeaderContainer,
  AccordionBodyContainer,
} from './EntityAccordion.style';

import EntityOverviewAccordion from 'components/common/entity-overview-accordion/EntityOverviewAccordion';
import EntityName from 'components/common/entity-name/EntityName';
import LabelDonut from 'components/common/label-donut/LabelDonut';
import AvatarGroup from 'components/common/avatar-group/AvatarGroup';
import ImageIcon from 'components/common/image-icon/ImageIcon';
import TasksContainer from 'components/layout/tasks/Tasks.container';

import { getRangeAndDuration } from 'utils/date-formats-util';

export default function EntityAccordion({ entity }) {
  const { t } = useTranslation();

  return (
    <EntityAccordionSC>
      <EntityOverviewAccordion
        header={
          <AccordionHeaderContainer className="animate__animated animate__fadeIn">
            <EntityName
              icon={<ImageIcon src={entity.img} alt={entity.title} />}
              entityName={t(entity.entity)}
              title={entity.title}
              link={`/ticket-${entity.key}`}
              footer={getRangeAndDuration(entity.createdAt, entity.dueDate)}
            />
            <LabelDonut
              value={entity.completedTasks}
              total={entity.totalTasks}
              label={t('tasks_completed')}
            />
            <AvatarGroup
              users={entity.contributors ?? []}
              view="line"
              maxCount={4}
            />
          </AccordionHeaderContainer>
        }
        hideToggleButton
        isOpen
        onToggle={() => {}}
      >
        <AccordionBodyContainer>
          <TasksContainer entity1={entity.entity} id1={entity.id} />
        </AccordionBodyContainer>
      </EntityOverviewAccordion>
    </EntityAccordionSC>
  );
}

EntityAccordion.propTypes = {
  entity: PropTypes.object.isRequired,
};
