/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-04-01 14:32:09
 */

export const variables = {
  headerHeight: 48,
  appDrawerExpandedWidth: 240,
  pageContentPadding: 24,
  cardPadding: 16,
  highlightCardWidth: 128,
  carousel: {
    buttonsWidth: 30,
    gap: 4,
  },
  dora: {
    width: 234,

    container: {
      height: 480,
    },
  },
  buttonSize: {
    small: { height: 26, fontSize: 12, iconSizeReduction: 4.8 },
    medium: { height: 32, fontSize: 13, iconSizeReduction: 10 },
    large: { height: 36.8, fontSize: 13.6, iconSizeReduction: 4.8 },
    huge: { height: 48, fontSize: 15.2, iconSizeReduction: 8 },
  },
  imageIconSize: {
    small: { height: 40, imgHeight: 24, borderRadius: 6, fontSize: 16 },
    medium: { height: 56, imgHeight: 35, borderRadius: 8, fontSize: 24 },
    large: { height: 73, imgHeight: 45, borderRadius: 10, fontSize: 28 },
  },
  entityName: {
    small: { titleFontSize: 14.4, footerFontSize: 10 },
    medium: { titleFontSize: 19, footerFontSize: 11 },
    large: { titleFontSize: 22, footerFontSize: 12 },
  },
  inputField: {
    small: { height: 32 },
    medium: { height: 40 },
    large: { height: 48 },
  },
  ganttChart: {
    dayWidth: 30,
  },
  featureCard: {
    width: 198,
  },
  pageHeader: {
    height: 82,
    iconVariantMap: {
      box: {
        borderRadius: 8,
        imageHeight: 32,
      },
      circle: {
        borderRadius: 100,
        imageHeight: 56,
      },
    },
  },
  menu: {
    height: 32,
    width: 160,
  },
};

export const zIndex = {
  appDrawer: 9997,
  appDrawerOverlay: 9996,
  workspaceMenu: 9998,
  header: 1899,
  searchHeader: 1950,
  searchHeaderOverlay: 1949,
  dashbaordFilter: 1840,
  dashbaordFilterOverlay: 1839,
  overlay: 9999998,
  menu: 9999999,
  ganttChartTimeLabel: 999,
  milestoneDonutChart: 10,
  timeRangeIcons: 100,
  modalOverlay: 9999,
  dora: 999998,
};

export const deviceBreakpoints = {
  tablet: 876,
};
