/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-02 23:01:13
 * @modify date 2021-03-03 21:29:58
 */

import styled from 'styled-components';

export const FileInfo = styled.div`
  position: absolute;
  bottom: -120px;
  background: ${(props) => props.theme.grayE};
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 80px;
  z-index: 100;
  transition: all 250ms ease;
  box-shadow: 0 -2px 5px ${(props) => props.theme.blackA2};
`;

export const AttachmentBlockSC = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 120px;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.dividerColor};
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    ${FileInfo} {
      bottom: 0;
    }
  }
`;

export const FilePreview = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ImagePreview = styled.img`
  display: flex;
  max-width: 120px;
  border-radius: 8px;
`;

export const FileName = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  padding: 0 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const FileSize = styled.div`
  display: flex;
  flex: 1;
  font-size: 11px;
  padding: 0 10px;
  color: ${(props) => props.theme.blackText2};
`;

export const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-left: 7px;
  padding-right: 10px;
  height: 26px;
  border-radius: 30px;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 1px solid ${(props) => props.theme.actionColor};
  cursor: pointer;
  color: ${(props) => props.theme.actionColor};
  font-size: 12px;

  .k-icon {
    color: ${(props) => props.theme.actionColor};
    margin-right: 4px;
  }

  &:hover {
    background: ${(props) => props.theme.white};
  }
`;
