/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-09 16:24:47
 */

import styled from 'styled-components';

import { variables, zIndex } from 'variables';

export const AppDrawerSC = styled.div`
  display: flex;
  height: 100vh;
  transition: all 250ms ease;
  z-index: ${zIndex.appDrawer};
  margin-left: ${(props) =>
    props.isAppDrawerOpen ? 0 : `-${variables.appDrawerExpandedWidth}px`};
`;

export const AppDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 0;
  min-width: ${variables.appDrawerExpandedWidth}px;
  height: 100%;
  background: ${(props) => props.theme.primaryColor};
  transition: all 250ms ease;
`;

export const CreateButtonContainer = styled.div`
  display: flex;
  width: ${variables.appDrawerExpandedWidth - 24}px;
  margin: 24px auto;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const MenuHeader = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(props) => props.theme.primaryTextColor};
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-left: 12px;
`;

export const MenuLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DoraContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: calc(100% - 16px);
  margin: 24px auto;
  min-height: 40px;
`;
