/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-03-13 17:49:46
 * @modify date 2021-04-18 08:24:48
 */

import { axiosInstance } from 'utils/api-util';

export function getProfileData() {
  return axiosInstance({
    url: 'profile/',
  });
}

export function updateUserData(userId, data) {
  const formData = new FormData();

  formData.append('first_name', data.firstName);
  formData.append('last_name', data.lastName);

  return axiosInstance({
    url: `user/${userId}/`,
    method: 'PATCH',
    data: formData,
    skipWorkspaceId: true,
  });
}

export function changePassword(oldPassword, newPassword) {
  const formData = new FormData();

  formData.append('old_password', oldPassword);
  formData.append('new_password', newPassword);

  return axiosInstance({
    url: 'change-password/',
    method: 'PUT',
    data: formData,
  });
}

export function updateProfilePicture(id, newFile) {
  const newFormData = new FormData();
  newFormData.append('file', newFile);

  return axiosInstance({
    url: `upload_file`,
    method: 'POST',
    data: newFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => {
      if (!response?.data?.file) {
        throw 'Something went wrong';
      }

      const userFormData = new FormData();
      userFormData.append('avatar', response?.data?.file.url);

      return axiosInstance({
        url: `user/${id}/`,
        method: 'PATCH',
        data: userFormData,
      });
    })
    .catch((error) => {
      throw error;
    });
}

export function removeProfilePicture(id) {
  const userFormData = new FormData();
  userFormData.append('avatar', '');

  return axiosInstance({
    url: `user/${id}/`,
    method: 'PATCH',
    data: userFormData,
  });
}
