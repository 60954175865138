/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  GanttChartSC,
  TimeLabelContainer,
  TimeLabel,
  Content,
  DaysBackground,
  DayBar,
  EntityArea,
  EntityList,
  EntityBar,
  EntityName,
  AvatarContainer,
} from './GanttChart.style';

import Avatar from 'components/common/avatar/Avatar';

export default function GanttChart({
  numberOfTimePeriods,
  timePeriodLabel,
  entities,
}) {
  console.log(numberOfTimePeriods);
  return (
    <GanttChartSC
      data-testid="gantt-chart"
      className="animate__animated animate__fadeIn"
    >
      <TimeLabelContainer>
        {Array.from(Array(numberOfTimePeriods).keys()).map((x) => (
          <TimeLabel key={x}>
            {timePeriodLabel} {x + 1}
          </TimeLabel>
        ))}
      </TimeLabelContainer>

      <Content days={numberOfTimePeriods * 7}>
        <DaysBackground>
          {Array.from(Array(numberOfTimePeriods * 7).keys()).map((x) => (
            <DayBar key={x} />
          ))}
        </DaysBackground>

        <EntityArea>
          <EntityList>
            {entities.map((entity) => (
              <EntityBar
                className="animate__animated animate__fadeInUp"
                key={entity.id}
                color={entity.color}
                startDay={entity.startDay}
                length={entity.projectLengthInDays}
              >
                <EntityName title={entity.title} to={`/ticket-${entity.key}`}>
                  {entity.title}
                </EntityName>
                <AvatarContainer>
                  <Avatar
                    img={entity.assignee.img}
                    link={`user/${entity.assignee.id}`}
                    title={entity.assignee.name}
                    imgSize={26}
                  />
                </AvatarContainer>
              </EntityBar>
            ))}
          </EntityList>
        </EntityArea>
      </Content>
    </GanttChartSC>
  );
}

GanttChart.propTypes = {
  entities: PropTypes.array.isRequired,
  numberOfTimePeriods: PropTypes.number,
  timePeriodLabel: PropTypes.string,
};

GanttChart.defaultProps = {
  numberOfTimePeriods: 11,
  timePeriodLabel: 'Week',
};
