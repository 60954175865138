/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React from 'react';
import PropTypes from 'prop-types';

import { ChatBlockSC, Message } from './ChatBlock.style';

import Avatar from 'components/common/avatar/Avatar';

export default function ChatBlock({ sent, user, message }) {
  return (
    <ChatBlockSC sent={sent} data-testid="chat-block">
      <Avatar
        img={user.img}
        username={user.name}
        hideUsername
        reverseAlign={sent}
        imgSize={sent ? 27 : 24}
      />
      <Message sent={sent}>{message}</Message>
    </ChatBlockSC>
  );
}

ChatBlock.propTypes = {
  sent: PropTypes.bool,
  user: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
};

ChatBlock.defaultProps = {
  sent: false,
};
