/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-21 21:39:33
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundSC, Card, Heading, SubHeading } from './NotFound.style';

import Config from 'app.config';

export default function NotFound() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('not_found.title');
    return () => {
      document.title = Config.appTitle;
    };
  });

  return (
    <NotFoundSC data-testid="not-found">
      <Card>
        <Heading data-testid="not-found-heading">{t('404')}</Heading>
        <SubHeading>{t('page_not_found')}</SubHeading>
      </Card>
    </NotFoundSC>
  );
}
