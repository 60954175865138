/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:20
 * @modify date 2020-12-27 13:25:20
 */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Dashboard from './Dashboard';

import Loading from 'components/common/loading/Loading';
import NotFound from 'components/layout/not-found/NotFound';

import {
  getStatuses,
  getDashboardService,
  getAllStatusTicketsService,
  getTicketsService,
  getAllStatusTicketsServiceByFilterIds,
  getTicketsServiceByFilterIds,
  updateTicket,
} from './Dashboard.service';

export default function DashboardContainer({ filterIds }) {
  const { dashboardId } = useParams();
  const [dashboard, setDashboard] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [pendingTickets, setPendingTickets] = useState([]);
  const [inProgressTickets, setInProgressTickets] = useState([]);
  const [completedTickets, setCompletedTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState([false, false, false]);
  const [internalLoading, setInternalLoading] = useState(false);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);
  const [page3, setPage3] = useState(0);

  const getData = () => {
    if (!filterIds) {
      getDashboardService(dashboardId).then((dashboardResponse) => {
        setDashboard(dashboardResponse?.data?.data);
        getStatuses().then((statusesResponse) => {
          setStatuses(statusesResponse?.data?.data);
          getAllStatusTicketsService(
            dashboardId,
            statusesResponse?.data?.data,
          ).then((ticketResponses) => {
            setPendingTickets(ticketResponses[0]?.data?.data[0]?.tasks);
            setInProgressTickets(ticketResponses[1]?.data?.data[0]?.tasks);
            setCompletedTickets(ticketResponses[2]?.data?.data[0]?.tasks);
            setHasMore(
              ticketResponses.map((r) => r?.data?.data[0]?.hasMore ?? false),
            );
            setLoading(false);
          });
        });
      });
    }
  };

  useEffect(getData, []);

  const getPreviewData = () => {
    if (filterIds && filterIds.length) {
      setLoading(true);
      getStatuses().then((statusesResponse) => {
        setStatuses(statusesResponse?.data?.data);
        getAllStatusTicketsServiceByFilterIds(
          filterIds,
          statusesResponse?.data?.data,
        ).then((ticketResponses) => {
          setPendingTickets(ticketResponses[0]?.data?.data[0]?.tasks);
          setInProgressTickets(ticketResponses[1]?.data?.data[0]?.tasks);
          setCompletedTickets(ticketResponses[2]?.data?.data[0]?.tasks);
          setHasMore(
            ticketResponses.map((r) => r?.data?.data[0]?.hasMore ?? false),
          );
          setLoading(false);
        });
      });
    }
  };

  useEffect(getPreviewData, [filterIds]);

  const handleAddTicket = (event) => {
    updateTicket(event.item.id, event.to.id);
  };

  const handleGetTicketDataResponse = (index, response) => {
    if (index === 0) {
      setPendingTickets((old) => [...old, ...response?.data?.data[0]?.tasks]);
      setPage1((old) => old + 1);
    } else if (index === 1) {
      setInProgressTickets((old) => [
        ...old,
        ...response?.data?.data[0]?.tasks,
      ]);
      setPage2((old) => old + 1);
    } else {
      setCompletedTickets((old) => [...old, ...response?.data?.data[0]?.tasks]);
      setPage3((old) => old + 1);
    }
    setHasMore((old) => {
      old[index] = response?.data?.data[0]?.hasMore ?? false;
      return old;
    });
    setInternalLoading(false);
  };

  const getTicketsData = (index) => {
    const pages = [page1, page2, page3];

    setInternalLoading(true);
    if (filterIds) {
      getTicketsServiceByFilterIds(
        filterIds,
        statuses[index].id,
        pages[index] + 1,
      ).then((response) => {
        handleGetTicketDataResponse(index, response);
      });
    } else {
      getTicketsService(dashboardId, statuses[index].id, pages[index] + 1).then(
        (response) => {
          handleGetTicketDataResponse(index, response);
        },
      );
    }
  };

  const handleScroll = ({ target }, index) => {
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && hasMore[index] && !internalLoading) {
      getTicketsData(index);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!dashboard && !filterIds) {
    return <NotFound />;
  }

  return (
    <Dashboard
      showHeader={!filterIds || !filterIds?.length}
      statuses={statuses}
      hasMore={hasMore}
      dashboard={dashboard}
      tickets={[pendingTickets, inProgressTickets, completedTickets]}
      setTickets={[
        setPendingTickets,
        setInProgressTickets,
        setCompletedTickets,
      ]}
      onAdd={handleAddTicket}
      onScroll={handleScroll}
    />
  );
}

DashboardContainer.propTypes = {
  filterIds: PropTypes.array,
};

DashboardContainer.defaultProps = {
  filterIds: null,
};
