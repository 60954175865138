/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-08 01:46:55
 * @modify date 2020-12-24 01:46:55
 */

import React from 'react';
import { useSelector } from 'react-redux';

import MyTickets from './MyTickets';

import NotFound from 'components/layout/not-found/NotFound';

const tabs = [
  {
    id: 'tickets',
    name: 'Tickets',
    link: '/my-tickets/tickets',
  },
  {
    id: 'updates',
    name: 'Updates',
    link: '/my-tickets/updates',
  },
];

export default function TicketsContainer() {
  const userData = useSelector((state) => state.userStates.userData);

  if (!userData) {
    return <NotFound />;
  }

  return <MyTickets userData={userData} tabs={tabs} />;
}
