/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { HighlightCardSC, Header, Body } from './HighlightCard.style';

import Avatar from 'components/common/avatar/Avatar';

import Tags from 'components/common/tags/Tags';
import AvatarGroup from 'components/common/avatar-group/AvatarGroup';
import Products from 'components/common/highlight-card/products/Products';
import Tickets from 'components/common/highlight-card/tickets/Tickets';

import { highlightCardTypes } from 'utils/highlight-card-util';

export default function HighlightCard({
  header,
  type,
  data,
  isAnimated,
  index,
}) {
  const renderBody = () => {
    switch (highlightCardTypes[type]) {
      case highlightCardTypes.tags:
        return <Tags tags={data} />;
      case highlightCardTypes.user:
        return (
          <Avatar
            username={data.name}
            link={`/user/${data.id}`}
            img={data.img}
            vertical
          />
        );
      case highlightCardTypes.users:
        if (data.length === 1) {
          return (
            <Avatar
              username={data[0].name}
              link={`/user/${data[0].id}`}
              img={data[0].img}
              vertical
            />
          );
        }
        return <AvatarGroup users={data} />;
      case highlightCardTypes.products:
        return <Products products={data} />;
      case highlightCardTypes.tickets:
        return <Tickets data={data} />;
      default:
        return null;
    }
  };

  return (
    <HighlightCardSC
      data-testid="highlight-card"
      // TODO: Removed animation, use animation again if we needed
      className={isAnimated ? '' : ''}
      // className={isAnimated ? 'animate__animated animate__fadeInDown' : ''}
      index={index}
    >
      <Header>{header}</Header>
      <Body>{renderBody()}</Body>
    </HighlightCardSC>
  );
}

HighlightCard.propTypes = {
  header: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  isAnimated: PropTypes.bool,
  index: PropTypes.any,
};

HighlightCard.defaultProps = {
  isAnimated: false,
  index: 0,
};
