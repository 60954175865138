/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2021-04-19 20:30:12
 */

import styled, { css } from 'styled-components';

export const AvatarSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: ${(props) =>
    props.vertical
      ? props.reverseAlign
        ? 'column-reverse'
        : 'column'
      : props.reverseAlign
      ? 'row-reverse'
      : 'row'};
`;

export const ImageLink = styled.span`
  cursor: ${(props) =>
    props.forceCursor
      ? props.forceCursor
      : props.disabled
      ? 'default'
      : 'pointer'};
`;

export const AvatarImage = styled.img`
  display: flex;
  height: ${(props) => props.imgSize}px;
  border-radius: ${(props) => props.imgSize}px;
`;

export const LetterImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.imgSize}px;
  height: ${(props) => props.imgSize}px;
  border-radius: ${(props) => props.imgSize}px;
  background: ${(props) => props.theme.primaryDarkColor};
  color: ${(props) => props.theme.white};
  font-size: ${(props) => props.imgSize / 2}px;
`;

export const NameContainer = styled.div`
  display: grid;
  gap: 2px;
`;

export const Username = styled.p`
  display: flex;
  font-size: ${(props) => (props.bold ? '14.4px' : '13.6px')};
  font-family: ${(props) => (props.bold ? 'Lato' : 'Roboto')};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  margin: 0;
  color: ${(props) => props.theme.black};
  text-decoration: none;
  cursor: ${(props) =>
    props.forceCursor ? props.forceCursor : props.link ? 'pointer' : 'default'};

  &:hover {
    color: ${(props) => props.theme.black};
  }

  ${(props) =>
    props.vertical &&
    css`
      text-align: center;
    `}
`;

export const Designation = styled.div`
  display: flex;
  font-size: 13px;
`;
