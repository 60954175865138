/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-23 16:07:27
 * @modify date 2021-01-23 16:07:27
 */

import React from 'react';
import PropTypes from 'prop-types';

import { ErrorSC } from './Error.style';

export default function Error({ gap, children }) {
  return (
    <ErrorSC data-testid="error" className="k-error" gap={gap}>
      {children}
    </ErrorSC>
  );
}
Error.propTypes = {
  gap: PropTypes.number,
  children: PropTypes.string,
};

Error.defaultProps = {
  gap: 10,
  children: 'Something went wrong. Please try again later.',
};
