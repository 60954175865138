/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import {
  TimeRangeSC,
  Icons,
  IconContainer,
  IconContainerLink,
  TextContainer,
  Range,
  Duration,
} from './TimeRange.style';

import CustomIcon from 'components/common/custom-icon/CustomIcon';
import Icon from 'components/common/icon/Icon';

import StatusSelectionContainer from 'components/layout/status-selection/StatusSelection.container';
export default function TimeRange({
  icon,
  isPriority,
  range,
  duration,
  updateStatus,
  ticket,
}) {
  return (
    <TimeRangeSC data-testid="time-range" className="k-time-range">
      <Icons>
        <IconContainer top hidden={isPriority ? 0 : 1}>
          <CustomIcon name="Priority" size={15} />
        </IconContainer>
        {updateStatus ? (
          <Popup
            style={{
              padding: 0,
              borderRadius: '4px',
              overflow: 'hidden',
            }}
            trigger={
              <IconContainerLink top={false}>
                <Icon name={icon} size={15} />
              </IconContainerLink>
            }
            content={<StatusSelectionContainer ticket={ticket} />}
            position="bottom left"
            on="click"
            basic
          />
        ) : icon ? (
          <IconContainer top={false}>
            <Icon name={icon} size={15} />
          </IconContainer>
        ) : null}
      </Icons>
      <TextContainer>
        <Range>{range}</Range>
        <Duration>{duration}</Duration>
      </TextContainer>
    </TimeRangeSC>
  );
}

TimeRange.propTypes = {
  icon: PropTypes.string,
  isPriority: PropTypes.bool,
  range: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  updateStatus: PropTypes.bool,
  ticket: PropTypes.object,
};

TimeRange.defaultProps = {
  isPriority: false,
  icon: null,
  updateStatus: false,
  ticket: null,
};
