/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-24 11:38:36
 * @modify date 2021-02-26 02:41:07
 */

export function getCurrentWorkspace() {
  let state = localStorage.getItem('state');
  if (!state) {
    return false;
  }
  state = JSON.parse(state);
  if (state?.userStates?.selectedWorkspace) {
    return state?.userStates?.selectedWorkspace;
  }
  return null;
}
