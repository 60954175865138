/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-20 17:42:52
 * @modify date 2021-01-20 17:42:52
 */

import React, { useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {
  LandingPageSC,
  Content,
  CatchLink,
  SectionContainer,
  Section1,
  TextArea,
  Heading1,
  Heading2,
  Heading3,
  Para,
  ImageContainer,
  Image,
  SectionContent,
  VisionSection,
  VisionSectionContent,
  SolutionSection,
  SolutionSectionContent,
  FeatureSection,
  FeatureSectionContent,
  FeatureSectionContentPhone,
  FeatureCard,
  FeatureImage,
  FeatureText,
  ContentSection,
  ButtonContainer,
  ContactForm,
  Input,
} from './LandingPage.style';

import Carousel from 'components/common/carousel/Carousel';

import Navbar from 'components/pages/guest/landing-page/navbar/Navbar';

import Illustration1 from 'assets/images/landing-page/illustration-1.svg';
import Illustration2 from 'assets/images/landing-page/illustration-2.svg';
import Illustration3 from 'assets/images/landing-page/illustration-3.svg';
import Illustration4 from 'assets/images/landing-page/illustration-4.svg';
import Illustration5 from 'assets/images/landing-page/illustration-5.svg';
import Illustration6 from 'assets/images/landing-page/illustration-6.svg';
import Illustration7 from 'assets/images/landing-page/illustration-7.svg';

export default function LandingPage({ loading, onContactFormSubmit }) {
  const { t } = useTranslation();

  const [visionShowAll, setVisionShowAll] = useState(false);
  const [solutionShowAll, setSolutionShowAll] = useState(false);

  const visionRef = useRef(null);
  const solutionRef = useRef(null);
  const featuresRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <LandingPageSC id="home">
      <Navbar refs={[visionRef, solutionRef, featuresRef, contactRef]} />

      <Content>
        <SectionContainer addBackground>
          <Section1>
            <TextArea style={{ paddingTop: '60px' }}>
              <Heading1 marginBottom={10}>
                {t('landing_page.section1.heading')}
              </Heading1>
              <Para>{t('landing_page.section1.para')}</Para>
              <Button primary className="c-button" href="/register">
                {t('try_it')}
              </Button>
            </TextArea>
            <ImageContainer>
              <Image src={Illustration1} alt={t('illustration')} />
            </ImageContainer>
          </Section1>
        </SectionContainer>

        <CatchLink id="vision" />
        <SectionContainer ref={visionRef}>
          <VisionSection>
            <Heading2 marginBottom={10}>
              {t('landing_page.section2.heading')}
            </Heading2>
            <Heading3 marginBottom={40}>
              {t('landing_page.section2.sub_heading')}
            </Heading3>

            <VisionSectionContent>
              <ImageContainer
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Image
                  src={Illustration2}
                  alt={t('illustration')}
                  style={{ height: '330px' }}
                />
              </ImageContainer>
              <TextArea style={{ width: '280px', marginLeft: '30px' }}>
                <Para>{t('landing_page.section2.para1')}</Para>
                {visionShowAll && (
                  <>
                    <Para>{t('landing_page.section2.para2')}</Para>
                    <Para>{t('landing_page.section2.para3')}</Para>
                  </>
                )}
                <Button
                  primary
                  className="c-button"
                  onClick={() => setVisionShowAll((old) => !old)}
                >
                  {t(visionShowAll ? 'read_less' : 'read_more')}
                </Button>
              </TextArea>
            </VisionSectionContent>
          </VisionSection>
        </SectionContainer>

        <CatchLink id="solution" />
        <SectionContainer ref={solutionRef} addBackground>
          <SolutionSection>
            <Heading2 marginBottom={10}>
              {t('landing_page.section3.heading')}
            </Heading2>
            <Heading3 marginBottom={40}>
              {t('landing_page.section3.sub_heading')}
            </Heading3>

            <SolutionSectionContent>
              <TextArea style={{ width: '230px', marginLeft: '30px' }}>
                <Para>{t('landing_page.section3.para1')}</Para>
                {solutionShowAll ? (
                  <>
                    <Para>{t('landing_page.section3.para2')}</Para>
                    <Para>{t('landing_page.section3.para3')}</Para>
                    <Para>{t('landing_page.section3.para4')}</Para>
                  </>
                ) : null}
                <Button
                  primary
                  className="c-button"
                  onClick={() => setSolutionShowAll((old) => !old)}
                >
                  {t(solutionShowAll ? 'read_less' : 'read_more')}
                </Button>
              </TextArea>
              <ImageContainer
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Image
                  src={Illustration3}
                  alt={t('illustration')}
                  style={{ height: '330px' }}
                />
              </ImageContainer>
            </SolutionSectionContent>
          </SolutionSection>
        </SectionContainer>

        <CatchLink id="features" />
        <SectionContainer ref={featuresRef}>
          <FeatureSection>
            <Heading2 marginBottom={10}>
              {t('landing_page.section4.heading')}
            </Heading2>
            <Heading3 marginBottom={60}>
              {t('landing_page.section4.sub_heading')}
            </Heading3>

            <FeatureSectionContent>
              <FeatureCard>
                <FeatureImage src={Illustration4} alt={t('illustration')} />
                <FeatureText>
                  <Trans
                    i18nKey="landing_page.section4.para1"
                    components={{ bold: <strong /> }}
                  />
                </FeatureText>
              </FeatureCard>
              <FeatureCard>
                <FeatureImage src={Illustration5} alt={t('illustration')} />
                <FeatureText>
                  <Trans
                    i18nKey="landing_page.section4.para2"
                    components={{ bold: <strong /> }}
                  />
                </FeatureText>
              </FeatureCard>
              <FeatureCard>
                <FeatureImage src={Illustration6} alt={t('illustration')} />
                <FeatureText>
                  <Trans
                    i18nKey="landing_page.section4.para3"
                    components={{ bold: <strong /> }}
                  />
                </FeatureText>
              </FeatureCard>
            </FeatureSectionContent>

            <FeatureSectionContentPhone>
              <Carousel
                autoplay={5000}
                cards={[
                  {
                    id: 1,
                    content: (
                      <FeatureCard>
                        <FeatureImage
                          src={Illustration4}
                          alt={t('illustration')}
                        />
                        <FeatureText>
                          <Trans
                            i18nKey="landing_page.section4.para1"
                            components={{ bold: <strong /> }}
                          />
                        </FeatureText>
                      </FeatureCard>
                    ),
                  },
                  {
                    id: 2,
                    content: (
                      <FeatureCard>
                        <FeatureImage
                          src={Illustration5}
                          alt={t('illustration')}
                        />
                        <FeatureText>
                          <Trans
                            i18nKey="landing_page.section4.para2"
                            components={{ bold: <strong /> }}
                          />
                        </FeatureText>
                      </FeatureCard>
                    ),
                  },
                  {
                    id: 3,
                    content: (
                      <FeatureCard>
                        <FeatureImage
                          src={Illustration6}
                          alt={t('illustration')}
                        />
                        <FeatureText>
                          <Trans
                            i18nKey="landing_page.section4.para3"
                            components={{ bold: <strong /> }}
                          />
                        </FeatureText>
                      </FeatureCard>
                    ),
                  },
                ]}
              />
            </FeatureSectionContentPhone>
          </FeatureSection>
        </SectionContainer>

        <SectionContainer ref={contactRef} addBackground>
          <ContentSection id="contact_us">
            <Heading2 marginBottom={10}>
              {t('landing_page.contact.heading')}
            </Heading2>
            <Heading3 marginBottom={40} case="uppercase">
              {t('landing_page.contact.sub_heading')}
            </Heading3>

            <SectionContent>
              <ImageContainer style={{ justifyContent: 'flex-end' }}>
                <Image
                  src={Illustration7}
                  alt={t('illustration')}
                  style={{ height: '290px' }}
                />
              </ImageContainer>
              <ContactForm onSubmit={onContactFormSubmit}>
                <Input type="text" placeholder={t('name')} />
                <Input type="text" placeholder={t('company')} />
                <Input type="email" placeholder={t('email_address')} required />
                <ButtonContainer>
                  <Button
                    primary
                    size="large"
                    type="submit"
                    className="s-button"
                    loading={loading}
                  >
                    {t('connect')}
                  </Button>
                </ButtonContainer>
              </ContactForm>
            </SectionContent>
          </ContentSection>
        </SectionContainer>
      </Content>
    </LandingPageSC>
  );
}

LandingPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  onContactFormSubmit: PropTypes.func.isRequired,
};
