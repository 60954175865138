/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-25 18:25:52
 * @modify date 2021-01-25 18:25:52
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ActivatingAccountSC, Heading } from './ActivatingAccount.style';

import Error from 'components/common/error/Error';
import AppLogo from 'components/common/app-logo/AppLogo';

export default function ActivatingAccount({ error }) {
  const { t } = useTranslation();

  return (
    <ActivatingAccountSC>
      <Heading>{t('activating_your_account')}</Heading>

      {error ? <Error>{error}</Error> : null}

      <AppLogo isFixedAtBottom />
    </ActivatingAccountSC>
  );
}

ActivatingAccount.propTypes = {
  error: PropTypes.string.isRequired,
};
