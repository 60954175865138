/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2020-12-26 23:29:19
 */

import translationEN from 'languages/en.json';

export const languages = ['en'];

// the translations
export const resources = {
  en: {
    translation: translationEN,
  },
};
