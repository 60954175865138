/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  LabelDonutSC,
  DonutContainer,
  LabelContainer,
  Label,
  SubLabel,
} from './LabelDonut.style';

import DonutChart from 'components/common/donut-chart/DonutChart';

export default function LabelDonut({ total, value, label }) {
  const { t } = useTranslation();

  if (!total || !value) return <div />;

  return (
    <LabelDonutSC data-testid="label-donut">
      <DonutContainer>
        <DonutChart percent={Math.ceil((value / total) * 100)} />
      </DonutContainer>
      <LabelContainer>
        <Label>
          {value} {t('of')} {total}
        </Label>
        <SubLabel>{label}</SubLabel>
      </LabelContainer>
    </LabelDonutSC>
  );
}

LabelDonut.propTypes = {
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
