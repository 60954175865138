/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 23:32:24
 * @modify date 2021-02-17 23:58:49
 */

import styled from 'styled-components';

export const PoliciesSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  gap: 32px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 10px 20px;

  .tabs {
    border: 1px solid ${(props) => props.theme.dividerColor};
    box-shadow: ${(props) => props.theme.policies.tab.boxShadow};
  }
`;
