/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-17 23:31:42
 * @modify date 2021-02-17 23:31:42
 */

import React from 'react';

import { PoliciesSC, Content } from './Policies.style';

import TermsOfService from 'components/pages/guest/policies/terms-of-service/TermsOfService';
import PrivacyPolicy from 'components/pages/guest/policies/privacy-policy/PrivacyPolicy';
import TabRouter from 'components/layout/tab-router/TabRouter';
import Navbar from 'components/pages/guest/landing-page/navbar/Navbar';

export default function Policies() {
  return (
    <PoliciesSC>
      <Navbar refs={[]} />

      <Content>
        <TabRouter
          tabs={[
            {
              id: 'terms-of-service',
              name: 'Terms Of Service',
              link: '/terms-of-service',
            },
            {
              id: 'privacy-policy',
              name: 'Privacy Policy',
              link: '/privacy-policy',
            },
          ]}
          renders={[<TermsOfService key={1} />, <PrivacyPolicy key={2} />]}
        />
      </Content>
    </PoliciesSC>
  );
}
