/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-06 11:20:41
 * @modify date 2021-01-06 11:20:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { DividerSC } from './Divider.style';

export default function Divider({ orientation, thickness }) {
  return (
    <DividerSC
      data-testid="divier"
      orientation={orientation}
      thickness={thickness}
    />
  );
}

Divider.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  thickness: PropTypes.number,
};

Divider.defaultProps = {
  orientation: 'horizontal',
  thickness: 1,
};
