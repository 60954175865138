/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-20 17:54:45
 * @modify date 2021-01-22 22:01:33
 */

import styled from 'styled-components';
import { deviceBreakpoints, zIndex } from 'variables';

export const NavbarSC = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  padding: 0 20px;
  z-index: ${zIndex.header};
  box-shadow: ${(props) =>
    props.isScrolled ? props.theme.boxShadow2 : 'none'};
  transition: all 250ms ease;
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: ${(props) => (props.isScrolled ? 60 : 120)}px;
  transition: all 250ms ease;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    height: 60px;
  }
`;

export const Brand = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoSC = styled.img`
  display: flex;
  height: 32px;
  margin-right: 12px;
  padding: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.primaryColor};
`;

export const Name = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 20px;
  color: ${(props) => props.theme.black};
`;

export const Links = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 36px;

  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const LinkSC = styled.a`
  position: relative;
  display: flex;
  font-weight: bold;
  color: ${(props) =>
    props.highlight
      ? props.theme.primaryColor
      : props.isBlock
      ? props.theme.white
      : props.theme.black};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: ${(props) => (props.isBlock ? '80px' : 'auto')};
  background: ${(props) =>
    props.isBlock ? props.theme.primaryColor : 'transparent'};
  font-size: 13px;
  transition: all 250ms ease;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 24px;
    width: ${(props) => (props.active ? 100 : 0)}%;
    height: 3px;
    background: ${(props) => props.theme.blackText2};
    border-radius: 10px;
    transition: all 250ms ease;
  }

  &:hover {
    color: ${(props) =>
      props.highlight
        ? props.theme.primaryColor
        : props.isBlock
        ? props.theme.white
        : props.theme.black};
  }
`;

export const PhoneToggleButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin-right: 10px;

  .k-icon {
    color: ${(props) => props.theme.landingPage.navbar.iconColor};
  }

  @media only screen and (min-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const LinksPhone = styled.span`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.show ? 500 : 0)}px;
  padding: ${(props) => (props.show ? 15 : 0)}px 0;
  overflow: hidden;
  transition: all 250ms ease;

  @media only screen and (min-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export const PhoneLinkContainer = styled.span``;

export const PhoneLinkSC = styled.a`
  position: relative;
  display: flex;
  font-weight: bold;
  color: ${(props) =>
    props.highlight
      ? props.theme.primaryColor
      : props.isBlock
      ? props.theme.white
      : props.theme.black};
  border-radius: 4px;
  justify-content: ${(props) => (props.isBlock ? 'center' : 'flex-start')};
  align-items: center;
  height: 32px;
  width: ${(props) => (props.isBlock ? '100%' : 'auto')};
  margin: ${(props) => (props.isBlock ? 10 : 0)}px;
  background: ${(props) =>
    props.isBlock ? props.theme.primaryColor : 'transparent'};
  font-size: 13px;
  margin-left: ${(props) => (props.active ? 10 : 0)}px;
  transition: all 250ms ease;

  &:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 6px;
    width: 3px;
    height: ${(props) => (props.active ? 20 : 0)}px;
    background: ${(props) => props.theme.blackText2};
    border-radius: 10px;
    transition: all 250ms ease;
  }
`;
