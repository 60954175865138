/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-28 13:42:55
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const CardBodySC = styled.div`
  display: flex;
  flex: 1;
  min-height: 10px;
  padding: ${(props) => (props.padded ? variables.cardPadding : 0)}px;
`;
