/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-03-31 09:10:04
 */

export default {
  /** Brand colors */
  primaryColor: '#2176ff',
  primaryTextColor: '#ffffff',
  secondaryTextColor: '#525f7f',
  primaryLightColor: '#3783FF',
  primaryDarkColor: '#1d65da',
  actionColor: '#2176ff',
  actionHoverColor: '#4196ff',
  dangerColor: '#fe4040',
  dangerHoverColor: '#ff2020',
  errorColor: '#fe4040',
  errorBackgroundColor: '#ffe3e6',
  errorBorderColor: '#ecbcc0',
  selectedColor: '#3390ff',
  linkColor: '#2f00e0',
  pageBackgroundColor: '#f7f9fc',
  lightBackgroundColor: '#f4f8ff',
  lightBackgroundColor2: '#E9F2FF',
  dividerColor: '#e6e6e6',
  white: '#ffffff',
  grayE: '#eeeeee',
  black: '#000000',
  blackText: '#4c4c4c',
  blackText2: '#808080',
  blackA1: 'rgba(0, 0, 0, 0.1)',
  blackA2: 'rgba(0, 0, 0, 0.2)',
  blackA3: 'rgba(0, 0, 0, 0.3)',
  blackA4: 'rgba(0, 0, 0, 0.4)',
  blackA5: 'rgba(0, 0, 0, 0.5)',
  whiteA9: 'rgba(255, 255, 255, 0.9)',
  gradiantBackground:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(33, 118, 255, 0.15) 78.8%), #ffffff',
  gradiantBackgroundBorder: '1px solid rgba(33, 118, 255, 0.15)',
  overlayBackground: 'rgba(0,0,0,0.5)',

  primaryColorA1: 'rgba(33, 118, 255, 0.1)',
  primaryColorA2: 'rgba(33, 118, 255, 0.2)',
  primaryColorA3: 'rgba(33, 118, 255, 0.3)',

  secondaryColorA1: 'rgba(82, 95, 127, 0.1)',
  secondaryColorA2: 'rgba(82, 95, 127, 0.2)',
  secondaryColorA3: 'rgba(82, 95, 127, 0.3)',

  boxShadow1: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  boxShadow2: '0px 2px 5px rgba(0, 0, 0, 0.2)',

  appDrawer: {
    header: {
      fontColor: '#ffffff',
    },
    createButton: {
      backgroundColor: '#ffffff',
      backgroundHoverColor: '#f2f2f2',
    },
  },

  auth: {
    backgroundColor: '#fbfcff',
    form: {
      boxShadow: '0px 16px 40px rgba(33, 118, 255, 0.14)',
    },
  },

  breadcrumbs: {
    arrowColor: '#C8CDD2',
  },

  button: {
    default: {
      backgroundColor: '#2176ff',
      backgroundHoverColor: '#3783FF',
      fontColor: '#f2f2f2',
      iconColor: '#f2f2f2',
      boxShadow: '0px 2px 5px rgba(33, 118, 255, 0.5);',
    },
    flat: {
      backgroundColor: 'transparent',
      backgroundHoverColor: '#e2e2e2',
      fontColor: '#222222',
      iconColor: '#444444',
      boxShadow: 'none',
    },
    inversePrimary: {
      backgroundColor: '#ffffff',
      backgroundHoverColor: '#f2f2f2',
      boxShadow: '0px 2px 5px rgba(33, 118, 255, 0.5);',
    },
  },

  card: {
    backgroundColor: '#ffffff',
  },

  carousel: {
    cardsContainer: {
      backgroundColor: '#f3f4f7',
    },
    dots: {
      backgroundColor: '#c9cacc',
      activeBackgroundColor: '#000000',
    },
  },

  checkbox: {
    hoverLabel: {
      fontColor: 'darken(#212121, 20)',
      before: {
        boxShadow: '0 0 0 3px rgba(117, 121, 231, 0.4)',
      },
    },
  },

  donutChart: {
    emptyRing: '#d7f3e1',
    fillRing: '#37c16b',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },

  dora: {
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.35)',
  },

  entityName: {
    icon: {
      backgroundColor: '#ffffff',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    },
  },

  entityOverviewAccordion: {
    body: {
      borderColor: '#ECEEEF',
    },
  },

  ganttChart: {
    timePeriodIndicatorOddColor: '#E8F1FF',
    timePeriodIndicatorEvenColor: '#D3E4FF',
    dayEvenColor: '#F3F4FA',
  },

  header: {
    backgroundColor: '#ffffff',
    iconColor: '#808080',
    iconHoverColor: '#606060',

    badge: {
      backgroundColor: '#D95D63',
    },
  },

  highlightCard: {
    header: {
      backgroundColor: '#DFECFF',
    },
  },

  icon: {
    darkFontColor: '#222222',
    lightFontColor: '#ffffff',
  },

  input: {
    iconColor: '#808080',
    fontColor: '#222222',
    borderColor: '#e6e6e6',
    hoverBorderColor: '#d6d6d6',
  },

  kanbanBoard: {
    swimlane: {
      backgroundColor: '#F0F2F5',
    },
    card: {
      dragShadow: '-10px 10px 15px rgba(0, 0, 0, 0.15)',
    },
  },

  label: {
    fontColor: '#ADAFB1',
  },

  landingPage: {
    lightBackgroundColor: '#FAFDFF',

    navbar: {
      iconColor: '#C4C4C4',
    },

    input: {
      boxShadow: '10.5px 9.75px 19.5px 7.3px rgba(66, 141, 255, 0.08)',
      placeholder: '#b7d3ff',
    },
  },

  menuLink: {
    hoverBackgroudColor: '#1E6AE5',
    activeBackgroundColor: '#1C64D9',
  },

  modal: {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
  },

  newTicket: {
    highlight: {
      backgroundColor: '#F6F9FC',
    },
  },

  notFound: {
    fontColor: '#222222',
    backgroundColor: '#ffffff',
  },

  policies: {
    tab: {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    },
  },

  search: {
    iconColor: '#7a7c80',
    fontColor: '#222222',
  },

  tabs: {
    button: {
      hoverBackgroundColor: '#f2f2f2',
    },
  },

  tag: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F6F9FC',
  },

  toast: {
    icon: {
      backgroundColor: '#37C16B',
    },
    default: {
      progressBar: {
        backgroundColor: '#37C16B',
      },
    },
  },

  ticketBar: {
    backgroundColor: '#F4F8FF',
  },
};
