/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-24 01:48:42
 * @modify date 2021-02-18 11:46:53
 */

import { axiosInstance } from 'utils/api-util';

export function getUserService(userId) {
  return axiosInstance({
    url: `entity/contributor`,
    params: {
      id: userId,
    },
  });
}

export function getTabs(userId) {
  const tabs = ['tickets', 'updates'];

  return tabs.map((tab) => ({
    id: tab,
    name: tab,
    link: `/user/${userId}/${tab}`,
  }));
}

export function getBreadcrumbs(data) {
  let newBreadcrumbs = [];
  if (data.team) {
    newBreadcrumbs.push({
      label: data.team.name,
      link: `/team/${data.team.id}`,
    });
  }
  if (data.designation) {
    newBreadcrumbs.push({
      label: data.designation,
    });
  }
  return newBreadcrumbs;
}
