/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-23 16:37:19
 * @modify date 2021-01-23 16:58:26
 */

export function combineName(nameObj) {
  if (typeof nameObj === 'string') {
    return nameObj;
  }
  return `${nameObj?.first} ${nameObj?.last}`;
}
