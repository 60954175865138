/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-13 18:35:20
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const ChatSC = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${variables.dora.container.height};
  min-height: ${variables.dora.container.height};
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.white};
  flex: 1;
  border-radius: 16px;

  transition: all 250ms ease;
`;

export const ChatHeader = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 8px;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.white};

  .k-button {
    &:hover {
      background: ${(props) => props.theme.primaryLightColor};
    }
  }
  .k-icon {
    color: ${(props) => props.theme.white};
  }
`;

export const HeaderText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 13.6px;
`;

export const ChatBody = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: ${variables.dora.container.height - 88}px;
  max-height: ${variables.dora.container.height - 88}px;
  background: ${(props) => props.theme.lightBackgroundColor};

  .typing {
    width: 20px;
    margin-left: 16px;
  }
`;

export const ChatBodyInner = styled.div`
  padding: 8px;
  width: 100%;
`;

export const ChatTypingArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;
  max-height: 48px;
  border-top: 1px solid ${(props) => props.theme.dividerColor};
  background: ${(props) => props.theme.white};
  transition: all 250ms ease;
`;

export const ChatTypeInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: 12.8px;
`;
