/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:31:41
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ImageIconSC, ImageSC, DefaultLetter } from './ImageIcon.style';

export default function ImageIcon({
  link,
  src,
  alt,
  title,
  size,
  variant,
  rounded,
  padded,
}) {
  return (
    <ImageIconSC
      as={link ? Link : 'div'}
      to={link}
      data-testid="image-icon"
      className="k-image-icon"
      size={size}
      variant={variant}
      rounded={rounded ? 1 : 0}
    >
      {src ? (
        <ImageSC
          src={src}
          alt={alt}
          title={title ? title : alt}
          size={size}
          padded={padded ? 1 : 0}
        />
      ) : (
        <DefaultLetter
          title={title ? title : alt}
          size={size}
          padded={padded ? 1 : 0}
        >
          {title ? title[0] : alt[0]}
        </DefaultLetter>
      )}
    </ImageIconSC>
  );
}

ImageIcon.propTypes = {
  link: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['default', 'colored']),
  rounded: PropTypes.bool,
  padded: PropTypes.bool,
};

ImageIcon.defaultProps = {
  link: null,
  size: 'medium',
  title: '',
  variant: 'default',
  rounded: false,
  padded: false,
};
