/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-24 01:07:51
 * @modify date 2021-02-05 23:23:35
 */

import { axiosInstanceNoAuth } from 'utils/api-util';

export function sendUserContactDetails({ name, company, emailAddress }) {
  let formData = new FormData();

  formData.append('name', name);
  formData.append('company', company);
  formData.append('emailAddress', emailAddress);

  return axiosInstanceNoAuth({
    method: `post`,
    url: `entity/subscribe`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
