/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-15 22:52:21
 * @modify date 2021-02-15 22:52:21
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  FeatureSC,
  PageHeaderContainer,
  HighlightCardContainer,
} from './Feature.style';

import PageHeader from 'components/common/page-header/PageHeader';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import Card from 'components/common/card/Card';
import CardHeader from 'components/common/card/card-header/CardHeader';
import CardBody from 'components/common/card/card-body/CardBody';
import MenuButton from 'components/common/menu-button/MenuButton';

import NewCommentContainer from 'components/layout/new-comment/NewComment.container';
import SummaryCardContainer from 'components/layout/summary-card/SummaryCard.container';
import Highlights from 'components/layout/highlights/Highlights.container';
import GanttChartContainer from 'components/layout/gantt-chart/GanttChart.container';
import UpdatesContainer from 'components/layout/updates/Updates.container';
import EntityAccordion from '../entity-accordion/EntityAccordion';
import TabRouter from 'components/layout/tab-router/TabRouter';

export default function Feature({
  data,
  breadcrumbs,
  tabs,
  onPageHeaderOptionClick,
  onCommentSubmit,
}) {
  const { t } = useTranslation();

  return (
    <FeatureSC data-testid="project-page">
      <PageHeaderContainer className="animate__animated animate__fadeIn">
        <PageHeader
          entityName={t(data.entity)}
          icon={data.img}
          heading={data.title}
          menuButton={
            <MenuButton
              options={[
                {
                  key: 'edit',
                  label: t('edit'),
                },
              ]}
              onOptionClick={(option) => onPageHeaderOptionClick(option, data)}
            />
          }
          footer={<Breadcrumbs links={breadcrumbs} />}
        />
      </PageHeaderContainer>

      <HighlightCardContainer className="animate__animated animate__fadeIn">
        <Highlights
          entity={data.entity}
          id={data.id}
          restProps={{ isAnimated: true }}
        />
      </HighlightCardContainer>

      <SummaryCardContainer
        summary={data.description}
        attachments={data.attachments ?? []}
      />

      <Card className="animate__animated animate__fadeIn">
        <CardHeader bold>{t('comment')}</CardHeader>
        <CardBody padded>
          <NewCommentContainer onSubmit={onCommentSubmit} />
        </CardBody>
      </Card>

      <TabRouter
        tabs={tabs}
        renders={[
          <EntityAccordion key={1} entity={data} />,
          <>
            <GanttChartContainer entity={data.entity} id={data.id} />
            <UpdatesContainer entity={data.entity} id={data.id} />
          </>,
        ]}
      />
    </FeatureSC>
  );
}

Feature.propTypes = {
  /** data object */
  data: PropTypes.object,
  /** breadcrumbs data */
  breadcrumbs: PropTypes.array,
  /** list of tabs */
  tabs: PropTypes.array.isRequired,
  /** handler for option click on page header */
  onPageHeaderOptionClick: PropTypes.func.isRequired,
  /** handler for comment submit */
  onCommentSubmit: PropTypes.func.isRequired,
};
