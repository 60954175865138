/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-27 13:25:11
 * @modify date 2020-12-27 13:25:11
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  DashboardListSC,
  FilterBodyContainer,
  DashboardListContainer,
  DashboardCard,
  TagContainer,
} from './DashboardList.style';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/card-body/CardBody';
import Button from 'components/common/button/Button';
import Icon from 'components/common/icon/Icon';
import EntityName from 'components/common/entity-name/EntityName';
import LabelDonut from 'components/common/label-donut/LabelDonut';
import AvatarGroup from 'components/common/avatar-group/AvatarGroup';
import ImageIcon from 'components/common/image-icon/ImageIcon';
import Tags from 'components/common/tags/Tags';

import DashboardFilterContainer from './dashboard-filter/DashboardFilter.container';

import DashboardContainer from 'components/pages/member/dashboard/Dashboard.container';

import DASHBOARD_ICON from 'assets/images/icons/dashboard.svg';

export default function DashboardList({
  dashboards,
  filterIds,
  onFilterIdsChange,
  onSave,
}) {
  const { t } = useTranslation();

  return (
    <DashboardListSC data-testid="dashboard-list-page">
      <Card className="animate__animated animate__fadeIn">
        <CardBody padded>
          <FilterBodyContainer>
            <DashboardFilterContainer onFilterIdsChange={onFilterIdsChange} />
            <Button
              buttonType="flat"
              outlined
              leftIcon={<Icon name="save" size={13} />}
              text={t('save')}
              disabled={filterIds.length > 0 ? false : true}
              onClick={onSave}
            />
          </FilterBodyContainer>
        </CardBody>
      </Card>

      {filterIds.length ? (
        <DashboardContainer filterIds={filterIds} />
      ) : (
        <DashboardListContainer>
          {dashboards.map((dashboard, index) => (
            <DashboardCard
              key={dashboard.id}
              index={index}
              className="animate__animated animate__fadeIn"
            >
              <EntityName
                icon={
                  <ImageIcon
                    src={DASHBOARD_ICON}
                    alt={dashboard.title}
                    variant="colored"
                  />
                }
                entityName={t('dashboard')}
                title={dashboard.title}
                link={`/dashboard/${dashboard.id}`}
                footer={
                  <TagContainer>
                    <Tags
                      orientation="horizontal"
                      gap={4}
                      tags={dashboard.filters.map((filter) => ({
                        id: filter.id,
                        name: filter.name ?? filter.title,
                      }))}
                    />
                  </TagContainer>
                }
              />
              <LabelDonut
                value={dashboard.completedTasks}
                total={dashboard.totalTasks}
                label={t('tasks_completed')}
              />
              <AvatarGroup
                users={dashboard.contributors}
                view="line"
                maxCount={4}
              />
            </DashboardCard>
          ))}
        </DashboardListContainer>
      )}
    </DashboardListSC>
  );
}

DashboardList.propTypes = {
  dashboards: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  onFilterIdsChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
