/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:39:31
 * @modify date 2021-01-14 09:45:20
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const TicketUpdateDataSC = styled.div`
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr auto;
  flex: 1;
  gap: 16px;
`;

export const HightlighCardsContainer = styled.div`
  height: 100%;
  width: ${variables.highlightCardWidth +
  (variables.carousel.buttonsWidth + variables.carousel.gap) * 2}px;
`;
