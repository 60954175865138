/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-26 22:50:38
 * @modify date 2021-02-26 22:50:38
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  WorkspaceMenuSC,
  MainLinks,
  MainLink,
  MainLinkText,
  WorkspaceListContainer,
  SubHeading,
  WorkspaceList,
  WorkspaceNameContainer,
  AddButtonContainer,
  ButtonText,
  ButtonIconContainer,
} from './WorkspaceMenu.style';

import Icon from 'components/common/icon/Icon';
import Divider from 'components/common/divider/Divider';
import WorkspaceName from 'components/common/workspace-name/WorkspaceName';

import { links } from 'utils/navigation-util';
export default function WorkspaceMenu({
  workspaceList,
  selectedWorkspace,
  onWorkspaceChange,
}) {
  const { t } = useTranslation();

  return (
    <WorkspaceMenuSC>
      <MainLinks>
        {/* <MainLink to="/manage-members">
          <Icon name="group" isDark size={21} />
          <MainLinkText>{t('manage_members')}</MainLinkText>
        </MainLink> */}
        <MainLink to={links.workspace_edit.path}>
          <Icon name="tune" isDark size={21} />
          <MainLinkText>{t('workspace_settings')}</MainLinkText>
        </MainLink>
        <MainLink to={links.invite_teammates.path}>
          <Icon name="person_add_alt" isDark size={21} />
          <MainLinkText>{t('invite_teammates')}</MainLinkText>
        </MainLink>
      </MainLinks>

      <Divider />

      <WorkspaceListContainer>
        <SubHeading>{t('all_workspaces')}</SubHeading>

        <WorkspaceList>
          {workspaceList.map((workspace) => (
            <WorkspaceNameContainer
              key={workspace.id}
              onClick={() => onWorkspaceChange(workspace)}
            >
              <WorkspaceName variant="dark" workspaceName={workspace.name} />
              {workspace.id === selectedWorkspace.id ? (
                <Icon name="done" size={16} />
              ) : null}
            </WorkspaceNameContainer>
          ))}
        </WorkspaceList>
      </WorkspaceListContainer>

      <Divider />

      <AddButtonContainer to={links.workspace.path}>
        <ButtonIconContainer>
          <Icon name="add" size={24} />
        </ButtonIconContainer>

        <ButtonText>{t('new_workspace_text')}</ButtonText>
      </AddButtonContainer>
    </WorkspaceMenuSC>
  );
}

WorkspaceMenu.propTypes = {
  workspaceList: PropTypes.array,
  selectedWorkspace: PropTypes.object,
  onWorkspaceChange: PropTypes.func,
};
