/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-26 16:12:17
 * @modify date 2021-02-27 00:47:31
 */

import styled from 'styled-components';

export const WorkspaceNameSC = styled.div`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
`;

export const WorkspaceLogo = styled.img`
  margin-right: 6.5px;
  height: 24px;
`;

export const WorkspaceDefaultLogo = styled.div`
  margin-right: 6.5px;
  height: 24px;
  width: 24px;
  background: ${(props) =>
    props.variant === 'light' ? props.theme.white : props.theme.primaryColor};
  color: ${(props) =>
    props.variant === 'light' ? props.theme.primaryColor : props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const WorkspaceText = styled.p`
  display: flex;
  height: 19px;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) =>
    props.variant === 'light' ? props.theme.primaryLightColor : 'transparent'};
  color: ${(props) =>
    props.variant === 'light' ? props.theme.white : props.theme.black};
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2px;

  &:hover {
    color: ${(props) =>
      props.variant === 'light'
        ? props.theme.lightBackgroundColor
        : props.theme.blackText};
  }
`;
