/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2020-12-21 21:31:41
 * @modify date 2020-12-21 21:32:28
 */

import styled from 'styled-components';
import { variables } from 'variables';

export const IconSC = styled.div`
  display: flex;
  background: ${(props) => props.theme.primaryColor};
  width: ${(props) =>
    variables.buttonSize[props.size].height -
    variables.buttonSize[props.size].iconSizeReduction}px;
  height: ${(props) =>
    variables.buttonSize[props.size].height -
    variables.buttonSize[props.size].iconSizeReduction}px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;

  .k-icon {
    color: ${(props) => props.theme.primaryTextColor};
  }
`;

export const TextSC = styled.div`
  display: flex;
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => variables.buttonSize[props.size].fontSize}px;
  padding: 0 8px;
  transition: all 250ms ease;
`;

export const ButtonSC = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: none;
  outline: none;
  height: ${(props) => variables.buttonSize[props.size].height}px;
  margin: 0;
  padding: 0
    ${(props) => variables.buttonSize[props.size].iconSizeReduction / 2}px;
  font-family: Lato;
  font-weight: bold;
  cursor: pointer;
  border-radius: ${(props) => (props.rounded ? '100px' : '3.2px')};
  background: ${(props) => props.theme.button.inversePrimary.backgroundColor};
  box-shadow: ${(props) =>
    props.elevated ? props.theme.button.inversePrimary.boxShadow : 'none'};
  transition: all 250ms ease;

  &:hover {
    background: ${(props) =>
      props.theme.button.inversePrimary.backgroundHoverColor};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    box-shadow: none;
  }
`;
